import React from 'react';

const Progressbar = (props) => {
  const {
    progress=0,
    message,
    title
  } = props;

  const wrapperStyles = {
    height: '1.5rem', 
  };

  const progressBarStyle = {
    width: `${progress}%`
  };

  return (
    <div className="mt-5">
      {title && (<h5>{title}</h5>)}
      <div className="progress" style={wrapperStyles}>
        <div 
          className="progress-bar progress-bar-striped  progress-bar-animated" 
          role="progressbar" 
          style={progressBarStyle}  
          aria-valuenow={progress} 
          aria-valuemin="0" 
          aria-valuemax="100"
        ></div>
      </div>
      <p >{`${message || 'In progress'} ...`}</p>
    </div>
  );
};

export default Progressbar;
