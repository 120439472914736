import { Route, Switch } from 'react-router-dom';
import React from 'react';

import { SubmissionsProvider } from '../../submission/submissionsContext';
import { submissionStatuses } from '../../../../utils';
import NamedSubmissionsList from '../../submission/components/NamedSubmissionsList';

const DeletedSubmissionsPage = () => (
  <SubmissionsProvider>
    <Switch>
      <Route
        exact
        path="/form/:formId/deleted"
        render={(props) => <NamedSubmissionsList
          hideAddBtn={true} 
          submissionsRequestParams={{query: {
            'metadata.routed__exists': false,
            'metadata.status': submissionStatuses.deleted
          }}}
          gridOptions={{
            hide: {moveToDeleted: true}, 
            hideTrack: true,
          }}
          {...props} 
        />
        }
      />
    </Switch>
  </SubmissionsProvider>
);

export default DeletedSubmissionsPage;
