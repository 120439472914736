import React, { useEffect } from 'react';
import _ from 'lodash';
import { Loading, AppErrors } from '../../../common';
import { TrackSubmissionForm } from '.';
import { getRouteTrack, useSubmission } from '../submission';
import RouteTrackingModalWrapper from './RouteTrackingModalWrapper';

const RouteTrackingModalWithRequest = (props) => {
  const {
    trackingSubmissionId,
    onModalCloseClick = () => {}
  } = props;

  const {state: submissionState, dispatch: dispatchSubmissionAction} = useSubmission();
  const {isActive: isSubmissionActive, routeTrack, error: submissionError} = submissionState;

  useEffect(() => {
    getRouteTrack(dispatchSubmissionAction, trackingSubmissionId);
  }, [dispatchSubmissionAction, trackingSubmissionId]);


  const MainContent = () => {
    if(submissionError) {
      return <AppErrors errors={[submissionError]}/>;
    }
    else if(isSubmissionActive) {
      return <Loading/>
    }
    else if(!_.isEmpty(routeTrack)) {
      return <TrackSubmissionForm routeTrack={routeTrack}/>
    }
    else {
      return null;
    }
  }
  return (
    <RouteTrackingModalWrapper onModalCloseClick={ onModalCloseClick}>
      <MainContent/>
    </RouteTrackingModalWrapper>
  )
}

export default RouteTrackingModalWithRequest;
