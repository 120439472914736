import React, { useEffect } from 'react';
import { NavLink, Route, Switch, useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import SubmissionsPage from '../../submission/components/SubmissionsPage';
import {getFormAndCheck, useForm, checkAccess, getFormFolder} from '../formContext';
import FormDelete from './FormDelete';
import FormView from './FormView';
import {SubmissionProvider, SubmissionsProvider} from '../../submission';
import {useAuth} from '../../../auth';
import FormFolder from './FormFolder';
import { AppErrors, Loading} from '../../../../common';
import {MyForm, MyFormProvider} from '../../../myForms/myForm';
import { RoutedSubmissionsPage } from '../../routedSubmission';
import { IncomingSubmissionsPage } from '../../incomingSubmission';
import { ArchivedSubmissionsPage } from '../../archivedSubmission';
import { TrackedSubmissionsPage } from '../../trackedSubmission/components';
import DataRoutesPage from '../../formDataRoutes/components/DataRoutesPage';
import { DeletedSubmissionsPage } from '../../deletedSubmission';
import { ProcessEditPage } from '../../process';
import CsvSubmissionsPage from '../../csvSubmissions/components/CsvSubmissionsPage';
import { checkCsvAccess, useUser } from '../../../users/user/userContext';
import { FormsConfig } from '../../../../config';
import { hasAdminRights, unauthorizedMsg } from '../../../../utils';


const FormPage = () => {
  const {formId} = useParams();
  const history = useHistory();

  const {dispatch, state: formState} = useForm();
  const {
    hasFormAccess, 
    hasFormWriteAccess,
    checkingAccess, 
    accessCheckError, 
    form, 
    isActive: 
    isFormActive, 
    loadingFormFolder, 
    formFolderError, 
    error: formError, 
    isProcess, 
    formFolder
  } = formState;
  const {state: authState} = useAuth();
  const {is: role, user } = authState;
  const { state: userState, dispatch: userDispatch } = useUser();
  const { isCsvUserGroupsLoading, isCsvUserGroupsFetched, allowCsv, error: userError } = userState;

  const isAdmin = hasAdminRights(role);
  const path = window.location.pathname.split('/')  

  useEffect(() => {
    if (!isAdmin) {
      checkAccess(dispatch, formId);
    }
  }, [dispatch, formId, isAdmin]);

  useEffect(() => {
    if (hasFormAccess || isAdmin) {
      getFormAndCheck(dispatch, formId, user);
      getFormFolder(dispatch, formId);
    }
  }, [dispatch, formId, hasFormAccess, isAdmin, user]);

  useEffect(() => {
    const folderUserGroups = _.get(formFolder, 'data.userGroups', []);
    const userId = _.get(user, '_id', '');
    const allowCsvUserGroups = _.filter(folderUserGroups, userGroup => userGroup.data.allowCsv && _.includes(userGroup.data.csvForms, formState.id));

    if (!isAdmin && allowCsvUserGroups.length && userId && !isCsvUserGroupsLoading && !isCsvUserGroupsFetched) {
      const userGroups =_.chain(allowCsvUserGroups).map(userGroup => userGroup._id).join(',').value();
      const query = {
        'data.group._id__in': userGroups,
        'data.user._id': userId,
      };
      checkCsvAccess(userDispatch, { limit: 1000000, query }, { }, null, FormsConfig.userGroup);
    }
  }, [userDispatch, user, isAdmin, formFolder, isCsvUserGroupsLoading, isCsvUserGroupsFetched, formState.id]);

  useEffect(() => {
    const FIFTH_INDEX_PATHS = [
      "edit",
      "route",
    ];
    const THIRD_INDEX_PATHS = [
      "deleted",
    ];
    function restrictPaths(hasFormWriteAccess, index, subroutes) {
      if (!hasFormWriteAccess && path.length === 3) {
        return true;
      }
      const isRestrictedPath = subroutes.includes(path[index]);
      return isRestrictedPath;
    }
    if (
      !hasFormWriteAccess 
      && (restrictPaths(hasFormWriteAccess, 5, FIFTH_INDEX_PATHS) || restrictPaths(hasFormWriteAccess, 3, THIRD_INDEX_PATHS))
    ) {
      history.goBack();
      alert(unauthorizedMsg(formId))
    }
  }, [path, isAdmin, hasFormWriteAccess, formId, history]);

  if ((checkingAccess || isFormActive || _.isEmpty(form) || loadingFormFolder || isCsvUserGroupsLoading) && !formError) {
    return <Loading/>;
  }

  if (!hasFormAccess && !isAdmin) {
    return  <AppErrors errors={[accessCheckError || 'You are not authorized to view this page.']} />
  }

  const navbarLinks = _.sortBy([
    {icon: "fa fa-chevron-left", getPath: (pathStart, eventId) => '/form', title: '', priority: 0 },
    {icon: "fa fa-pencil", getPath: (pathStart, formId) => `/form/${formId}`, title: 'Enter Data', hide: !hasFormWriteAccess, notAdmin: true, priority: 10 },
    {icon: "fa fa-list-alt", getPath: (pathStart, formId) => `/form/${formId}/submission`, exact:false, title: isAdmin ? 'Current Data' : 'Current', priority: 20 },
    {icon: "fa fa-arrow-circle-up", getPath: (pathStart, formId) => `/form/${formId}/routed`, exact:false, notAdmin: true,title: 'Routed', priority: 30 },
    {icon: "fa fa-arrow-circle-up", getPath: (pathStart, formId) => `/form/${formId}/routes`, exact:false, adminOnly: true,title: 'Data Routes', priority: 35 },
    {icon: "fa fa-arrow-circle-down", getPath: (pathStart, formId) => `/form/${formId}/incoming`, exact:false,notAdmin: true, title: 'Incoming', priority: 40 },
    {icon: "fa fa-th-list", getPath: (pathStart, formId) => `/form/${formId}/tracked`, exact:false, notAdmin: true,title: 'Tracked', priority: 45 },
    {icon: "fa fa-check-square-o", getPath: (pathStart, formId) => `/form/${formId}/myform`, hide: !hasFormWriteAccess, exact: true, notAdmin: true, title: 'Manage My Form', priority: 50 },
    {icon: "fa fa-edit", getPath: (pathStart, formId) => `/form/${formId}/folder`, adminOnly: true, title: 'Form Folder', exact: false, priority: 60 },
    {icon: "fa fa-edit", getPath: (pathStart, formId) => `/form/${formId}/edit`, adminOnly: true, hide: !isProcess || !hasFormWriteAccess, title: 'Edit Process', priority: 65 },
    {icon: "fa fa-trash", getPath: (pathStart, formId) => `/form/${formId}/delete`, adminOnly: true, hide: !hasFormWriteAccess, title: 'Delete Form', priority: 70 },
    {icon: "fa fa-file-archive-o", getPath: (pathStart, formId) => `/form/${formId}/archived`, exact: false, notAdmin: true, title: 'Archived', priority: 75},
    {icon: "fa fa-trash", getPath: (pathStart, formId) => `/form/${formId}/deleted`, exact: false, hide: !hasFormWriteAccess, notAdmin: true, title: 'Deleted', priority: 80 },
    {icon: "fa fa-download", getPath: (pathStart, formId) => `/form/${formId}/csv`, exact: true, hide: !isAdmin && !allowCsv, title: 'CSV', priority: 85},
  ], (link) => link.priority );

  const Navbar = () => {
    return (
      <ul className="nav nav-tabs mb-2">
        {navbarLinks.map(link => {
          if ((link.adminOnly && !isAdmin) || (link.notAdmin && isAdmin) || link.hide) return null;

          return (
            <li className="nav-item" key={link.title}>
              <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.getPath('', formId)}  >
                <i className={link.icon}></i> {link.title}
              </NavLink>
            </li>
          )
        })}
      </ul>
    )
  };

  const adminRoutes = [
    { path:'/form/:formId/edit', component: ProcessEditPage,  hide: !isProcess},
    { path:'/form/:formId/delete', component: FormDelete },
    { path: '/form/:formId/folder', component: FormFolder },
    { path: '/form/:formId/routes', component: DataRoutesPage},
    { path: '/form/:formId/csv', component: CsvSubmissionsPage },
  ];

  const userRoutes = [
    <Route path="/form/:formId/routed" key="routed" component={RoutedSubmissionsPage} />,
    <Route path="/form/:formId/incoming" key="incoming" component={IncomingSubmissionsPage} />,
    <Route path="/form/:formId/tracked" key="tracked" component={TrackedSubmissionsPage} />,
    <Route path="/form/:formId/archived" key="archived" component={ArchivedSubmissionsPage} />,
    <Route path="/form/:formId/deleted" key="deleted" component={DeletedSubmissionsPage} />,
    <Route path="/form/:formId/myform" key="myform" render={(props) => (
      <MyFormProvider>
        <MyForm {...props}/>
      </MyFormProvider>
    )}/>,
    <Route
      exact
      path="/form/:formId"
      key="newSubmission"
      render={ (props) => (
        <SubmissionsProvider>
          <SubmissionProvider>
            <FormView {...props} addSubmissionAccess={true} />
          </SubmissionProvider>
        </SubmissionsProvider>
      )}
    />,
  ];

  if (allowCsv) {
    userRoutes.push(<Route path="/form/:formId/csv" key="csv" component={CsvSubmissionsPage} />);
  }

  return (
    <div>
      <Navbar/>
      <AppErrors errors={[formError, formFolderError, userError]} />
      {!formError && <Switch>
        <Route path="/form/:formId/submission" component={SubmissionsPage} />
        {isAdmin
          ? adminRoutes.map(route => route.hide ? null : <Route path={route.path}  key={route.path} component={route.component}/>)
          : userRoutes
        }
      </Switch>}
    </div>
  );
};

export default FormPage;
