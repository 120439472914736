import _  from "lodash";

const suppportedDataTypes = [
  'number',
  'datetime',
  'checkbox',
];

const getField = (query) => {
  return `data.${query.field.path}`;
}

const getValue = (query) => {
  return suppportedDataTypes.includes(query.field.type)
    ? query[`${query.field.type}_value`]
    : query.value;
}

const getDateTimeValue = (query) => {
  const operator = query.operator;
  const value = getValue(query);
  const field = getField(query);

  switch (operator) {
    case 'gt':
      return {
        '$gt': [
          `$${field}`,
          {
            '$dateFromString': {
              'dateString': value
            }
          }
        ]
      };
    case 'gte':
      return {
        '$gte': [
          `$${field}`,
          {
            '$dateFromString': {
              'dateString': value
            }
          }
        ]
      };
    case 'lt':
      return {
        '$lt': [
          `$${field}`,
          {
            '$dateFromString': {
              'dateString': value
            }
          }
        ]
      };
    case 'lte':
      return {
        '$lte': [
          `$${field}`,
          {
            '$dateFromString': {
              'dateString': value
            }
          }
        ]
      };
    default:
      return null;
  }
}

const getQueryValue = (query) => {
  const value = getValue(query);
  const operator = query.operator;

  switch (operator) {
    case 'notEqual':
      return {
        '$ne': value
      };
    case 'startsWith':
      return {
        '$regex': `^${value}`,
        '$options': 'i'
      };
    case 'contain':
      return {
        '$regex': `^.*${value}.*$`,
        '$options': 'i'
      };
    case 'notContain':
      return {
        '$regex': `^((?!${value}).)*$`,
        '$options': 'i'
      };
    case 'endsWith':
      return {
        '$regex': `${value}$`,
        '$options': 'i'
      };
    case 'matches':
      return {
        '$regex': value,
        '$options': 'i'
      };
    case 'gt':
      return {
        '$gt': value
      };
    case 'gte':
      return {
        '$gte': value
      };
    case 'lt':
      return {
        '$lt': value
      };
    case 'lte':
      return {
        '$lte': value
      };
    default:
      return value;
  }
}

export const getExportQuery = (data) => {
  const exportQuery = {};

  const queries = data.queries.filter((query) => {
    const value = getValue(query);
    return value || _.isBoolean(value);
  });

  const emptyQueires = data.queries
    .filter(query => query.operator === 'empty');

  queries
    .filter(query => query.field.type !== 'datetime')
    .forEach(query => {
      exportQuery[getField(query)] = getQueryValue(query);
    });

  const dateTimeQueries = queries
    .filter(query => query.field.type === 'datetime')

  const dateTimeExpressions = dateTimeQueries
    .map(getDateTimeValue);

  if (dateTimeExpressions.length) {
    exportQuery['$expr'] = {
      '$and': dateTimeExpressions
    };
  }

  dateTimeQueries
    .forEach(query => {
      exportQuery[getField(query)] = {
        '$ne': null
      }
    });

  if (emptyQueires.length) {
    exportQuery['$and'] = [];
  }

  emptyQueires
    .forEach(query => {
      const emptyObject = {};
      emptyObject[getField(query)] = '';
      const nullObject = {};
      nullObject[getField(query)] = null;
      exportQuery['$and'].push({
        '$or': [emptyObject, nullObject]
      });
    })

  return exportQuery;
};
