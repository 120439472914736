import React from 'react';
import _ from 'lodash';
import {FormsConfig} from '../../../config';
import * as AppUtils from '../../../utils';
import NativePromise from 'native-promise-only';

const FormsContext = React.createContext();

const initialState = {
  error: '',
  formsUpdateProgress: 0,
  formsUpdating: false,
  requestParamsSet: false,
  requestParams: {
    limit: 10000000,
    query: {},
    select: '',
    sort: '',
  }
};

function formsReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_START':
      return {
        ...state,
        error: '',
        formsUpdating: true,
        formsUpdateProgress: 5,
       };
    case 'UPDATE_FINISH':
      return {
        ...state,
        error: '',
        formsUpdating: false,
        formsUpdateProgress: 0,
      };
    case 'UPDATE_FAIL':
      return {
        ...state,
        error: action.error,
        formsUpdating: false,
        formsUpdateProgress: 0,
      };
    case 'UPDATE_PROGRESS':
      return {
        ...state,
        formsUpdateProgress: action.progress,
      };
    case 'SET_FILTER_QUERY': 
      return {
        ...state,
        requestParams: {...state.requestParams, query: action.query},
        requestParamsSet: true,
      };
    default:
      return state;
  }
}

export function FormsProvider(props) {
  const [state, dispatch] = React.useReducer(formsReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <FormsContext.Provider value={value} {...props} />;
}

export function useForms() {
  const context = React.useContext(FormsContext);
  if (!context) {
    throw new Error('useForms must be used within a FormsProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

const startFormsUpdate = () => ({
  type: 'UPDATE_START',
});

const finishFormsUpdate = () => ({
  type: 'UPDATE_FINISH',
});

const failFormsUpdate = (error) => ({
  type: 'UPDATE_FAIL',
  error
});

const setUpdateProgress= (progress) => ({
  type: 'UPDATE_PROGRESS',
  progress
});

const setFilterQuery= (query) => ({
  type: 'SET_FILTER_QUERY',
  query
});

const accessControlCompKey = 'submissionAccessData';

const getAccessControlComponent = () => {
  return AppUtils.getForm(null, FormsConfig.submissionAccessData)
    .then((form) => {
      return _.chain(form).get('components', []).find(comp => comp.key === accessControlCompKey).value();
    });
}

const getProjectForms = (limit, requestCount) => {
  return AppUtils.getForms({ limit, query: {type: 'form',  'tags__ne': 'default'} }, requestCount);
}

const addFormAccessControlCompAndSave = (dispatch, formAccessControlComp, limit, skipCoef) => {
  return getProjectForms(limit, skipCoef)
  .then(forms => {
    dispatch(setUpdateProgress(AppUtils.getProgress(limit, skipCoef, forms.serverCount)));

    const formsPromises = _.map(forms, form => {
      const pathToComponents = form.display === 'wizard' ? 'components[0].components' : 'components';
      const pathExists = _.chain(form).get(pathToComponents).isArray().value();

      if (pathExists) {
        const updatedComponents = _.chain(form).get(pathToComponents).filter(comp => comp.key !== accessControlCompKey).value();
        updatedComponents.push(formAccessControlComp);
        _.set(form, pathToComponents, updatedComponents);
      }

      return AppUtils.saveForm(form);
    });

    if (forms.serverCount > limit*skipCoef) {
      formsPromises.push(addFormAccessControlCompAndSave(dispatch, formAccessControlComp, limit, ++skipCoef));
    }
    return NativePromise.all(formsPromises);
  });
}

export const addFormAccessControlFields = (dispatch, done = () =>{}) => {
  dispatch(startFormsUpdate());

  return getAccessControlComponent()
    .then((accessControlComp) => {
      return addFormAccessControlCompAndSave(dispatch, accessControlComp, 100, 1);
    })
    .then((result) => {
      dispatch(finishFormsUpdate());
      done(null, result);
    })
    .catch((error) => {
      dispatch(failFormsUpdate());
      done(error);
    });
}

export const setFilterQueryParam = (dispatch, query) => {
  dispatch(setFilterQuery(query));
}