import React from 'react';
import { Formio } from '@formio/react';
import { FormsConfig } from '../../../config';
import NativePromise from 'native-promise-only';
import _ from 'lodash';

const FormContext = React.createContext();

const initialState = {
  myFormForm: {},
  isActive: false,
  error: '',
  myForm: {}
};

const myFormReducer = function(state, action) {
  switch (action.type) {
    case 'MY_FORM_REQUEST':
      return {
        ...state,
        isActive: true,
        myForm: {},
        error: '',
      };
    case 'MY_FORM_FAILURE':
      return {
        ...state,
        isActive: false,
        myForm: {},
        error: action.error,
      };  
    case 'MY_FORM_SUCCESS':
      return {
        ...state,
        isActive: false,
        error: '',
        myFormForm: {},
        myForm: action.myForm
      };
    case 'MY_FORM_FORM_SUCCESS':
      return {
        ...state,
        error: '',
        isActive: false,
        myFormForm: action.myFormForm
      };
    case 'MY_FORM_FORM_REQUEST':
      return {
        ...state,
        isActive: true,
        myFormForm: {},
        error: '',
      };
    case 'MY_FORM_FORM_FAILURE':
      return {
        ...state,
        isActive: false,
        myFormForm: {},
        error: action.error,
      };
    case 'FORM_SAVE_FAILURE':
      return {
        ...state,
        error: action.error,
      };
    case 'MY_FORM_DELETE_REQUEST':
      return {
        ...state,
        isActive: true,
        error: '',
      };
    case 'MY_FORM_DELETE_FAILURE':
      return {
        ...state,
        isActive: false,
        error: action.error,
      };    
    case 'MY_FORM_RESET':
      return initialState;
    default:
      return state;
  }
}

export function MyFormProvider(props) {
  const [state, dispatch] = React.useReducer(myFormReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <FormContext.Provider value={value} {...props} />;
}

export function useMyForm() {
  const context = React.useContext(FormContext);
  if (!context) {
    throw new Error('useMyForm must be used within a MyFormProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

const requestDeleteMyForm = () => ({
  type: 'MY_FORM_DELETE_REQUEST',
});

const failDeleteMyForm = (err) => ({
  type: 'MY_FORM_DELETE_FAILURE',
  error: err,
});

const requestMyForm = () => ({
  type: 'MY_FORM_REQUEST',
});

const receiveMyForm = (myForm) => ({
  type: 'MY_FORM_SUCCESS',
  myForm
});

const receiveMyFormForm = (myFormForm) => ({
  type: 'MY_FORM_FORM_SUCCESS',
  myFormForm
});

const requestMyFormForm = () => ({
  type: 'MY_FORM_FORM_REQUEST',
});

const failMyFormForm = (err) => ({
  type: 'MY_FORM_FORM_FAILURE',
  error: err,
});

const failMyForm = (err) => ({
  type: 'MY_FORM_FAILURE',
  error: err,
});

const resetMyForm = () => ({
  type: 'MY_FORM_RESET',
});

const failSaveForm = (error) => ({
  type: 'FORM_SAVE_FAILURE',
  error
});


export const isInMyForms = (form) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.myForms}/submission`;

  const formio = new Formio(url);

  return formio.loadSubmissions({params: {'data.form._id': form._id}})
    .then(result => {
      return !!result.length;
    })
}

const saveTag = (tag) => {
  const id = tag._id;
  const url = `${Formio.getProjectUrl()}/${FormsConfig.myTags}/submission${id ? `/${id}` : ''}`;

  const formio = new Formio(url);

  return formio.saveSubmission(tag);
}

const saveTags = (tags) => {
  const tagPromises = tags ? tags.map(tag => saveTag({data:{tag}})) : [];
  return NativePromise.all(tagPromises);
}

export const transformMyFormData = (myFormData) => {
  const {newTags, addNewTags} = myFormData;
  const areNewTags = addNewTags && !!newTags.length;

  let transformPromise = areNewTags
    ? saveTags(newTags).then(savedTags => {
        _.set(myFormData, 'tags', [..._.get(myFormData, 'tags', []), ...savedTags]);
        _.unset(myFormData, 'newTags', []);
        _.set(myFormData, 'addNewTags', false);
        return myFormData;
      })
    : NativePromise.resolve(myFormData);

  return transformPromise.then(result => {
    return result;
  })
 
}

export const validateAndTransformMyFormData = (dispatch, myForm, validateExistance, next, done=()=> {}) => {
  const errors = [];

  return NativePromise
    .resolve()
    .then(() => {
      const {tags, newTags, form} = myForm.data;
      const areTags = tags.length || (newTags && newTags.length);

      if (!areTags) {
        errors.push({message: 'Please add at least one tag that will be associated with the form.'});
      }
      return validateExistance ? isInMyForms(form) : false;
    })
    .then(myFormExist => {
      if (myFormExist) {
        errors.push({message: 'The form has already been added to My Forms, please select another form.'});
      }

      if (errors.length) { 
        next(errors);
      }
      else { 
        return transformMyFormData(myForm.data)
      }
    })
    .then(submissionData => {
      if (submissionData && !errors.length) {
        myForm.data = submissionData;
        next();
      }
    })
    .catch((err) => {
      dispatch(failSaveForm(`Error while validating and transforming My Form data: ${err}`));
      done(err); 
    })
}

export const setMyForm = (dispatch, myForm, done=()=> {}) => {
  dispatch(receiveMyForm(myForm));
  done()
}

export const getMyFormForm = (dispatch, myFormFormId, done = ()=>{}) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.folderForm}/submission`;
  const formio = new Formio(url);

  dispatch(requestMyFormForm());

  return formio.loadSubmissions({params: {'data.form._id': myFormFormId}})
    .then(result => {
      dispatch(receiveMyFormForm(result[0]||{}))
      done(null, result[0]);
    })
    .catch(err => {
      dispatch(failMyFormForm(err));
    });
}

export const getMyForm = (dispatch, formId, done=()=>{}) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.myForms}/submission`;
  const formio = new Formio(url);

  dispatch(requestMyForm());

  return formio.loadSubmissions({params: {'data.form.data.form._id': formId}})
    .then(result => {
      dispatch(receiveMyForm(result[0], {}));
      done(null, result);
    })
    .catch(err => {
      dispatch(failMyForm(`Error while getting My Form: ${err}`));
      done(err);
    });
};

export const deleteMyForm = (dispatch, myFormId, done=()=>{}) => {
  const formio = new Formio(`${Formio.getProjectUrl()}/${FormsConfig.myForms}/submission/${myFormId}`);

  dispatch(requestDeleteMyForm());

  return formio.deleteSubmission()
    .then(() => {
      dispatch(resetMyForm());
      done(null, true);
    })
    .catch((err) => {
      dispatch(failDeleteMyForm(`Error while deleting My Form: ${err}`));
      done(err);
    });
}
