import React from 'react';
import _ from 'lodash';
import * as Utils from '../../../utils';

const ProcessHeader = (props) => {
  const {
    form,
    submission, 
    children
  } = props;
  
  const currentStage = Utils.getCurrentStageSettings(submission, form);

  return (
    <div className="alert alert-primary rounded my-3 pt-0 border border-primary row">
      <div className="col-10">
    <div className="row  mt-2">
      <div className="col-5">
        <span className="mr-2 font-weight-bold">Process Name:</span>
        <span>{_.get(form, 'name', '')}</span>
      </div>
      <div className="col-7">
        <span className="mr-2 font-weight-bold">Process Title:</span>
        <span>{_.get(submission, 'metadata.submissionName', '')}</span>
      </div>
      
    </div>
    <div className="row mt-2">
      <div className="col-5">
        <span className="mr-2 font-weight-bold">Current Stage:</span>
        <span>{currentStage.title}</span>
      </div>
      <div className="col-7">
        <span className="mr-2 font-weight-bold">Stage Description:</span>
        <span>{currentStage.description || '-'}</span>
      </div>
    </div>
    </div>
    <div className="col-2 mt-2 d-flex justify-content-end align-items-center">{children}</div>
    </div>
  );
}



export default ProcessHeader;
