import { NavLink, Route, Switch } from 'react-router-dom';
import React from 'react';
import _ from 'lodash';
import IncomingSubmissionsList from './IncomingSubmissionsList';
import { SubmissionsProvider } from '../../submission/submissionsContext';
import { Title } from '../../../../common';
import AllSpecial from './AllSpecial';

const AllIncomingSubmissionsPage = () => {
  const navbarLinks = _.sortBy([ 
    {icon: "", path: `/incoming`, title: 'All Incoming', priority: 10 },
    {icon: "", path: `/incoming/archived`, title: 'All Incoming Archived', priority: 30 },
    {icon: "", path: `/incoming/special`, title: 'All Special', priority: 20 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-3">
      {navbarLinks.map(link => {
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true } to={link.path}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

  return (
    <>
      <Title text={'All Incoming Submissions'}/>
      <Navbar/>
      <SubmissionsProvider>
        <Switch>
          <Route
            path="/incoming"
            exact
            render={(props) =><IncomingSubmissionsList {...props} gridName={'allIncomingTableFilter'} />}
          />
          <Route
            path="/incoming/archived"
            exact
            render={(props) => <SubmissionsProvider><IncomingSubmissionsList {...props} archived={true} gridName={'allIncomingArchivedTableFilter'} /></SubmissionsProvider>}
          />
          <Route
            path="/incoming/special"
            exact
            render={(props) => <SubmissionsProvider><AllSpecial/></SubmissionsProvider>}
          />
        </Switch>
      </SubmissionsProvider>
    </>
)};

export default AllIncomingSubmissionsPage;
