import { NavLink, Route, Switch, useParams, Redirect, Link} from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import { useSubmission, getSubmissionAndRouteTrack } from '../../submission/submissionContext';
import { useForm } from '../../form';
import { Loading, Title, AppErrors } from '../../../../common';
import { ArchivedSubmissionAlert, RoutedSubmissionAlert, CurrentSubmissionRedirect} from '../../partials';
import { getUserRole, readOnlyRoles, routedSubmissionRoles } from '../../FormsUtils';
import { useAuth } from '../../../auth';
import { isRoutedArchived } from '../../../../utils';
import ProcessSubmissionView from '../../submission/components/ProcessSubmissionView';
import { SubmissionConvertButtons } from '../../convertButtons';
import { SubmissionView, SubmissionConversionProvider } from '../../submission';

const RoutedSubmissionPage = () => {
  const { formId, submissionId } = useParams();
  const { dispatch, state: submissionState } = useSubmission();
  const { state: formState } = useForm();
  const {isActive: isSubmissionActive, submission, routeTrack, error: submissionError} = submissionState;
  const { isProcess} = formState;
  const {state: userSate} = useAuth();
  const {user} = userSate;

  const [userRole, setUserRole] = useState(false);

  const isArchived = useMemo(() => isRoutedArchived(routeTrack, user), [user, routeTrack]);

  useEffect(() => {
    getSubmissionAndRouteTrack(dispatch, submissionId, formId);
  }, [dispatch, submissionId, formId]);

  useEffect(() => {
    if(!_.isEmpty(user) && !_.isEmpty(submission)) {
      setUserRole(getUserRole(routeTrack, user._id));
    }
  }, [submissionId, formId, submission, user, routeTrack]);

  const View = () => isProcess
    ? <SubmissionConversionProvider><ProcessSubmissionView readOnly={true} /></SubmissionConversionProvider>
    : <SubmissionView readOnly={true} />;

  const navbarLinks = _.sortBy([
    {icon: "fa fa-chevron-left", path: isArchived ? `/form/${formId}/archived/routed`:`/form/${formId}/routed`, title: '', priority: 0 },
    {icon: "fa fa-eye", path: `/form/${formId}/routed/${submissionId}`, title: 'View', priority: 10 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs">
      {navbarLinks.map(link => {
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.path}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

  if (submissionError) {
    return <AppErrors errors={[submissionError]}/>
  }

  if(isSubmissionActive || _.isEmpty(submission) || !userRole) {
    return <Loading/>;
  }

  if (_.get(submission, 'metadata.archived', false)) {
    return <Redirect to={`/form/${formId}/archived/${submissionId}`} />
  }

  if (userRole && userRole !== routedSubmissionRoles.routeInitiator){
    if (userRole === routedSubmissionRoles.owner){
      return <Redirect to={`/form/${formId}/incoming/${submissionId}`} />
    }
    if (_.some(readOnlyRoles, role => role === userRole) ){
      return <Redirect to={`/form/${formId}/tracked/${submissionId}`} />
    }
    return <CurrentSubmissionRedirect submission={submission} formId={formId} submissionId={submissionId}/>
  }

  return (
    <div>
      <Title text={`${_.get(formState, 'form.title', '')}  - Routed Submission`}/>
      {isArchived && <ArchivedSubmissionAlert/>}
      <RoutedSubmissionAlert message={`This ${isProcess ? 'process' : 'form submission'} has been routed to other users and is available to you in read-only mode.`} routedSubmission={routeTrack}/>
      {!isProcess && formState.hasFormWriteAccess && <div className="d-flex justify-content-end">
        <SubmissionConversionProvider><SubmissionConvertButtons/></SubmissionConversionProvider>
          <Link
            className="btn btn-primary ml-2"
            to={{
              pathname: `/form/${formId}`,
              state: {data: submission.data || {}}
            }}
          >Create Copy</Link>
      </div>}
      <Navbar />
      <Switch>
        <Route
          exact
          path="/form/:formId/routed/:submissionId"
          component={View}
        />
      </Switch>
    </div>
  )
};

export default RoutedSubmissionPage;
