import React from 'react';
import { Formio } from '@formio/react';
import { FormsConfig } from '../../../config';
import NativePromise from 'native-promise-only';
import _ from 'lodash';

const FolderContext = React.createContext();

const initialState = {
  parentFolder: null,
  parentFolderError: '',
  loadingParentFolder: false,
  deletingSubfolders: false,
  deleteSubfoldersStatus: {},
  deleteSubfoldersError: '',
  deletePermissionError: '',
  updatingDependencies: false,
  updateSubfoldersStatus: {},
  updateFolderFormsStatus: {},
  updateDependenciesError: '',
};

const deletePermissionErrors = {
  folder: 'The folder cannot be deleted as it contains forms. Please delete or move forms from this folder and its subfolders to other folders.',
  subfolder: 'The folder cannot be deleted as its subfolders contain forms. Please delete or move forms from subfolders to other folders.'
};

const deleteSubfoldersProgress ={
  'checkingFolder':{
    progress: 15,
    message: 'Checking Folder for Deletion'
  },
  'searching':{
    progress: 20,
    message: 'Searching Subfolders'
  },
  'checkingSubfolders':{
    progress: 40,
    message: 'Checking for Deletion'
  },
  'getting': {
    progress: 50,
    message: 'Searching Subfolders'
  },
  'checkingChildren':{
    progress: 60,
    message: 'Checking for Deletion'
  },
  'deleting': {
    progress: 70,
    message: 'Deleting Subfolders'
  },
  'finishing': {
    progress: 90,
    message: 'Start Deleting Folder'
  },
};

const updateSubfoldersProgress = {
  'starting':{
    progress: 5,
    message: 'Starting Updating Subfolders'
  },
  'gettingSubfolders':{
    progress: 20,
    message: 'Checking Subfolders'
  },
  'updatingSubfolders':{
    progress: 50,
    message: 'Updating Subfolders'
  },
  'savingSubfolders': {
    progress: 80,
    message: 'Saving  Subfolders'
  },
  'finishing':{
    progress: 99,
    message: 'Finishing'
  },
};

const updateFolderFormsProgress = {
  'starting':{
    progress: 5,
    message: 'Starting Updating Folder Forms'
  },
  'loadingFolderForms':{
    progress: 20,
    message: 'Checking Folder Forms'
  },
  'updatingFolderForms':{
    progress: 50,
    message: 'Updating Folder Forms'
  },
  'savingFolderForms': {
    progress: 80,
    message: 'Saving Folder Forms'
  },
  'finishing':{
    progress: 99,
    message: 'Finishing'
  },
};

const folderReducer = (state, action) => {
  switch (action.type) {
    case 'PARENT_FOLDER_REQUEST':
      return {
        ...state,
        parentFolderError: '',
        loadingParentFolder: true,
        parentFolder: null
      };
    case 'PARENT_FOLDER_SUCCESS':
      return {
        ...state,
        parentFolderError: '',
        loadingParentFolder: false,
        parentFolder: action.parentFolder
      };
    case 'PARENT_FOLDER_FAILURE':
      return {
        ...state,
        parentFolderError: action.error,
        loadingParentFolder: false,
      };
    case 'FOLDER_RESET':
      return initialState;
    case 'DELETE_SUBFOLDERS_START':
      return {
        ...state,
        deleteSubfoldersError: '',
        deletingSubfolders: true,
        deletePermissionError: ''
      };
    case 'SET_DELETE_SUBFOLDERS_STATUS':
      return {
        ...state,
        deleteSubfoldersStatus: deleteSubfoldersProgress[`${action.status}`]
      };
    case 'DELETE_SUBFOLDERS_FAILURE':
      return {
        ...state,
        deleteSubfoldersError: action.error,
        deletingSubfolders: false,
        deleteSubfoldersStatus: {},
      };
    case 'DELETE_PERMISSION_FAILURE':
      return {
        ...state,
        deletingSubfolders: false,
        deleteSubfoldersStatus: {},
        deletePermissionError: action.error
      };
    case 'UPDATE_DEPENDENCIES_START':
      return {
        ...state,
        updatingDependencies: true,
        updateDependenciesError: '',
      };
    case 'SET_UPDATE_SUBFOLDERS_STATUS':
      return {
        ...state,
        updatingDependencies: true,
        updateSubfoldersStatus: updateSubfoldersProgress[`${action.status}`]
      };
    case 'SET_UPDATE_FORMS_STATUS':
      return {
        ...state,
        updatingDependencies: true,
        updateFolderFormsStatus: updateFolderFormsProgress[`${action.status}`]
      };
    case 'UPDATE_DEPENDENCIES_FAILURE':
      return {
        ...state,
        updateDependenciesError: action.error,
        updatingDependencies: false,
        updateDependenciesStatus: {},
      };
    default:
      return state;
  }
};

export function FolderProvider(props) {
  const [state, dispatch] = React.useReducer(folderReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <FolderContext.Provider value={value} {...props}/>;
}

export function useFolder() {
  const context = React.useContext(FolderContext);
  if (!context) {
    throw new Error('useFolder must be used within a FolderProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

const requestParentFolder = () => ({
  type: 'PARENT_FOLDER_REQUEST',
});

const receiveParentFolder = (parentFolder) => ({
  type: 'PARENT_FOLDER_SUCCESS',
  parentFolder
});

const failParentFolder = (error) => ({
  type: 'PARENT_FOLDER_FAILURE',
  error,
});

export const resetFolder = () => ({
  type: 'FOLDER_RESET'

});

const failDeleteSubfolders = (error) => ({
  type: 'DELETE_SUBFOLDERS_FAILURE',
  error,
});

const setDeleteSubfoldersStatus = (status) => ({
  type: 'SET_DELETE_SUBFOLDERS_STATUS',
  status
});

const startDeleteSubfolders = () => ({
  type: 'DELETE_SUBFOLDERS_START',
});

const failDeletePermission = (error) => ({
  type: 'DELETE_PERMISSION_FAILURE',
  error
});

const failUpdateDependencies = (error) => ({
  type: 'UPDATE_DEPENDENCIES_FAILURE',
  error,
});

const setUpdateSubfoldersStatus = (status) => ({
  type: 'SET_UPDATE_SUBFOLDERS_STATUS',
  status
});

const setUpdateFolderFormsStatus = (status) => ({
  type: 'SET_UPDATE_FORMS_STATUS',
  status
});

const startUpdateDependencies = () => ({
  type: 'UPDATE_DEPENDENCIES_START',
});

export const getParentFolder = (dispatch, folderId, done = () => {}) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.folder}/submission/${folderId}`;
  const formio = new Formio(url);

  dispatch(requestParentFolder());

  formio.loadSubmission()
    .then((result) => {
      dispatch(receiveParentFolder(result));
      done(null, result);
    })
    .catch((error) => {
      dispatch(failParentFolder(error));
      done(error);
    });
};

const loadAndCheckSubfolders = (formio, folderId, subfolders, dispatch)=> {
  return formio.loadSubmissions({params: {'data.parentFolder._id': folderId, select: '_id'}})
    .then((result) => {
      if (!result.length) {
        return subfolders;
      }

      if (result.length) {
        const checkEmptyPromises = result.map((subf) => {
          subfolders.push(subf._id);
          return isEmptyFolder(subf._id);
        });

        if (dispatch) {
          dispatch(setDeleteSubfoldersStatus('checkingSubfolders'));
        }

        return NativePromise.all(checkEmptyPromises)
          .then(checks => {
            const isNotEmptySubfolder = _.some(checks, isEmpty => !isEmpty);
            if (isNotEmptySubfolder) {
              return [false];
            }
            else {
              if (dispatch) {
                dispatch(setDeleteSubfoldersStatus('getting'));
              }

              const loadSubfolderPromises = result.map((subf) => {
                subfolders.push(subf._id);
                return loadAndCheckSubfolders(formio, subf._id, subfolders);
              });

              if (dispatch) {
                dispatch(setDeleteSubfoldersStatus('checkingChildren'));
              }
              return NativePromise.all(loadSubfolderPromises);
            }
          });
      }
    })
};

const isEmptyFolder = (folderId, setStatus)=> {
  const formio = new Formio(`${Formio.getProjectUrl()}/${FormsConfig.folderForm}/submission`);
 
  if (setStatus) {
    setStatus(); 
  }

  return formio.loadSubmissions({params: {'data.folder._id': folderId}})
    .then((result) => {
      return !result.length;
    })
};

const getSubfoldersForDeletion = (dispatch, folderId) => {
  const formio = new Formio(`${Formio.getProjectUrl()}/${FormsConfig.folder}/submission`);
  dispatch(setDeleteSubfoldersStatus('searching'));

  return loadAndCheckSubfolders(formio, folderId, [], dispatch);
};

const deleteSubfolder = (subfolderId)=> {
  const formio = new Formio(`${Formio.getProjectUrl()}/${FormsConfig.folder}/submission/${subfolderId}`);

  return formio.deleteSubmission();
};
 
export const deleteSubfolders = (dispatch, folderId, done = () => {}) => {
  dispatch(startDeleteSubfolders());
  dispatch(setDeleteSubfoldersStatus('checkingFolder'));

  return isEmptyFolder(folderId)
    .then(isEmpty => {
      if (isEmpty) {
        return getSubfoldersForDeletion(dispatch, folderId)
          .then(subfolders => {
            const subfolderIds = _.chain(subfolders).flattenDeep().uniq().value();

            if (subfolderIds.length && subfolderIds[0] === false) {
              const permissionError = deletePermissionErrors.subfolder;
              dispatch(failDeletePermission(permissionError));
              done(permissionError);
              return;
            };

            dispatch(setDeleteSubfoldersStatus('deleting'));
            
            const deletePromises = _.map(subfolderIds, (id) => {
              return deleteSubfolder(id);
            });

            return NativePromise.all(deletePromises).then(()=> {
              dispatch(setDeleteSubfoldersStatus('finishing'));
              dispatch(resetFolder());
              done(null, true);
            });
          });
      }
      else {
        const permissionError = deletePermissionErrors.folder;

        dispatch(failDeletePermission(permissionError));
        done(permissionError);
      }
    })
    .catch((error) => {
      dispatch(failDeleteSubfolders(error));
      done(error);
    });
};

const loadSubfolders = (formio, folderId, subfolders, params, dispatch)=> {
  return formio.loadSubmissions({params: {...params, 'data.parentFolder._id': folderId, }})
    .then((result) => {
      if (!result.length) {
        return subfolders;
      }

      if (result.length) {
        const loadSubfolderPromises = result.map((subf) => {
          subfolders.push(subf);
          return loadSubfolders(formio, subf._id, subfolders, params);
        });
    
        return NativePromise.all(loadSubfolderPromises);
      }
    });
};

const getSubfolders = (dispatch, folderId, params= {}) => {
  const formio = new Formio(`${Formio.getProjectUrl()}/${FormsConfig.folder}/submission`);

  return loadSubfolders(formio, folderId, [], params, dispatch);
};

const saveSubmission = (dispatch, formName, submission,  done = () => {}) => {
  const projectUrl = Formio.getProjectUrl();
  const url = `${projectUrl}/${formName}/submission/${submission._id}`;
  const formio = new Formio(url);

  return formio.saveSubmission(submission);
};

 const saveSubfolder = (dispatch, subfolder, done = () => {}) => {
   return saveSubmission(dispatch, FormsConfig.folder, subfolder, done);
};

const saveFormFolder = (dispatch, formFolder, done = () => {}) => {
  return saveSubmission(dispatch, FormsConfig.folderForm, formFolder, done);
};

const updateSubfolders = (dispatch, folderId, changes ) => {
  const {initialName, newName, deletedGroups, updatedFolder} = changes;
  const areDeletedGroups = deletedGroups && !!deletedGroups.length;

  if (newName || areDeletedGroups) {
    dispatch(setUpdateSubfoldersStatus('gettingSubfolders'));
    return getSubfolders(dispatch, folderId, !newName && areDeletedGroups ? {'data.userGroups._id__in': deletedGroups.join(',')} : {})
      .then((subfoldersArr => {
        const subfolders = _.chain(subfoldersArr).flattenDeep().uniq().value();
        const updateFolderFormsPromises = [];

        dispatch(setUpdateSubfoldersStatus('updatingSubfolders'));

        const updatedSubfolders = _.map(subfolders, subfolder => {
          if (areDeletedGroups) {
            const subfolderUpdatedGroups = _.filter(_.get(subfolder, 'data.userGroups'), group => !deletedGroups.includes(group._id));
            _.set(subfolder, 'data.userGroups', subfolderUpdatedGroups);
            updateFolderFormsPromises.push(updateFolderForms(null, subfolder._id, subfolderUpdatedGroups));
          }

          if (newName) {
          const subfolderPath = _.get(subfolder, 'data.folderPath');

          const updatedPath = _.chain(subfolderPath)
            .split('/')
            .map(pathPart => {
              const part = pathPart.trim()
              return part === initialName ? newName : part;
            })
            .join('/ ');

            _.set(subfolder, 'data.folderPath', updatedPath);
          }

          return subfolder;
        });

        const groupedSubfolders = _.keyBy(updatedSubfolders, '_id' );
        groupedSubfolders[`${updatedFolder._id}`] = updatedFolder;

        const subfolderSavePromises =  _.map(updatedSubfolders, subf => {
          const parentFolderId = _.get(subf, 'data.parentFolder._id');
          const changedParentFolder = _.get(groupedSubfolders, parentFolderId);

          if (changedParentFolder && changedParentFolder.data) {
            _.set(subf, 'data.parentFolder.data', changedParentFolder.data);
          }
          return saveSubfolder(dispatch, subf);
        });

        dispatch(setUpdateSubfoldersStatus('savingSubfolders'));

        return NativePromise.all([...updateFolderFormsPromises, subfolderSavePromises]);
      }))
  }
};

const updateFolderForms = (dispatch, folderId, newGroups) => {
  const formio = new Formio(`${Formio.getProjectUrl()}/${FormsConfig.folderForm}/submission`);

  if (dispatch) {
    dispatch(setUpdateFolderFormsStatus('loadingFolderForms'));
  }

  return formio.loadSubmissions({params: { 'data.folder._id': folderId }})
    .then((result) => {
      if (dispatch) {
        dispatch(setUpdateFolderFormsStatus('updatingFolderForms'));
      }
      const changedFolderForms = _.map(result, formFolder => {
        _.set(formFolder, 'data.userGroups', newGroups);
        return saveFormFolder(dispatch, formFolder);
      });
      if (dispatch) {
        dispatch(setUpdateFolderFormsStatus('savingFolderForms'));
      }
      return NativePromise.all(changedFolderForms);
    });
};

export const updateFolderDependantResources = (dispatch, folderId, changes, done=()=> {}) => {
  dispatch(startUpdateDependencies());
  dispatch(setUpdateSubfoldersStatus('starting'));
  dispatch(setUpdateFolderFormsStatus('starting'));

  const {newName, newGroups, deletedGroups} = changes;
  const updatingPromises = [];
  const areGroupsChanged = deletedGroups && !!deletedGroups.length;
  
  if (newName || areGroupsChanged) {
    updatingPromises.push(updateSubfolders(dispatch, folderId, changes)
      .then((res) => { 
        dispatch(setUpdateSubfoldersStatus('finishing')); 
        return res;
      }));
  }
  else {
    dispatch(setUpdateSubfoldersStatus('finishing')); 
  }

  if (newGroups) {
    updatingPromises.push(updateFolderForms(dispatch, folderId, newGroups)
      .then((res) => { 
        dispatch(setUpdateFolderFormsStatus('finishing'));
        return res;
      }));
  }
  else {
    dispatch(setUpdateFolderFormsStatus('finishing'));
  }

  return NativePromise.all(updatingPromises).then((result) => {
    dispatch(resetFolder());
    done(null, result);
  }).catch((error) => {
    dispatch(failUpdateDependencies(error));
    done(error);
  })
};
