import React, { useEffect } from 'react';
import { NavLink,  Route, Switch, useParams } from 'react-router-dom';
import _ from 'lodash';

import { useSubmission, getSubmission, SubmissionView, SubmissionDelete } from '../../../forms/submission';
import {UserGroupsPage} from '../../userGroups';
import { FormProvider } from '../../../forms/form';
import { Title } from '../../../../common';

const UserPage = (props) => {
  const {pathStart, formName, paramName } = props;
  const eventId = useParams()[`${paramName}Id`];

  const { dispatch: dispatchSubmissionEvent, state: submissionState } = useSubmission();
  const submission = _.get(submissionState, 'submission', {});

  useEffect(() => {
    getSubmission(dispatchSubmissionEvent, eventId, null, formName);
  }, [eventId, dispatchSubmissionEvent, formName]);

  const View = () => <SubmissionView pathStart={pathStart} readOnly={true} formName={formName}/>; 
  const Edit = () => <SubmissionView pathStart={pathStart} readOnly={false} formName={formName}/>;

  const navbarLinks = _.sortBy([ 
    {icon: "fa fa-chevron-left", getPath: (pathStart, eventId) => `${pathStart}`, title: '', priority: 0 },
    {icon: "fa fa-eye", getPath: (pathStart, eventId) => `${pathStart}/${eventId}`, title: 'View', priority: 10 },
    {icon: "fa fa-edit", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/edit`, title: 'Edit', priority: 20 },
    {icon: "fa fa-user-plus", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/groups`, exact: false, title: 'Groups', priority: 30 },
    {icon: "fa fa-trash", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/delete`, title: 'Delete', priority: 40 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-2">
      {navbarLinks.map(link => (
        <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.getPath(pathStart, eventId)}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );

const deleteSubject = () => {
  if (submission.data) {
    const {email} = submission.data;
    return `the user ${email}`;
  }
  return;
} 

  return (
    <div>
      <Title text={`User`}/>
      <Navbar />
      <Switch>
        <Route exact path={`${pathStart}/:${paramName}Id`} component={View}/>
        <Route path={`${pathStart}/:${paramName}Id/edit`} component={Edit}/>
        <Route
          path={`${pathStart}/:${paramName}Id/delete`}
          render={(props) => <SubmissionDelete {...props} pathStart={pathStart} formName={formName} paramName={paramName} deleteSubject={deleteSubject()}/>}

        />
        <Route path={`${pathStart}/:${paramName}Id/groups`} render={(props) => <FormProvider><UserGroupsPage {...props} groupSubmission={submission} /></FormProvider>}/>
      </Switch>
    </div>
  );
};

export default UserPage;
