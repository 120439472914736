import { Formio } from '@formio/react';
import editForm from './PrevButton.form';

const FormioButton = Formio.AllComponents.button;
class PrevButton extends FormioButton {
  static schema(...extend) {
    return FormioButton.schema({
      type: 'prevButton',
      label: 'Previous',
      key: 'previous',
      size: 'md',
      action: '',
      leftIcon: '',
      rightIcon: '',
      block: false,
      persistent: false,
      disableOnInvalid: false,
      theme: 'primary',
      dataGridLabel: true
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Previous Button',
      group: 'custom',
      icon: 'arrow-left',
      weight: 30,
      schema: PrevButton.schema()
    };
  }

  onClick(event) {
    if (this.disabled || this.options.attachMode === 'builder') {
      return;
    }
    this.dataValue = true;

    if (this.component.showValidations) {
      this.emit('checkValidity', this.data);
    }

    const wizard = this.root;
    const pageStageId = wizard.pages[wizard.page]?.component?.stageId;
    const prevPageStageId= wizard.pages[wizard.page -1]?.component?.stageId;
    let allowPrev = pageStageId === prevPageStageId;

    if (allowPrev) { 
      this.root.prevPage();
    }
  }
}

PrevButton.editForm = editForm;


export default PrevButton;