import editForm from './Component.form';
import { Formio } from "@formio/react";
const Component = Formio.AllComponents.component;

Component.prototype.getProcessData = function() {
  const getProcessRoot = (instance) => {
    return instance.id === instance.root.id ? instance.data : getProcessRoot(instance.root);
  }

  return getProcessRoot(this);
}

Component.editForm = editForm;

export default Component;


