import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Form } from '@formio/react';
import _ from 'lodash';
import { Utils } from 'formiojs';

import { Loading, AppErrors } from '../../../../common';
import { useSubmission, saveSubmission, setRouteSettings, setInitPage } from '../submissionContext';
import { useForm } from '../../form/formContext';
import { ProcessHeader } from '../../partials';
import SubmissionRoute from './SubmissionRoute';
import { FormsConfig } from '../../../../config';
import { useAuth } from '../../../auth';
import { formsStageId, hasAdminRights, unauthorizedMsg } from '../../../../utils';
import { useSubmissionConversion, processUrl, resetState } from '../submissionConversionContext';
import { SubmissionConvertButtons } from '../../convertButtons';

const ProcessSubmissionView = (props) => {
  const history = useHistory();
  const { hideComponents, readOnly, formName, options: propsOptions,} = props;
  const { formId } = useParams();
  const processRef = useRef();
  const processSupplementFormRef = useRef();
  const {state: formState } = useForm();
  const {state: authState } = useAuth();
  const { user, userInfo, is: role } = authState;
  const isAdmin = hasAdminRights(role);
  const {isActive: isFormActive, error: formError, form, clonedForm} = formState;
  const forbidPdfDownload = _.get(formState, 'formFolder.data.folder.data.forbidPdfDownload');

  const {state: submissionState, dispatch: dispatchSubmissionAction} = useSubmission();
  const {url, submission, error: submissionError, isActive: isSubmissionActive, route, processInitPage} = submissionState;
  const {dispatch: dispatchProcessAction} = useSubmissionConversion();

  const options = { template: 'bootstrap', iconset: 'fa', readOnly: !!readOnly, initPage: processInitPage,  ...formState.options, ...propsOptions, userId: user._id, userProfileId: userInfo._id, isProcess: true};

  const onSubmit = (submission) => {
    if (!formState.hasFormWriteAccess) {
      alert(unauthorizedMsg(formId));
      history.goBack();
      return;
    } 
    const processSupplement = processSupplementFormRef.current.submission;
    _.set(submission, 'metadata.processSupplement', processSupplement);
   
    saveSubmission(dispatchSubmissionAction, submission, formId, formName);
    dispatchSubmissionAction(setInitPage(processRef.current.page));
  }

  const isLoading = (isFormActive || isSubmissionActive || _.isEmpty(submission) || _.isEmpty(form)) && !submissionError;

  const onSaveProcess = (event) => {
    event.preventDefault();
    processRef.current.emit('submitButton', { state: 'draft' });
  };

  const handlePageChange = (page) => {
    if (!isAdmin && forbidPdfDownload) {
      return;
    }

    if (page.component.stageId !== formsStageId) {
      return resetState(dispatchProcessAction);
    }

    let formComponent;

    Utils.eachComponent(page.components, (comp) => {
      if(comp.component.type === 'form') {
        formComponent = comp.component;
      }
    });

    processUrl(dispatchProcessAction, submission.data[formComponent.key]._id, formComponent.form);
  }

  if (isLoading ) {
    return <Loading />;
  }

  return (
    <div>
      <AppErrors errors={[formError, submissionError]} />
      <ProcessHeader form={form} submission={submission}>
        {!route && !readOnly && formState.hasFormWriteAccess &&<button className="btn btn-primary" onClick={onSaveProcess}>Save Process</button> }
      </ProcessHeader>
      <SubmissionConvertButtons />
      {!route
        ? <Form
            form={clonedForm}
            submission={submission}
            url={url}
            hideComponents={hideComponents}
            onSubmit={onSubmit}
            formReady={(form)=> {
              processRef.current = form;
              form.on('routeToStage', ({routeStage, routeRecipients}) => {
                setRouteSettings(dispatchSubmissionAction, {routeStage, routeRecipients});
              });
              form.on('wizardPageSet', (page) => {
                handlePageChange(page);
              });
            }}
            options={options}
          />
        : <SubmissionRoute/>
      }
      <hr/>
      {!route && <Form
        submission={_.get(submission, 'metadata.processSupplement', {})}
        src={FormsConfig.getSrc('processSupplement')}
        formReady={(form) => {
          processSupplementFormRef.current = form;
        }}
        options={{template: 'bootstrap', iconset: 'fa', noValidate: true, readOnly: !formState.hasFormWriteAccess, user: user.data}}
      />}
    </div>
  );
};

export default ProcessSubmissionView;
