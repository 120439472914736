import { NavLink, Route, Switch } from 'react-router-dom';
import React from 'react';
import _ from 'lodash';
import RoutedSubmissionsList from './RoutedSubmissionsList';
import { SubmissionsProvider } from '../../submission/submissionsContext';
import { Title } from '../../../../common';

const AllRoutedSubmissionsPage = () => {
  const navbarLinks = _.sortBy([ 
    {icon: "", path: `/routed`, title: 'All Routed', priority: 10 },
    {icon: "", path: `/routed/archived`, title: 'All Routed Archived', priority: 20 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-3">
      {navbarLinks.map(link => {
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true } to={link.path}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

  return (
    <>
      <Title text={'All Routed Submissions'}/>
      <Navbar/>
      <SubmissionsProvider>
        <Switch>
          <Route
            path="/routed"
            exact
            render={(props) =><RoutedSubmissionsList {...props} gridName={'allRoutedTableFilter'} />}
          />
          <Route
            path="/routed/archived"
            exact
            render={(props) => <SubmissionsProvider><RoutedSubmissionsList {...props} archived={true} gridName={'allRoutedArchivedTableFilter'} /></SubmissionsProvider>}
          />
        </Switch>
      </SubmissionsProvider>
    </>
)};

export default AllRoutedSubmissionsPage;
