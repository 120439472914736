import React from 'react';
import { AppConfig } from '../../config';

const Footer = () => {
  const styles = {
    padding: '15px',
  };

  return (
    <footer className="footer bg-light shadow-lg">
      <div className="container text-center mt-auto" style={styles}>
        Built with ♥ by&nbsp;
        <a href="https://form.io">Form.io</a>
        <span className="text-muted mx-2" >|</span>
        <span className="text-muted" >App version: {AppConfig.version}</span>
      </div>
    </footer>
  );
};

export default Footer;
