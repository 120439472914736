import React from 'react';
import { Modal, Confirm } from '../../../common';

const ModalConfirmation = (props) => {
  const {
    onNo =()=>{},
    onYes =()=>{},
    onModalCloseClick = () => {},
    message
  } = props;

  return (
    <Modal width={'70%'} height={'30%'} contentStyles={{top: '30%'}}>
    <div className="modal-content font-weight-light rounded" >
      <button type="button " className="close text-right mr-2" onClick={onModalCloseClick} data-dismiss="modal" aria-label="Close">
        <span  aria-hidden="true">&times;</span>
      </button>
      <div className="modal-body">
        <Confirm
          message={message}
          wrapperStyles={{margin: '1rem 2rem'}}
          onYes={onYes}
          onNo={onNo}
        />
      </div>
    </div>
  </Modal>
  )
}

export default ModalConfirmation;
