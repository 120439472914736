import { Formio } from "@formio/react";

const BasicEditForm = Formio.AllComponents.button.editForm;

export default function some(...extend) {
  return BasicEditForm(
    [
      {
        key: "display",
        components: [
          {
            key: "action",
            ignore: true,
          },
          {
            key: "custom",
            ignore: true,
          },
          {
            label: "Target Form Page",
            widget: "choicesjs",
            tableView: true,
            dataSrc: "custom",
            data: {
              custom:
                "let pages = instance.options.editForm.components;\nlet formStageId = '-1';\nlet stagePages = _.filter(pages, page => page.stageId === formStageId);\nvalues = _.map(stagePages, (page, index) => ({label: page.title, value: page.key }))",
            },
            selectThreshold: 0.3,
            valueProperty: "value",
            template: "<span>{{ item.label  }}</span>",
            key: "targetFormPage",
            type: "select",
            input: true,
            validate: {
              required: true,
            },
            weight: 5,
          },
          {
            label: "Form Back Button Path",
            widget: "choicesjs",
            tableView: true,
            weight: 7,
            dataSrc: "custom",
            data: {
              custom:
                "let pages = instance.options.editForm.components;\nlet editStageId= instance.options.stage;\nlet stagePages = _.filter(pages, page => page.stageId === editStageId);\nvalues = _.map(stagePages, (page, index) => ({label: page.title, value: page.key }));",
            },
            valueProperty: "value",
            selectThreshold: 0.3,
            // eslint-disable-next-line no-template-curly-in-string
            customDefaultValue: "let pages = instance.options.editForm.components;\nlet currentPage = _.get(pages, `[${instance.options.page}]`, {});\nvalue = currentPage.key || '';",
            key: "formBackPath",
            type: "select",
            input: true,
          },
          {
            label: "Show Form Next Button",
            tableView: false,
            weight: 9,
            key: "formNextButton",
            type: "checkbox",
            input: true,
            defaultValue: false,
          },
          {
            label: "Form Next Button Path",
            widget: "choicesjs",
            tableView: true,
            weight: 12,
            dataSrc: "custom",
            data: {
              custom:
                "let pages = instance.options.editForm.components;\nlet editStageId= instance.options.stage;\nlet stagePages = _.filter(pages, page => page.stageId === editStageId);\nvalues = _.map(stagePages, (page, index) => ({label: page.title, value: page.key }));",
            },
            valueProperty: "value",
            selectThreshold: 0.3,
            validate: {
              required: true,
            },
            key: "formNextPath",
            conditional: {
              show: true,
              when: "formNextButton",
              eq: "true",
            },
            type: "select",
            input: true,
          },
        ],
      },
    ],
    ...extend
  );
}
