import React, {useState} from 'react';
import { TrackSubmissionForm } from '.';

const RoutedSubmissionAlert = (props) => {
  const {
    routedSubmission = {},
    message
  } = props;

  const [showRoute, setShowRoute] = useState(false);

  return (
    <div className="alert alert-primary" role="alert">
      {message}
      <div className="mt-2 font-italic">
        <input className="mr-2" type="checkbox" id="track" name="scales"
          checked={showRoute}
          onChange={() => setShowRoute(!showRoute)}
        />
      <label htmlFor="track">Show Route Details</label>
      </div>
      {showRoute && <TrackSubmissionForm routeTrack={routedSubmission}/>}
    </div>
  )
}

export default RoutedSubmissionAlert;
