import React, {useRef} from 'react';
import { Form } from '@formio/react';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';

import { Loading, Title, AppErrors } from '../../../../common';
import { useForm } from '../formContext';
import {
  useSubmission,
  useSubmissions,
  saveSubmission,
  resetSubmissions,
} from '../../submission';
import { SubmissionName } from '../../partials';
import { useAuth } from '../../../auth'
import { autoNameFieldKey, setProcessFormsSubmissionOwner, setProcessSubmissionFirstStage, setSubmissionName, setSubmissionOwner } from '../../../../utils';

const FormView = (props) => {
  const {
    submission,
    hideComponents,
    name,
    pathStart,
    formName,
    title,
    options: propsFormOptions,
    addSubmissionAccess,
    location
  } = props;

  const history = useHistory();

  const { formId } = useParams();
  const { dispatch: dispatchSubmissionAction, state: submissionState } = useSubmission();
  const { dispatch: dispatchSubmissionsAction } = useSubmissions();
  const {state: userState} = useAuth();
  const {userInfo, user } = userState;
  const requiredName = !!formId;

  const { state: formState } = useForm();
  const {
    form,
    clonedForm,
    error: formError,
    isActive,
    url,
    options: formOptions,
    autofilledFields,
    isProcess, autoName
  } = formState;

  const options = {template: 'bootstrap', iconset: 'fa',  ...formOptions, ...propsFormOptions, userId: user._id, userProfileId: userInfo._id};

  const onSubmit = (submission) => {
    if(isProcess) {
      setProcessSubmissionFirstStage(submission, form);
      setProcessFormsSubmissionOwner(form, submission, user);
    }

    if (addSubmissionAccess) {
      setSubmissionOwner(submission, user);
    }

    saveSubmission(dispatchSubmissionAction, submission, formId, name, (err, submission) => {
      if (!err) {
        dispatchSubmissionsAction(resetSubmissions());
        history.push(pathStart ||  `${formId ? `/form/${formId}/submission` : `${'/' + formName}`}/${submission._id}`);
      } 
    });
  }

  const nameForm = useRef();
  const submitBtn = useRef();
  const formRef = useRef();

  const autofillForm = () => {
    autofilledFields.forEach(field => {
      const autofilledField = formRef.current.getComponent(field.path);
      const setAutoValue = (prefilledField) => {
        if (prefilledField) {
          const value = prefilledField.evaluate(field.autofilledValue, {userProfile: userInfo.data}, 'value');
          prefilledField.setValue(value);
        }
      };
    
      if (_.isArray(autofilledField)) {
        _.each(autofilledField, prefilledField => {
          setAutoValue(prefilledField);
        });
      }
      else {
        setAutoValue(autofilledField);
      }
      
    });

    formRef.current.onChange();
  }

  const AutoFillBtn = () => {
    return !!autofilledFields.length 
      ? (<div className="d-flex justify-content-end mt-2">
          <button onClick={autofillForm} className="btn btn-primary" >Autofill With User Profile Data</button>
        </div>)
      : null;
  }

  if (isActive) {
    return <Loading />;
  }

  return (
    <div>
      <Title text={title || `New ${ form.title }`}/>
      <AppErrors errors={[formError, submissionState.error]} />
      <AutoFillBtn/>
      {requiredName && !autoName && <SubmissionName
          onFormReady={(form) => {nameForm.current = form;}}
          onNameChange={(name) => {
            if (name && requiredName) {
              if (submitBtn.current && submitBtn.current.hasError && !submitBtn.current?.root?.errors.length) {
                submitBtn.current.triggerChange();
              }
            }
          }}
      />}
      <Form
        form={isProcess ? clonedForm : form}
        submission={location?.state || submission}
        url={url}
        formReady={(form)=> {
          formRef.current = form;
          submitBtn.current = form.getComponent('submit');
        }}
        options={{...options,
          hooks: {
          customValidation: function (submission, next) {
            if (!requiredName) {
              next();
            }

            const submNameForm= nameForm.current;
            const submissionName = autoName ?  _.get(submission, `data.${autoNameFieldKey}`, '') : _.get(submNameForm, 'submission.data.submissionName', '');

            if (submissionName) {
              setSubmissionName(submission, submissionName)
              next();
            } 
            else {
              submNameForm.getComponent('submissionName').pristine = false;
              submNameForm.getComponent('submissionName').checkValidity();
              submNameForm.getComponent('submissionName').focus();
              
              next('Please add name for this submission.');
            }
          }
        }}}
        hideComponents={hideComponents}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default FormView;
