import { Templates, Formio } from '@formio/react';
import BuilderUtils from 'formiojs/utils/builder.js';
import Webform from 'formiojs/Webform.js';
import {Utils} from 'formiojs';
import _ from 'lodash';
import { formsStageId } from '../utils';


const WebformBuilder = Formio.Builders.getBuilder('webform');
class ProcessBuilder extends WebformBuilder {
  constructor() {
    let element, options;
    if (arguments[0] instanceof HTMLElement || arguments[1]) {
      element = arguments[0];
      options = arguments[1];
    }
    else {
      options = arguments[0];
    }
    
    // Reset skipInit in case PDFBuilder has set it.
    options.skipInit = false;
    options.display = 'wizard';

    super(element, options);

    this.page = this.options.page = 0;

    // Need to create a component order for each group.
    for (const group in this.groups) {
      if (this.groups[group] && this.groups[group].components) {
        this.groups[group].componentOrder = Object.keys(this.groups[group].components)
          .map(key => this.groups[group].components[key])
          .filter(component => component && !component.ignore)
          .sort((a, b) => a.weight - b.weight)
          .map(component => component.key);
      }
    }

    this.options.hooks.attachPanel = (element, component) => {
      if (component.refs.removeComponent) {
        this.addEventListener(component.refs.removeComponent, 'click', () => {
          const pageIndex = this.pages.findIndex((page) => page.key === component.key);
          const componentIndex = this._form.components.findIndex((comp) => comp.key === component.key);
          if (pageIndex !== -1) {
            this.removePage(pageIndex, componentIndex);
          }
        });
      }
    };

    const originalRenderComponentsHook = this.options.hooks.renderComponents;
    this.options.hooks.renderComponents = (html, { components, self }) => {
      if (self.type === 'form' && !self.root) {
        return html;
      }
      else {
        return originalRenderComponentsHook(html, { components, self });
      }
    };

    const originalAttachComponentsHook = this.options.hooks.attachComponents;
    this.options.hooks.attachComponents = (element, components, container, component) => {
      if (component.type === 'form' && !component.root) {
        return element;
      }

      return originalAttachComponentsHook(element, components, container, component);
    };

    // Wizard pages don't replace themselves in the right array. Do that here.
    this.on('saveComponent', (component, originalComponent) => {
      const webformComponents = this.webform.components.map(({ component }) => component);
      if (this._form.components.includes(originalComponent)) {
        this._form.components[this._form.components.indexOf(originalComponent)] = component;
        this.rebuild();
      }
      else if (webformComponents.includes(originalComponent)) {
        this._form.components.push(component);
        this.rebuild();
      }
      else {
        // Fallback to look for panel based on key.
        const formComponentIndex = this._form.components.findIndex((comp) => originalComponent.key === comp.key);
        if (formComponentIndex !== -1) {
          this._form.components[formComponentIndex] = component;
          this.rebuild();
        }
      }
    }, true);
  }

  allowDrop(element) {
    return (this.webform && this.webform.refs && this.webform.refs.webform === element) ? false : true;
  }

  get formsStageId() {
    return formsStageId;
  }

  get pages() {
    return _.filter(this._form.components, { type: 'panel' });
  }

  get currentPage() {
    const pages = this.pages;
    return (pages && (pages.length >= this.page)) ? pages[this.page] : null;
  }

  set form(value) {
    this._form = value;
    if (!this._form.components || !Array.isArray(this._form.components)) {
      this._form.components = [];
    }

    const {stagesSettings } = value.properties;
    this.stage = this.options.stage = _.get(stagesSettings, '[0].id', '');
    const stagesCount = stagesSettings.length;


    if (this.pages.length === 0) {
      // const components = this._form.components.filter((component) => component.type !== 'button');
      this._form.components = [...this.getDefaultStages(0, stagesCount, stagesSettings)];
    }
    else {
      const stagesIds = _.chain(stagesSettings).map(stage => stage.id).value();
      const groupedPages = _.chain(this._form.components)
        .filter(page => _.includes([...stagesIds, this.formsStageId], page.stageId))
        .groupBy('stageId')
        .value();

      const reorderedPages = _.map(stagesIds, stageId => groupedPages[stageId] || []);
      reorderedPages.push(groupedPages[this.formsStageId])
      this._form.components = _.flatten(reorderedPages) ;

      const currentStagesIds = _.chain(this._form.components).filter(page => page.stageId !== this.formsStageId).groupBy('stageId').keys().value();
      const currentStagesCount = currentStagesIds.length;

      if (currentStagesCount < stagesCount) {
        const formComponents = _.map([...stagesIds, this.formsStageId], (stageId, index) => {
          const stageComponents = _.filter(this._form.components, page => page.stageId === stageId);
          return !!stageComponents.length ? stageComponents : this.getPageConfig(1, [], stageId) 
        });
        this._form.components = _.flatten(formComponents);
      } 
    }
    this.addAccessControlFields();

    this.rebuild();
  }


  addAccessControlFields() {
    const accessControlComp = _.get(this.options, 'accessControlComp', {});

    const accessControlCompKey = accessControlComp.key;
    _.each(this._form.components, (panel, index) => {
      panel.components = _.filter(panel.components, comp => comp.key !== accessControlCompKey);
      if(index === 0) {
        panel.components.push(accessControlComp);
      }
    });
  }

  get form() {
    return this._form;
  }

  get schema() {
    _.assign(this.currentPage, this.webform._form.components[0]);

    const webform = new Webform(this.options);
    webform.setForm(this._form, { noEmit: true });
    return webform.schema;
  }

  render() {
    return this.renderTemplate('builderWizard', {
      sidebar: this.renderTemplate('builderSidebar', {
        scrollEnabled: this.sideBarScroll,
        groupOrder: this.groupOrder,
        groupId: `builder-sidebar-${this.id}`,
        groups: this.groupOrder.map((groupKey) => this.renderTemplate('builderSidebarGroup', {
          group: this.groups[groupKey],
          groupKey,
          groupId: `builder-sidebar-${this.id}`,
          subgroups: this.groups[groupKey].subgroups.map((group) => this.renderTemplate('builderSidebarGroup', {
            group,
            groupKey: group.key,
            groupId: `group-container-${groupKey}`,
            subgroups: []
          })),
        })),
      }),
      pages: this.pages,
      stages: this.stagesSettings,
      form: this.webform.render(),
      formsStage: this.isFormsStage()
    });
  }

  attach(element) {
    this.loadRefs(element, {
      addPage: 'multiple',
      gotoPage: 'multiple',
      gotoStage: 'multiple',
      gotoFormsStage: 'multiple',
    });

    this.refs.addPage.forEach(link => {
      this.addEventListener(link, 'click', (event) => {
        event.preventDefault();
        this.addPage();
      });
    });

    this.refs.gotoPage.forEach((link, index) => {
      this.addEventListener(link, 'click', (event) => {
        event.preventDefault();
        this.setPage(+event.target.dataset.index);
      });
    });

    this.refs.gotoStage.forEach((link, index) => {
      this.addEventListener(link, 'click', (event) => {
        event.preventDefault();
        const stageId = _.get(this.stagesSettings, `[${index}].id`, '')
        this.stage = this.options.stage  = stageId;
        this.setPage(_.findIndex(this.pages, page => page.stageId === stageId));
      });
    });

    this.refs.gotoFormsStage.forEach((link, index) => {
      this.addEventListener(link, 'click', (event) => {
        event.preventDefault();
        this.stage = this.formsStageId;
        
        this.setPage(_.findIndex(this.pages, page => page.stageId === this.formsStageId));
      });
    });

    return super.attach(element);
  }

  rebuild() {
    const page = this.currentPage;
    this.webform.setForm({
      display: 'form',
      type: 'form',
      components: page ? [page] : [],
    }, { keepAsReference: true });
    return this.redraw();
  }

  get stagesSettings() {
    return  _.get(this._form, 'properties.stagesSettings', [])
  }

  isFormsStage() {
    return this.stage === this.formsStageId;
  }

  addPage(page) {
    const pagePrevIndex = _.findLastIndex(this.pages, page => page.stageId === this.stage);
    const pageIndex = _.filter(this.pages, page => page.stageId === this.stage).length + 1;
    const existingPage = page && page.schema;

    const newPage = existingPage
      ? Utils.fastCloneDeep(page.schema) 
      : this.getPageConfig(pageIndex, [], this.stage);

    BuilderUtils.uniquify(this._form.components, newPage);

    existingPage 
    ? this._form.components.push(newPage)
    : this._form.components.splice(pagePrevIndex+1, 0, newPage);

    this.emitSaveComponentEvent(
      newPage,
      newPage,
      this._form,
      'components',
      (this._form.components.length - 1),
      true,
      newPage
    );

    this.emit('change', this._form);
    return this.rebuild();
  }

  removePage(pageIndex, componentIndex) {
    this._form.components.splice(componentIndex, 1);
    this.emit('change', this._form);
    if (pageIndex === 0) {
      this.addAccessControlFields();
    }

    if (pageIndex === this.pages.length) {
      // If the last page is removed.
      const isLastStagePage =  !_.some(this._form.components, panel => panel.stageId === this.stage);
      if (isLastStagePage) {
        this._form.components.push(this.getPageConfig(1, [], this.stage));
        this.addAccessControlFields();
        return this.rebuild();
      }
      else {
        return this.setPage(pageIndex - 1);
      }
    }
    else {
      const isLastStagePage =  !_.some(this._form.components, panel => panel.stageId === this.stage);
      if (isLastStagePage) {
        this._form.components.splice(pageIndex, 0, this.getPageConfig(1, [], this.stage));
        this.addAccessControlFields();
      }

      this.setPage(_.findIndex(this._form.components, panel => panel.stageId === this.stage));
      return this.rebuild();
    }
  }

  setPage(index) {
    if (index === this.page) {
      return;
    }
    this.page = this.options.page = index;
    return this.rebuild();
  }

  getDefaultStages(startIndex = 0, stagesCount = 0, stagesSettings = []) {
    const stages = [];
    for(let stage = startIndex; stage<(startIndex + stagesCount); stage++) {
      stages.push(this.getDefaultStage(stagesSettings[stage].id));
    }
    stages.push(this.getPageConfig(1, [],this.formsStageId));
    return _.flatten(stages);
  }

  getDefaultStage(stageId) {
    return [this.getPageConfig(1, [], stageId)];
  }

  getPageConfig(index, components = [], stageId) {
    const panelSettings = {
      type: 'panel',
      components,
      stageId,
      buttonSettings: {
        previous: false,
        cancel: false,
        next: false
      },
    };
    
    return stageId === this.formsStageId
      ? {
          title: `Form ${index}`,
          label: `Form ${index}`,
          key: `form${index}Stage${stageId}`,
          ...panelSettings
        }
      : {
          title: `Page ${stageId}-${index}`,
          label: `Page ${stageId}-${index}`,
          key: `page${index}Stage${stageId}`,
          ...panelSettings
        };
  }

  pasteComponent(component) {
    if (component instanceof ProcessBuilder) {
      return;
    }
    if (this._form.components.find(comp => _.isEqual(component.component, comp))) {
      this.addPage(component);
    }
    else {
      return super.pasteComponent(component);
    }
  }
}

Templates.current.builderWizard.form = (ctx) => {
  return `<div class="formio builder row formbuilder">
  <div class="col-xs-4 col-sm-3 col-md-2 formcomponents">
    ${ctx.sidebar}
  </div>
  <div class="col-xs-8 col-sm-9 col-md-10 formarea">
    <span class="badge badge-light">
      Stages
    </span>
    <div class="d-flex flex-row">
      <ol class="breadcrumb w-100">
        ${ctx.stages.map(function(stage) {
        return  `<li>
          <span title="${stage.title}" class="mr-2 badge ${stage.id === ctx.self.stage ? 'badge-primary' : 'badge-info'} wizard-page-label" ref="gotoStage">${stage.title}</span>
        </li>`
        }).join('') }
      
      </ol>
      <div class="breadcrumb d-block border-left border-light" title="Process Forms"  >
        <span title="Process Forms" class="badge badge-secondary ${ctx.formsStage ? 'text-dark' : '' } p-1 wizard-page-label" ref="gotoFormsStage">PROCESS FORMS</span>
      </div>
    </div>
    <span  class="badge badge-light">
      Stage Panels
    </span>
    <ol class="breadcrumb">
      ${ctx.pages.map(function(page, pageIndex) {
      return  page.stageId === ctx.self.stage ? `<li>
        <span title="${page.title}" data-index="${pageIndex}" class="mr-2 badge ${ctx.formsStage && pageIndex === ctx.self.page ? 'text-dark' : '' } ${ctx.formsStage ? 'badge-secondary' : pageIndex === ctx.self.page ? 'badge-primary' : 'badge-info'} wizard-page-label" ref="gotoPage">${page.title}</span>
      </li>` : '';
      }).join('') }
      <li>
        <span title="${ctx.t(ctx.formsStage ? 'Add Form Page':'Create Page')}" class="mr-2 badge badge-success wizard-page-label" ref="addPage">
          <i class="${ctx.iconClass('plus')}"></i> ${ctx.t(ctx.formsStage ? 'Form':'Page')}
        </span>
      </li>
    </ol>
    <div ref="form">
      ${ctx.form}
    </div>
  </div>
</div>`
}

export default ProcessBuilder;