import { Route, Switch } from 'react-router-dom';
import React from 'react';
import NamedSubmissionsList from './NamedSubmissionsList';
import { SubmissionsProvider } from '../submissionsContext';
import SubmissionPage from './SubmissionPage';
import { SubmissionProvider } from '../submissionContext';
import { useAuth } from '../../../auth';
import { hasAdminRights } from '../../../../utils';
import { useForm } from '../../form';

const SubmissionsPage = () => {
  const {state: authState} = useAuth();
  const {is: role } = authState;
  const isAdmin = hasAdminRights(role);
  const { state: formState} = useForm();

  return (
    <SubmissionsProvider>
      <Switch>
        <Route
          exact
          path="/form/:formId/submission"
          render={(props) => <NamedSubmissionsList 
            hideAddBtn={isAdmin} 
            submissionsRequestParams={isAdmin 
              ? {query: {
                  'metadata.inProcess__exists': false,
                }} 
              : {query: {
                  'metadata.routed__exists': false,
                  'metadata.status__exists': false,
                  'metadata.inProcess__exists': false,
                }}
            }
            gridOptions={{
              hideTrack: true,
              hideSelect: isAdmin,
              hide: {
                deletePermanently: true,  
                moveToCurrent: true, 
                moveToArchived: !formState.hasFormWriteAccess ? true : false, 
                moveToDeleted: !formState.hasFormWriteAccess ? true : false
              }, 
            }}
            title={ `Current Submissions - ${formState.form.title} Form `}
            {...props} 
          />}
        />
        <Route
          path="/form/:formId/submission/:submissionId"
          render={(props) => <SubmissionProvider><SubmissionPage {...props} /></SubmissionProvider>}
        />
      </Switch>
    </SubmissionsProvider>
  )
};

export default SubmissionsPage;
