import { Route, Switch } from 'react-router-dom';
import React from 'react';
import TrackedSubmissionsList from './TrackedSubmissionsList';
import TrackedSubmissionPage from './TrackedSubmissionPage';
import { SubmissionProvider } from '../../submission/submissionContext';
import { SubmissionsProvider } from '../../submission/submissionsContext';

const TrackedSubmissionsPage = () => (
  <SubmissionsProvider>
    <Switch>
      <Route
        exact
        path="/form/:formId/tracked"
        component={TrackedSubmissionsList}
      />
      <Route
        path="/form/:formId/tracked/:submissionId"
        render={(props) => <SubmissionProvider><TrackedSubmissionPage {...props} /></SubmissionProvider>}
      />
    </Switch>
  </SubmissionsProvider>
);

export default TrackedSubmissionsPage;
