import React, { useEffect, useCallback, useState} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form, Formio } from '@formio/react';
import _ from 'lodash';

import { Loading, Title, AppErrors } from '../../../../common';
import { useForm } from '../../form';
import { useSubmissions, saveSubmissions, deleteSubmissions } from '../submissionsContext';
import { FormsConfig } from '../../../../config';
import { ModalConfirmation, RouteTrackingModalWithRequest } from '../../partials';
import { addSelectedRowsClass, setSubmissionStatus, submissionStatuses, unsetSubmissionStatus } from '../../../../utils';
import { useAlerts } from '../../../alerts';
import { SubmissionProvider } from '../submissionContext';


const NamedSubmissionsList = (props) => {
  const {
    FormError,
    gridOptions ={},
    formName,
    getViewPath,
    createSubmissionPath,
    addBtnTitle,
    id,
    submissionsRequestParams = {},
    title,
    hideAddBtn
  } = props;


  const defaultRequestParams = {
    limit: 100000,
    query: {},
    select: '',
    sort: '-modified',
    ...submissionsRequestParams
  };

  const defaultStatusAction = {
    active:false,
    selectedSubmissions: [],
    delete: false,
    archive: false,
    moveToCurrent: false,
    deletePermanently: false,
  };
  
  const [statusAction, setStatusAction] = useState(defaultStatusAction);
  const [requestParams, setRequestParams] = useState(defaultRequestParams);
  const [paramsSet, setParamsSet] = useState(false);
  const [page, setPage] = useState(null);
  const [trackingSubmissionId, setTrackingSubmissionId] = useState(null);

  const history = useHistory();
  const formId  = useParams().formId || id;

  const { state: submissionsState, dispatch: dispatchSubmissionsAction } = useSubmissions();
  const { state: formState} = useForm();
  const { addAlert } = useAlerts();

  useEffect(()=> {
    setRequestParams((requestParams) => {
      const updatedRequestParams = {...requestParams, ...submissionsRequestParams};
      setParamsSet(true);

      return !_.isEqual(requestParams, updatedRequestParams) 
        ? updatedRequestParams
        : requestParams;
    });
  }, [submissionsRequestParams]);

  const getListDataUrl = useCallback(
    () => {
      const query = _.chain(requestParams.query).map((value, key) => `${key}=${value}`).join('&').value();
      const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;

      return `${Formio.getProjectUrl()}${formPath}/submission?${query ? '&'+ query : ''}`;
    },
    [requestParams, formId, formName]
  );

  const onStatusChanged = () => {
    setStatusAction(defaultStatusAction);
    setPage(null);
  }

  const onNo = () => {
    setStatusAction({
      ...defaultStatusAction,
      selectedSubmissions: statusAction.selectedSubmissions,
    })
  };

  const onYes = () => {
    const {selectedSubmissions, archive, moveToCurrent, delete: deleteAction, deletePermanently} = statusAction;

    if (deletePermanently) {
      deleteSubmissions(dispatchSubmissionsAction, _.map(selectedSubmissions, subm => subm._id), formId, (err) => {
        if(!err) {
          onStatusChanged();
         addAlert({ type: 'success', content: 'Form Submissions are successfully deleted'});
        }
      });
      return;
    }

    const newStatus = archive 
      ? submissionStatuses.archived
      : deleteAction 
        ? submissionStatuses.deleted 
        : '';

    const updatedSubmissions = _.chain(selectedSubmissions)
      .each(subm => {
        newStatus 
        ? setSubmissionStatus(subm, newStatus)
        : unsetSubmissionStatus(subm)
      })
      .value();

    saveSubmissions(dispatchSubmissionsAction, updatedSubmissions, formId, null, (err) => {
      if(!err) {
        onStatusChanged();
        addAlert({ type: 'success', content: `Form Submissions are successfully moved to the ${(moveToCurrent && 'Current')  || (deleteAction && 'Deleted') || (archive && 'Archived')}`}); 
      }
    });
  }

  const isLoading = formState.isActive || submissionsState.isActive || !paramsSet;

  if (isLoading) {
    return <Loading />;
  }

  const Grid = () => (
    <Form
      submission={{data: {selectedSubmissions: statusAction.selectedSubmissions}}}
      src={FormsConfig.getSrc('namedSubmissionsTable')}
      options={{
        noSubmit: true,
        fetchUrl: getListDataUrl(),
        ...formState.options,
        ...gridOptions
      }}
      formReady={(form) => {
        const gridComp = form.getComponent('submissions');

        gridComp.dataTableReady.promise.then(() => {
          if (_.isNumber(page)) {
              gridComp.formioGrid.pagination().component.currentPage = page;
          }
        });

        form.on('open', ({_id: submissionId})=> {
          if (submissionId) {
            history.push(getViewPath ? getViewPath(formId, submissionId) : `/form/${formId}/submission/${submissionId}`);
          }
        });

        form.on('page', ()=> {
          addSelectedRowsClass(form, form.data.selectedSubmissions);
        });

        form.on('deselectAll', () => {
          addSelectedRowsClass(form, []);
        });

        form.on('moveToArchived', () => {
          setPage(gridComp.formioGrid.currentPage);
          setStatusAction({
            ...defaultStatusAction,
            active: true,
            selectedSubmissions: form.data.selectedSubmissions || [],
            archive: true
          });
        });

        form.on('moveToDeleted', () => {
          setPage(gridComp.formioGrid.currentPage);

          setStatusAction({
            ...defaultStatusAction,
            active: true,
            selectedSubmissions: form.data.selectedSubmissions || [],
            delete: true,
          })
        });

        form.on('moveToCurrent', () => {
          setPage(gridComp.formioGrid.currentPage);
          setStatusAction({
            ...defaultStatusAction,
            active: true,
            selectedSubmissions: form.data.selectedSubmissions || [],
            moveToCurrent: true,
          })
        });

        form.on('deletePermanently', () => {
          setPage(gridComp.formioGrid.currentPage);
          setStatusAction({
            ...defaultStatusAction,
            active: true,
            selectedSubmissions: form.data.selectedSubmissions || [],
            deletePermanently: true,
          })
        });

        form.on('track', ({_id: submissionId})=> {
          setPage(gridComp.formioGrid.currentPage);
          setTrackingSubmissionId(submissionId);
        });
 
        form.on('selectRow', (data)=> {
          const selectedSubmissions = form.data.selectedSubmissions;
          const submissionId = data._id;

          form.data.selectedSubmissions = _.some(selectedSubmissions, selectedSubm => selectedSubm._id === submissionId)
            ? [..._.filter(selectedSubmissions, selectedSubm => selectedSubm._id !== submissionId)]
            : [...selectedSubmissions, data];

          addSelectedRowsClass(form, form.data.selectedSubmissions);
          form.triggerChange();
        });
      }}
    />
  );

  const titleText = title || '';

  const AddBtn = () => {
    return hideAddBtn
      ? null
      : (<div className="d-flex justify-content-end mb-2">
          <Link className='btn btn-primary' to={createSubmissionPath || `/form/${formId || formState.id}`}>
            <i className='glyphicon glyphicon-plus fa fa-plus' aria-hidden='true'></i>
            &nbsp; {addBtnTitle || 'New ' + formState.form?.title}
          </Link>
        </div>)
  };

  const getConfirmationMessage = () => {
    const {delete: deleteAction, moveToCurrent, archive, deletePermanently} = statusAction;

    if(archive) {
      return <span>Are you sure you wish to move <strong>all selected form submission(s)</strong>  to the <strong>Archived</strong>?</span>
    }

    if (deleteAction) {
      return <span>Are you sure you wish to move <strong>all selected form submission(s)</strong> to the <strong>Deleted</strong>?</span>;
    }

    if (moveToCurrent) {
      return <span>Are you sure you wish to move <strong>all selected form submission(s)</strong> to the <strong>Current</strong>?</span>;
    }

    if (deletePermanently) {
      return <span>You will not be able to restore form submissions after permanent deletion. Are you sure you wish to delete <strong>all selected form submission(s) permanently</strong>?</span>;
    }
  };

  const MainContent = () =>  (
    <div className='form-index position-relative'>
      {statusAction.active && <ModalConfirmation
        onNo={onNo}
        onYes={onYes}
        message={getConfirmationMessage()}
        onModalCloseClick={onNo}
      />}
      <AppErrors errors={[formState.error, submissionsState.error]} />
      {title ? <Title text={titleText}/> : null}
      {formState.hasFormWriteAccess ? <AddBtn/> : null}
      {trackingSubmissionId && <SubmissionProvider><RouteTrackingModalWithRequest trackingSubmissionId={trackingSubmissionId} onModalCloseClick={()=> setTrackingSubmissionId(null)}/></SubmissionProvider>}
      <Grid/>
    </div>
  );

  if (formState.error && FormError) {
    return(
      <FormError error={formState.error}>
        <MainContent />
      </FormError>
    );
  }

  return <MainContent />;
}

export default NamedSubmissionsList;
