import { Loading, Modal } from '../../../common';
import Login from './Login';


const LoginWrapper = (props) => {
  return (
    <div className="login-wrapper">
      <Modal className="alert alert-info">
        <div className="d-flex flex-column align-items-center">
          <Loading style={{ marginBottom: ' 10px' }}/>
          Logging In...
        </div>
      </Modal>
      <div className="login-wrapper-body">
        { props.children }
      </div>
    </div>
  );
};

const AutoLoginPage = () => {
  const onFormReady = (form) => {
    const loginBtn = form.getComponent('loginAsUserWithOpenId');
    loginBtn.refs.button.click();
  }

  return (
    <LoginWrapper>
      <Login onFormReady={onFormReady} />
    </LoginWrapper>
  );
};

export default AutoLoginPage;
