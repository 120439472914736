import React from 'react';
import { Modal } from '../../../common';

const RouteTrackingModalWrapper = (props) => {
  const {
    children,
    onModalCloseClick = () => {}
  } = props;

  return (
    <Modal width={'90%'} height={'80%'} contentStyles={{'overflowY': 'scroll'}}>
      <div className="modal-content font-weight-light submission-tracking" >
        <div className="modal-header">
          <h5 className="modal-title font-weight-normal">Route Tracking</h5>
          <button type="button" className="close" onClick={onModalCloseClick} data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {children}
      </div>
    </Modal>
  )
}

export default RouteTrackingModalWrapper;
