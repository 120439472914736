import React from 'react';
import { auth } from '@formio/react';
import { FormsConfig } from '../../config';
import { Formio } from '@formio/react';

const AuthContext = React.createContext();

const initialState = {
  init: false,
  isActive: false,
  user: null,
  authenticated: false,
  submissionAccess: {},
  formAccess: {},
  projectAccess: {},
  roles: {},
  is: {},
  error: '',
  userInfo: {},
  loggingOut: false,
  isUserInfoFetched: false,
  beforeLogoutActionExecution: false,
  beforeLogoutAction: false,
};

const parentAuthReducer = auth();
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_INFO_REQUEST':
      return {
        ...state,
        isActive: true,
        isUserInfoFetched: false,
      };
    case 'USER_INFO_SUCCESS':
      const userInfo = action.userInfo;

      return {
        ...state,
        userInfo,
        error: '',
        isActive: false,
        isUserInfoFetched: true,
      };
    case 'USER_INFO_FAILURE':
      return {
        ...state,
        error: action.error,
        isActive: false
      };
    case 'SET_BEFORE_LOGOUT_ACTION':
      return {
        ...state,
        beforeLogoutAction: action.action,
      };
    case 'SET_BEFORE_LOGOUT_ACTION_EXECUTION':
      return {
        ...state,
        beforeLogoutActionExecution: action.execution,
      };
    case 'SET_LOGGING_OUT':
        return {
          ...state,
          beforeLogoutActionExecution: false,
          loggingOut: true
        };
    case 'SET_SUCCESS_LOG_OUT': 
      return {
        ...state,
        loggingOut: false
      }
    default:
      return parentAuthReducer(state, action);
  }
};

export const requestUserInfo = () => ({
  type: 'USER_INFO_REQUEST',
});

export const addUserInfo = (userInfo) => ({
  type: 'USER_INFO_SUCCESS',
  userInfo,
});

const failUserInfo =  (error) => ({
  type: 'USER_INFO_FAILURE',
  error,
});

const logoutUser = () => ({
  type: 'USER_LOGOUT',
});

export const setBeforeLogoutAction = (action) => ({
  type: 'SET_BEFORE_LOGOUT_ACTION',
  action
});

export const setBeforeLogoutActionExecution = (execution) => ({
  type: 'SET_BEFORE_LOGOUT_ACTION_EXECUTION',
  execution
});

export const setLoggingOut = () => ({
  type: 'SET_LOGGING_OUT',
});

export const setSuccessLoggedOut = () => ({
  type: 'SET_SUCCESS_LOG_OUT',
});


export function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <AuthContext.Provider value={value} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

export const getUserInfo = (dispatch, userId, done=()=>{}) => {
    const url = `${Formio.getProjectUrl()}/${FormsConfig.userProfile}/submission`;
    const formio = new Formio(url);

    dispatch(requestUserInfo());

    formio.loadSubmissions({params: {'data.id': userId}})
      .then((result) => {
        dispatch(addUserInfo(result[0] || {}));
        done(null, result);
      })
      .catch((error) => {
        dispatch(failUserInfo(error));
        done(error);
      });
};

export const logout = (dispatch, done = ()=>{}) => {
  dispatch(setLoggingOut());

  Formio.logout().then(()=> {
    dispatch(logoutUser());
    dispatch(setSuccessLoggedOut());

    done();
  });
};
