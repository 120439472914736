import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { FormsProvider } from '../formsContext';
import { FormProvider } from '../formContext';
import FormPage from './FormPage';
import FormsListPage from './FormsListPage';
import { FilterProvider } from '../../../filter/filterContext';
import { ProcessCreatePage } from '../../process'
import { UserProvider } from '../../../users/user/userContext';

const FormsPage = () => (
   <FormsProvider>
    <Switch>
      <Route
        exact
        render={ (props) => <FilterProvider><FormsListPage {...props} /></FilterProvider>}
        path="/form"
      />
      <Route
        exact
        path="/form/create"
        render={ (props) => <FormProvider><ProcessCreatePage {...props} /></FormProvider> }
      />
      <Route
        path="/form/:formId"
        render={ (props) => <UserProvider><FormProvider><FormPage {...props} /></FormProvider></UserProvider> }
      />

    </Switch>
   </FormsProvider>
)

export default FormsPage;
