import { Form } from '@formio/react';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Loading, Progressbar, AppErrors } from '../../../../common';
import { FormsConfig } from '../../../../config'
import { useAuth, addUserInfo } from '../../../auth';
import { getForm, useForm } from '../../../forms/form';
import { useSubmission, saveSubmission } from '../../../forms/submission';
import { useUser, addUserToEveryoneGroup } from '../../../users/user/userContext';

const EmployeeInfoPage = () => {
  const formName = FormsConfig.userProfile;

  const { dispatch, state: formState } = useForm();
  const { form, isActive,  error: formError } = formState;

  const { state: authState, dispatch: dispatchAuthAction } = useAuth();
  const { user } = authState;

  const { state: submissionState, dispatch: dispatchSubmissionAction } = useSubmission();
  const { savingSubmission, error: submissionError} = submissionState;

  const { dispatch: dispatchUserAction, state: userState } = useUser();
  const {
    addingUserToEveryoneStatus,
    addingUserToEveryoneError
  } = userState;
  const { message, progress } = addingUserToEveryoneStatus;

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    getForm(dispatch, null, formName);
  }, [dispatch, formName]);

  const options = { template: 'bootstrap', iconset: 'fa', readOnly: false, ...form.options, userId: user._id, isUserProfile:true };

  const onSubmit = (submission) => {
    setIsSubmitted(true);
    saveSubmission(dispatchSubmissionAction, submission, null, formName, (err, submission) => {
      if (!err) {
        addUserToEveryoneGroup(dispatchUserAction, user, (err, userGroup) => {
          if (!err) {
            dispatchAuthAction(addUserInfo(submission));
          }
        });
      }
    });
  }

  const submission = {
    data: {
      id: user.data.id,
      firstName: user.data.firstName,
      lastName: user.data.lastName,
      email: user.data.email
    }
  };

  if (isActive || _.isEmpty(form)) {
    return <Loading />;
  }

  return (
    <div>
      <h3>{'User Profile'}</h3>
      <div className="alert alert-info" role="alert">
          <span className="">To start working with the app, please fill in your profile.</span>
        </div>
      <AppErrors errors={[formError, submissionError, addingUserToEveryoneError]} />
      {isSubmitted && ![formError, submissionError, addingUserToEveryoneError].some(Boolean)
        ? (<Progressbar message={savingSubmission ? 'Saving User Profile' : message} progress={savingSubmission ? 15 : progress} />)
        : (<Form
            form={form}
            submission={submission}
            onSubmit={onSubmit}
            options={{...options}}
          />)
      }
    </div>
  );
}

export default EmployeeInfoPage;
