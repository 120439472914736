import { Form } from '@formio/react';
import { useParams } from 'react-router';
import { useForm } from '../../form';
import * as Utils from '../../../../utils';
import { FormsConfig } from '../../../../config';
import { Title } from '../../../../common';
import { getExportQuery } from '../../../../utils/CsvUtils';

const CsvSubmissionsPage = () => {
  const params = useParams();
  const { state } = useForm();

  const options = {
    template: 'bootstrap',
    inconset: 'fa',
    form: state.form,
    isProcess: state.isProcess
  };

  const formComponents = state.isProcess
    ? state.form.components
      .filter(comp => comp.stageId === '-1')
      .map(comp => comp.components[0])
    : [];

  if (formComponents.length) {
    options.processFormIds = formComponents
      .map(comp => comp.form).join(',');
  }

  const onCsvDownload = (data) => {
    const exportQuery = getExportQuery(data);
    const formId = state.isProcess
      ? data.processForm._id
      : params.formId;
    const title = state.isProcess
      ? `${state.form.title} - ${data.processForm.title}`
      : state.form.title;
    Utils.exportSubmissions(exportQuery, formId, title);
  }

  return (
    <div>
      <Title text={state.form.title}/>
      <Form
        src={FormsConfig.getSrc('csvFilters')}
        formReady={(form)=> {
          form.on('csvDownload', onCsvDownload);
        }}
        options={options}
      />

    </div>
  );
};

export default CsvSubmissionsPage;
