import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="alert alert-warning" role="alert">
      <h3 className="alert-heading text-center">Page Not Found</h3>
      <Link className="" to="/">Go to Home Page</Link>
    </div>
  );
};

export default NotFoundPage;
