import React from 'react';
import { useAuth } from '../../modules/auth';
import { Link } from 'react-router-dom';
import { Form } from '@formio/react';
import { FormsConfig } from '../../config';
// import { StopBanner } from '../../modules/alerts/StopBanner';

const Home = () => {
  const { state: authState } = useAuth();
  const {authenticated, user} = authState;

  const Greeting = () => {
    if (authState.user && authState.user.data) {
      return (
       <>
         <h3>
          You are logged in as&nbsp;<strong>{ user.data.email }</strong>!
        </h3>
        {/* <StopBanner /> */}
       </>
      );
    }
  };

  return (
    <div className="mt-3">
      <div className="container">
        {authenticated 
          ? (<div className="well text-center">
              <Greeting/>
              <Form src={FormsConfig.getSrc('homePage' )}/>
            </div>)
          : <h3 className="well text-center">Please <Link to="/auth">log in</Link> to start using the application</h3>
        }
      </div>
    </div>
  );
};

export default Home;
