import React, { useEffect } from 'react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import _ from 'lodash';

import { useSubmission, getSubmission, SubmissionView, SubmissionDelete } from '../../../forms/submission';

const UserGroupPage = (props) => {
  const {pathStart, formName, paramName } = props;
  const eventId = useParams()[`${paramName}Id`];

  const { dispatch: dispatchSubmissionEvent, state:submissionState } = useSubmission();

  useEffect(() => {
    getSubmission(dispatchSubmissionEvent, eventId, null, formName);
  }, [eventId, dispatchSubmissionEvent, formName]);

  const View = () => <SubmissionView pathStart={pathStart} readOnly={true} formName={formName} hideLabel/>; 
  const Edit = () => <SubmissionView pathStart={pathStart}  readOnly={false} formName={formName} hideLabel/>;

  const navbarLinks = _.sortBy([ 
    {icon: "fa fa-chevron-left", getPath: (pathStart, eventId) => `${pathStart}`, title: '', priority: 0 },
    {icon: "fa fa-eye", getPath: (pathStart, eventId) => `${pathStart}/${eventId}`, title: 'View User Group', priority: 10 },
    {icon: "fa fa-edit", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/edit`, title: 'Edit User Group', priority: 20 },
    {icon: "fa fa-trash", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/delete`, title: 'Delete User Group', priority: 50 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-2">
      {navbarLinks.map(link => (
        <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact to={link.getPath(pathStart, eventId)}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );

  const deleteSubject = () => {
    const submissionData = submissionState.submission?.data;
    if (submissionData) {
      const {group, user} = submissionData;
      return `the group '${group.data.groupName}' for the user '${user.data.email}'`;
    }
    return;
  }
  return (
    <div>
      <Navbar/>
      <Switch>
        <Route exact path={`${pathStart}/:${paramName}Id`} component={View}/>
        <Route path={`${pathStart}/:${paramName}Id/edit`} component={Edit}/>
        <Route
          path={`${pathStart}/:${paramName}Id/delete`}
          render={(props) => <SubmissionDelete {...props} formName={formName} paramName={paramName} deleteSubject={deleteSubject()} pathStart={pathStart}/>}
        />
      </Switch>
    </div>
  );
};

export default UserGroupPage;
