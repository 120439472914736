import { useEffect } from 'react';
import _ from 'lodash';

import { useForm } from '../../form';
import { useAuth } from '../../../auth';
import { useSubmission } from '../../submission/submissionContext';
import { useSubmissionConversion, getPdfUrl } from '../../submission/submissionConversionContext';
import { Loading } from '../../../../common';
import { hasAdminRights } from '../../../../utils';

const SubmissionConvertButtons = () => {
  const { state: submissionState } = useSubmission();
  const { url } = submissionState;
  const { state: formState } = useForm();
  const { isProcess } = formState;
  const forbidPdfDownload = _.get(formState, 'formFolder.data.folder.data.forbidPdfDownload');
  const { state: authState } = useAuth();
  const { is: role} = authState;
  const isAdmin =  hasAdminRights(role);
  const { state: submissionConversionState, dispatch: submissionConversionAction } = useSubmissionConversion();
  const { isPdfUrlLoading, pdfUrl } = submissionConversionState;
  const isPdfAllowed = isAdmin || !forbidPdfDownload;

  useEffect(() => {
    if (isPdfAllowed && url && !isProcess) {
      getPdfUrl(submissionConversionAction, url);
    }
  }, [submissionConversionAction, url, isProcess, isPdfAllowed]);

  if (isPdfUrlLoading) {
    return <Loading style={{ width: '32px', height: '32px' }} />;
  }

  return (isPdfAllowed && pdfUrl)
  ?  (<div className="d-flex justify-content-end" role="group" aria-label="Basic example">
      <a type="button" href={pdfUrl} rel="noreferrer"  target="_blank" className="btn btn-info mr-2">PDF</a>
    </div>)
  : null;
};

export default SubmissionConvertButtons;
