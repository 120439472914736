import { Formio } from "@formio/react";

const BasicEditForm = Formio.AllComponents.button.editForm;

export default function RouteButtonEditForm(...extend) {
  return BasicEditForm(
    [
      {
        key: "display",
        components: [
          {
            key: "action",
            ignore: true,
          },
          {
            key: "event",
            ignore: true,
          },
        ],
      },
      {
        key: "route",
        label: "Route",
        weight: 5,
        components: [
          {
            label: "Route To Stage",
            widget: "choicesjs",
            validate: {
              required: true,
            },
            dataSrc: "custom",
            dataType: "string",
            valueProperty: "value",
            data: {
              custom:
                "let stages = _.get(instance, 'options.editForm.properties.stagesSettings', []);\nvalues = _.map(stages, (stage, index) => ({label: stage.title, value: stage.id}));",
            },
            key: "routeStage",
            type: "select",
            input: true,
          },
          {
            label: "Recipients",
            key: "routeRecipients",
            type: "container",
            input: true,
            components: [
              {
                label: "Route To:",
                hideLabel: false,
                key: "to",
                type: "container",
                input: true,
                components: [
                  getRecipientTypeComponent(),
                  getGroupComponents("To Group"),
                ],
              },
              {
                label: "CCs:",
                hideLabel: false,
                tableView: false,
                key: "ccs",
                type: "container",
                input: true,
                components: [
                  getRecipientTypeComponent(),
                  getGroupComponents("CC Group"),
                ],
              },
              {
                label: "BCCs:",
                hideLabel: false,
                tableView: false,
                key: "bccs",
                type: "container",
                input: true,
                components: [
                  getRecipientTypeComponent(),
                  getGroupComponents("BCC Group"),
                ],
              },
              {
                label: "HTML",
                className: "well",
                attrs: [
                  {
                    attr: "",
                    value: "",
                  },
                ],
                content:
                  "The <code>User</code> option means that any system user that has access to the process can be selected as the recipient of the submission. </br>\nThe <code>Group User</code> means that any user of specific group can be selected as the recipient of the submission. </br>\nThe <code>All Group Users</code> option means that all users of specific group will be recipients of the submission, the TO/CC/BCC field will be prefilled.",
                refreshOnChange: false,
                key: "html",
                type: "htmlelement",
                input: false,
                tableView: false,
              },
            ],
          },
        ],
      },
    ],
    ...extend
  );
}

function getGroupSelectComponent(ext = {}) {
  return {
    label: "Group",
    widget: "choicesjs",
    dataSrc: "url",
    data: {
      url: "{{Formio.getProjectUrl()}}/usersgroup/submission",
    },
    valueProperty: "_id",
    limit: 10000,
    validate: {
      required: true,
    },
    template: "<span>{{ item.data.groupName }}</span>",
    key: "group",
    lazyLoad: false,
    type: "select",
    disableLimit: false,
    searchField: "data.groupName__regex",
    authenticate: true,
    input: true,
    ...ext,
  };
}

function getGroupComponents(groupLabel = "") {
  return {
    key: "wrapper",
    type: "wrapper",
    label: "Wrapper",
    input: false,
    hideLabel: true,
    tableView: false,
    customConditional:
      "show = ['groupUser', 'allGroupUsers'].includes(_.get(row, 'recipientType', ''));",
    components: [
      {
        label: "Conditional",
        tooltip:
          "Check it, if you want the group value to change depending on the condition.",
        disableSortingAndFiltering: false,
        tableView: false,
        defaultValue: false,
        key: "conditional",
        type: "checkbox",
        input: true,
      },
      getGroupSelectComponent({
        label: groupLabel,
        customConditional: "show=!row.conditional",
      }),
      {
        label: "Group Options",
        addAnother: "Add Group",
        addAnotherPosition: "bottom",
        initEmpty: true,
        clearOnHide: true,
        tooltip: "Add groups and use their keys as value for group variable in Conditional Group field.",
        customConditional: "show=row.conditional",
        input: true,
        validate: {
          required: true,
        },
        key: "groupOptions",
        type: "datagrid",
        components: [
          {
            label: "Key",
            disableSortingAndFiltering: false,
            tableView: true,
            clearOnHide: true,
            calculateValue:
              "var options = data.groupOptions || [];\n\nfunction getOptionKey(index) {\n  var optionIndex = instance.rowIndex + index;\n  var optionKey = 'gr' + optionIndex\n  var isUnique = !_.some(options, option => option.key === optionKey);\n  return isUnique ? optionKey : getOptionKey(index+1);\n}\n\nvalue = value || getOptionKey(1);",
            allowCalculateOverride: true,
            key: "key",
            type: "textfield",
            input: true,
          },
          getGroupSelectComponent(),
        ],
      },
      {
        label: "Conditional Group",
        editor: "ace",
        autoExpand: false,
        placeholder: 'group = data.name === "Tom" ? "gr1" : "gr2"',
        disableSortingAndFiltering: false,
        tableView: true,
        key: "conditionalGroup",
        customConditional: "show=row.conditional",
        validate: {
          required: true,
        },
        description: "<p>The following variables are available in all scripts: <strong>form, submission, data, row, component, instance, moment, _, utils</strong>.\nThe group value must be equal to some Group Options key or empty string. Example: </p><pre>group = data.name === \"Tom\" ? \"gr1\" : \"gr2\";</pre>",
        type: "textarea",
        input: true,
      },
    ],
  };
}

function getRecipientTypeComponent() {
  return {
    label: "Recipient",
    optionsLabelPosition: "right",
    inline: true,
    hideLabel: true,
    tableView: false,
    values: [
      {
        label: "User",
        value: "user",
        shortcut: "",
      },
      {
        label: "Group User",
        value: "groupUser",
        shortcut: "",
      },
      {
        label: "All Group Users",
        value: "allGroupUsers",
        shortcut: "",
      },
    ],
    key: "recipientType",
    defaultValue: "user",
    type: "radio",
    input: true,
  };
}
