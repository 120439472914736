import React, { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Form} from '@formio/react';


const FilterableGrid = (props) => {
  const { formReady: formReadyProp = () => {}, gridName, options: propOptions = {}} = props;
  const gridRef = useRef();

  const updateFilters = useCallback((formioGridComponent) => {
    if (!formioGridComponent) return;

    const { filters, sort } =  formioGridComponent;
    const currentFilters = {};

    if (!_.isEmpty(filters)) {
      currentFilters.filters = filters;
    }

    if (!_.isEmpty(sort) && sort.key) {
      currentFilters.sort = sort;
    }

    if(!_.isEmpty(currentFilters)) {
      localStorage.setItem(gridName, JSON.stringify(currentFilters));
    }
    else {
      localStorage.removeItem(gridName)
    }
  }, [gridName])

  useEffect(() => {
    return () => {
      if (!gridName) return;

      updateFilters(gridRef.current?.formioGrid?.component);
    }
  }, [gridName, updateFilters]);

  const gridProps = {
    ...props,
    formReady(form) {
      const dataTable = _.find(form.components, comp => comp.component.type === 'datatable') || {};

      if (gridName && !_.isEmpty(dataTable)) {
        dataTable.on('page', () => {
          updateFilters(dataTable.formioGrid?.component);
        });
      }

      gridRef.current = dataTable;
      formReadyProp(form);
    },
    options: {
      hooks: {
        dataTableRetainedFilters() {
          if (!gridName) return {};

          let savedFilter = {};
   
          try {
            savedFilter = JSON.parse(localStorage.getItem(gridName));
          }
          catch(e) {
            savedFilter = {};
          }

          return savedFilter;
        }
      },
      ...propOptions
    }
  }
  
  return <Form  {...gridProps}/>;
};

export default FilterableGrid;
