import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useForm } from '../../form';
import RoutingSubmissionsList from '../../partials/RoutingSubmissionsList';

const DataRoutesList = () => {
  const formId  = useParams().formId;
  const { state: formState} = useForm();

  const getGridSubmissionsData = (submissionsResponse) => {
    const routedSubmissions = _.chain(submissionsResponse)
      .map(subm => {
        return {
          routeSubmission: subm,
          routeTrackId: subm._id,
          submission: _.get(subm, 'data.routedSubmission'),
          form: _.get(subm, 'data.routedForm'),
          data: _.chain(subm)
            .get('data.tracking', [])
            .last()
            .value()
        };
      })
      .value()

    return _.map(routedSubmissions, (subm) => {
      return {
        submission: subm.routeSubmission,
        routeTrackId: subm.routeTrackId,
        routedSubmissionId: _.get(subm, 'submission.id', ''),
        submissionName: _.get(subm, 'submission.name', ''),
        formTitle: _.get(formId ? formState : subm, 'form.title', ''),
        formId: formId || _.get(subm, 'form.id', ''),
        to: _.chain(subm).get('data.recipientsInfo', []).map(owner => _.get(owner, 'to[0].data.email', '')).value(),
        dateSent: _.get(subm, 'data.senderInfo[0].dateSent', ''),
        from:  _.chain(subm).get('data.senderInfo[0].from[0].data.email', []).value()
      }
    });
  }

  return <RoutingSubmissionsList
    submissionsRequestParams={{
      query: formId ? {'data.routedForm.id': formId} : {},
    }} 
    styleUnopenedRecords={false}
    transformGridData={getGridSubmissionsData}
    gridOptions={{
      adminView: true
    }} 
    getOpenRecordPath={(routedFormId,routedSubmissionId) => `/form/${routedFormId}/routes/${routedSubmissionId}`}
    title={`Routed Submissions - ${formState.form.title} Form`}
  />
}

export default DataRoutesList;
