import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import {Utils} from 'formiojs';

import { FormsConfig } from '../../../../config';
import { FormView, getForm, useForm, setFormOptions } from '../../../forms/form';
import { FormsProvider } from '../../../forms/form/formsContext';
import { SubmissionProvider, SubmissionsList, SubmissionsProvider, useSubmission, resetSubmission } from '../../../forms/submission';
import { getSubfolderGroupsQuery } from '../../FoldersUtils';
import { Loading } from '../../../../common';

const SubfoldersPage = () => {
  const {folderId} = useParams();
  const pathStart = `/folders/${folderId}/subfolders`;
  const formName = FormsConfig.folder;

  const {dispatch: dispatchFormEvent, state: formState} = useForm();
  const {form, isActive: isFormActive} = formState;
  const {state: submissionState, dispatch: dispatchSubmissionAction} = useSubmission();
  const {submission: folder } = submissionState;
 
  const transformFormComponents = useCallback((form) => {
    if (form.components) {
      const groupComp = Utils.getComponent(form.components, 'group');

      if (groupComp) {
        groupComp.tableView = false;
      }
    }
    return form;
  }, []);

  useEffect(() => {
    getForm(dispatchFormEvent, null, formName, transformFormComponents);
  }, [dispatchFormEvent, formName, transformFormComponents]);

  useEffect(() => {
    setFormOptions(dispatchFormEvent, {disableParent: true});
  }, [dispatchFormEvent]);

  const getParentFolderData = () => {
    const parentFolder = folder;
    const parentFolderGroups = _.get(parentFolder, 'data.userGroups', []);

    return {
      data: {
        parentFolder,
        subfolder: true,
        userGroups: parentFolderGroups
      }
    }
  }

  if (isFormActive || _.isEmpty(form)) {
    return <Loading/>
  }

  return (
    <div>
      <FormsProvider>
        <SubmissionsProvider>
          <Switch>
            <Route
              exact
              path={`${pathStart}`}
              render={(props) => {
              return  <SubmissionsList
                  {...props}
                  formName={formName}
                  beforeViewSubmission={() => {dispatchSubmissionAction(resetSubmission())}}
                  getViewPath={(formId, submission) => `/folders/${submission._id}`}
                  createSubmissionPath={`${pathStart}/create`}
                  submissionsRequestParams ={{query: {'data.parentFolder._id': folder._id}}}
                  addBtnTitle={'Subfolder'}
                  title={`${_.get(folder, 'data.folderName', '')} Folder Subfolders`}
                />
              }}
            />
            <Route
              exact
              path={`${pathStart}/create`}
              render={(props) => (
                <SubmissionProvider>
                  <FormView 
                    {...props} 
                    name={formName} 
                    options={{subfolderGroupsQuery: getSubfolderGroupsQuery(folder)}}
                    submission={getParentFolderData()} 
                    pathStart={pathStart}
                  />
                </SubmissionProvider>
              )}
            />
          </Switch>
        </SubmissionsProvider>
      </FormsProvider>
    </div>
  );
};



export default SubfoldersPage;
