import React, { useEffect, useCallback, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom'
import { Formio} from '@formio/react';
import { Loading, Title, AppErrors } from '../../../../common';
import { useForm, getGridForm} from '../../form';
import { FormsConfig } from '../../../../config';
import _ from 'lodash'
import {Utils} from 'formiojs';
import FilterableGrid from '../../../../common/FilterableGrid/FilterableGrid';

const SubmissionsList = (props) => {
  const {
    FormError,
    formName,
    getViewPath,
    createSubmissionPath,
    addBtnTitle,
    submissionsRequestParams = {},
    title,
    hideAddBtn,
    beforeViewSubmission = () => {},
    gridName = ''
  } = props;
  
  const history = useHistory();
  let formId  = useParams().formId;
  
  const defaultRequestParams = {
    limit: 100000,
    query: {},
    select: '',
    sort: '',
    ...submissionsRequestParams
  };
  
  const [requestParams, setRequestParams] = useState(defaultRequestParams);
  const [paramsSet, setParamsSet] = useState(false);
  const { state: formState, dispatch: dispatchFormEvent } = useForm();
  const {gridError, loadingGrid } = formState;

  useEffect(() => {
    getGridForm(dispatchFormEvent, '',FormsConfig.submissionsTable);
  }, [dispatchFormEvent]);

  useEffect(()=> {
    setRequestParams((requestParams) => {
      const updatedRequestParams = {...requestParams, ...submissionsRequestParams};
      setParamsSet(true);

      return (!_.isEqual(requestParams, updatedRequestParams)) 
        ? updatedRequestParams
        : requestParams;
    });
  }, [submissionsRequestParams]);


  const getFormattedForm = () => {
    const form = formState.gridForm;

    if (form.components){
      const gridComponents = [];

      Utils.eachComponent(formState.form.components, (comp, path) => {
        if (comp.tableView) {
          gridComponents.push({...comp, calculateValue: '', disabled: false, key: `data.${path}`});
        }
      }, true);

      form.components[0].components = gridComponents;
    }
    return form;
  };

  const getListDataUrl = useCallback(
    () => {
      const query = _.chain(requestParams.query).map((value, key) => `${key}=${encodeURIComponent(value)}`).join('&').value();
      const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
      return `${Formio.getProjectUrl()}${formPath}/submission?${query ? '&'+ query : ''}`;
    },
    [requestParams, formId, formName]
  );

  const isLoading = formState.isActive || !paramsSet || loadingGrid;

  if (isLoading) {
    return (<Loading />);
  }

  const Grid = () =>  (
    <FilterableGrid
      gridName={gridName}
      form={getFormattedForm()}
      options={{
        fetchUrl: getListDataUrl(),
        noSubmit: true, 
        ...formState.options
      }}
      formReady={(form)=> {
        form.on('rowClick', (row)=> {
          beforeViewSubmission();
          history.push(getViewPath ? getViewPath(formId, row) : `/form/${formId}/submission/${row._id}`);
        });
      }}
    />
  );

  const titleText = title || `Current Submissions - ${formState.form.title} Form `;

  const AddBtn = () => {
    return hideAddBtn
      ? null
      : (
        <div className="d-flex justify-content-end mb-2">
          <Link className='btn btn-primary' to={createSubmissionPath || `/form/${formId || formState.id}`}>
            <i className='glyphicon glyphicon-plus fa fa-plus' aria-hidden='true'></i>
            &nbsp; {addBtnTitle || 'New ' + formState.form?.title}
          </Link>
        </div>
      )
  }

  const MainContent = () =>  (
    <div className='form-index'>
      <AppErrors errors={[gridError]} />
      <Title text={titleText} />
      <AddBtn/>
      <Grid/>
    </div>
  );

  if (formState.error && FormError) {
    return(
      <FormError error={formState.error}>
        <MainContent />
      </FormError>
    );
  }

  return <MainContent />;
}

export default SubmissionsList;
