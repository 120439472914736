import React from 'react';

const FilterContext = React.createContext();

export const filterNames = {
  formsFilter: 'formsFilter',
  myFormsFilter: 'myFormsFilter',
  set: false
};

const initialState = {
  filter: {},
  error: ''
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'FILTER_SET':
        return {
          ...state,
          filter: {...action.filter},
          error: '',
          set: true,
      };
      case 'FILTER_FAILURE':
        return {
          ...state,
          error: action.error,
      };
    default:
      return state;
  }
};

export function FilterProvider(props) {
  const [state, dispatch] = React.useReducer(filterReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <FilterContext.Provider value={value} {...props} />;
}

export function useFilter() {
  const context = React.useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

const addFilter = (filter) => ({
  type: 'FILTER_SET',
  filter
});

const failFilter =  (error) => ({
  type: 'FILTER_FAILURE',
  error,
});

export const setFilter = (dispatch, filter, filterName) => {
  localStorage.setItem(filterName, JSON.stringify(filter));
  dispatch(addFilter(filter));
}

export const getFilter = (dispatch, filterName) => {
  try {
    const savedFilter = localStorage.getItem(filterName);
    dispatch(addFilter(savedFilter ? JSON.parse(savedFilter): {})); 
  }
  catch(e) {
    failFilter(e);
  }
}
