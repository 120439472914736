import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { FormsConfig } from '../../../../config';
import { FormView, getForm, useForm } from '../../../forms/form';
import { FormsProvider } from '../../../forms/form/formsContext';
import { SubmissionProvider, SubmissionsList, SubmissionsProvider } from '../../../forms/submission';
import GroupPage from './GroupPage';
import { Loading, AppErrors } from '../../../../common';

const GroupsPage = () => {
  const paramName = 'group';
  const pathStart = '/groups';
  const formName = FormsConfig.usersGroup;

  const { dispatch: dispatchFormEvent, state: formState } = useForm();
  const {form, isActive: isFormActive, error} = formState;


  useEffect(() => {
    getForm(dispatchFormEvent, null, formName);
  }, [dispatchFormEvent, formName]);

  if (isFormActive || _.isEmpty(form)) {
    return <Loading/>
  }

  if (error) {
    return <AppErrors errors={[error]}/>
  }

  return (
    <FormsProvider>
      <SubmissionsProvider>
        <Switch>
          <Route
            exact
            path={`${pathStart}`}
            render={(props) => {
              return  <SubmissionsList
                {...props}
                formName={formName}
                getViewPath={(formId, submission) => `${pathStart}/${submission._id}`}
                createSubmissionPath={`${pathStart}/create`}
                addBtnTitle={'Group'}
                title={'Groups'}
                gridName={'groupsTableFilter'}
              />
            }}
          />
          <Route
            exact
            path={`${pathStart}/create`}
            render={(props) => (
              <SubmissionProvider>
                <FormView {...props} name={formName} pathStart={pathStart}/>
              </SubmissionProvider>
            )}
          />
          <Route
            path={`${pathStart}/:${paramName}Id`}
            render={(props) => (
              <SubmissionProvider>
                <GroupPage {...props} pathStart={pathStart} paramName={paramName} formName={formName} />
              </SubmissionProvider>
            )}
          />
        </Switch>
      </SubmissionsProvider>
    </FormsProvider>
  );
};

export default GroupsPage;
