import React, {useState} from 'react';
import { Form} from '@formio/react';
import { FormsConfig } from '../../../config';
import _ from 'lodash';
import { Loading } from '../../../common';

const TrackSubmissionForm = (props) => {
  const {
    routeTrack = {}, showRoutesIds
  } = props;
  const [isReady, setFormReady] = useState(false);

  const routedSubmissionName = _.get(routeTrack, 'data.routedSubmission.name', '');
  const reportDate = _.get(routeTrack, 'data.tracking[0].senderInfo[0].dateSent', '');
  const formName = _.get(routeTrack, 'data.routedForm.name', '-');
  return (
  <div className="modal-body p-3" >
    <div className="row mb-4">
      <span className="col-5"><span className="font-weight-bold mr-2">Submission Name:  </span>{routedSubmissionName}</span>
      <span className="col-3"><span className="font-weight-bold mr-2">Report Date:</span> {reportDate ? new Date(reportDate).toLocaleDateString() : '-'}</span>
      <span className="col-4"><span className="font-weight-bold mr-2">Form/Process Name:</span>{formName}</span>
    </div>
    <div className="submission-tracking">
      <Form
        submission={ routeTrack}
        src={FormsConfig.getSrc('routeTrack')}
        options={{readOnly: true, hide: {submit:true}, showRoutesIds}}
        formReady={()=> {setFormReady(true)}}
      />
      {!isReady && <Loading/>}
    </div>
  </div>
  )
}

export default TrackSubmissionForm;
