import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { NavLink, Link } from 'react-router-dom';
import _ from 'lodash';
import { useAuth, logout, setBeforeLogoutActionExecution} from '../../modules/auth';
import Logo from '../../assets/Logo.png';
import { IncomingCount } from '../../modules/incomingCount';
import { hasAdminRights } from '../../utils';

const Header = () => {
  const history = useHistory();

  const { state: authState, dispatch } = useAuth();
  const { authenticated, is: role, userInfo, beforeLogoutAction, beforeLogoutActionExecution } = authState;

  const isAdmin = hasAdminRights(role);

  const onLogout = () => {
    if (beforeLogoutAction) {
      dispatch(setBeforeLogoutActionExecution(true));
    }
    else {
      logout(dispatch, () => {
        history.push('/auth');
      });
    }
  };

  useEffect(() => {
    if (beforeLogoutActionExecution && !beforeLogoutAction) {
      logout(dispatch, () => {
        history.push('/auth');
      });
    }
  }, [beforeLogoutAction, beforeLogoutActionExecution, dispatch, history]);
   
  const navbarLinks = _.sortBy([ 
    {icon: "fa fa-home fa-lg", path: '/', title: '', exact: true, adminOnly: false, authenticetedOnly: true, priority: 0 },
    {icon: "fa fa-arrow-circle-down", afterTitle: <IncomingCount/> , path: '/incoming', title: 'All Incoming', notAdmin: true, authenticetedOnly: true,  priority: 10 },
    {icon: "fa fa-arrow-circle-up", path: '/routed', title: 'All Routed', notAdmin: true, authenticetedOnly: true,  priority: 15 },
    {icon: "fa fa-wpforms", path: '/form', title: 'Forms', adminOnly: false,authenticetedOnly: true,  priority: 23 },
    {icon: "fa fa-check-square-o", path: '/myforms', title: 'My Forms', notAdmin: true, authenticetedOnly: true,  priority: 25 },
    {icon: "fa fa-address-card", path: '/employees', title: 'Employees', adminOnly: false, authenticetedOnly: true, priority: 30 },
    {icon: "fa fa-user", path: '/users', title: 'Users', adminOnly: true, authenticetedOnly: false,  priority: 35 },
    {icon: "fa fa-users", path: '/groups', title: 'Groups', adminOnly: true, authenticetedOnly: false,  priority: 40 },
    {icon: "fa fa-folder-open", path: '/folders', title: 'Folders', adminOnly: true, authenticetedOnly: false,  priority: 35 },
  ], (link) => link.priority);

  return (
     <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4 shadow">
        <div className="container">
          <Link className="navbar-brand mr-5" to="/">
            <img className="logo" 
              alt="Ohio Department of Rehabilitation and Correction" 
              src={Logo}
              style={{height:"40px"}} 
            />
          </Link>
          <ul className="nav navbar-nav mr-auto">
            {
              navbarLinks.map(link => {
                if ((link.adminOnly && !isAdmin) || (link.authenticetedOnly && !authenticated) || (link.notAdmin && isAdmin)) return null;

                return (
                  <NavLink exact={link.exact} to={link.path} key={link.title} role="navigation link" className="nav-link">
                    <i className={link.icon}></i>
                    &nbsp;
                    {link.title}&nbsp;
                    {link.afterTitle}
                  </NavLink>
                )
              })
            }
          </ul>
          <ul className="nav navbar-nav ml-auto">
            {authenticated && (
              <li className="nav-item">
              <NavLink to={`/employees/${_.get(userInfo, '_id', '')}`} role="navigation link" className=" nav-link">
                <span className="fa fa-user-circle  fa-lg" title={`User: ${_.get(userInfo, 'data.email', '')}`} />&nbsp;
              </NavLink>
              </li>
            )}
            { authenticated 
              ? (<li className="nav-item">
                  <span className="nav-link" role="navigation link"  onClick={onLogout}>
                    <span className="fa fa-sign-out fa-lg" title="Log Out"/>&nbsp;
                  </span>
                </li>) 
              : (<NavLink to="/auth" role="navigation link" className="nav-link">
                  Login
                </NavLink>)
            }
          </ul>
        </div>
      </nav>
  );
};

export default Header;
