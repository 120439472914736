import { Formio } from "@formio/react";

const BasicEditForm = Formio.AllComponents.button.editForm;

export default function some(...extend) {
  return BasicEditForm(
    [
      {
        key: "display",
        components: [
          {
            key: "action",
            ignore: true,
          },
          {
            key: "custom",
            ignore: true,
          },
          {
            label: "Target Page",
            widget: "choicesjs",
            tableView: true,
            dataSrc: "custom",
            data: {
              custom:
                "let pages = instance.options.editForm.components;\nlet editStageId= instance.options.stage;\nlet stagePages = _.filter(pages, page => page.stageId === editStageId);\nvalues = _.map(stagePages, (page, index) => ({label: page.title, value: page.key }))",
            },
            selectThreshold: 0.3,
            valueProperty: "value",
            template: "<span>{{ item.label  }}</span>",
            key: "targetPage",
            type: "select",
            input: true,
            validate: {
              required: true,
            },
            weight: 5,
          },
        ],
      },
    ],
    ...extend
  );
}
