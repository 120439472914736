import React, {useState} from 'react';
import { Form} from '@formio/react';
import { FormsConfig } from '../../../config';
import _ from 'lodash';
import { Loading } from '../../../common';

const SubmissionName = (props) => {
  const {
    readOnly,
    onNameChange,
    onFormReady: onNameFormReady,
    name
  } = props;
  const [isReady, setFormReady] = useState(false);

  return (
    <div className="alert alert-primary rounded my-3 pt-0 border border-primary">
      <Form
        submission={name ? {data: {submissionName: name}} : null}
        src={FormsConfig.getSrc('submissionName')}
        onChange={(data)=> {
          const name = _.get(data, 'data.submissionName', '');
          onNameChange(name);
        }}
        formReady={(form) => {
          setFormReady(true)
          onNameFormReady(form);
        }}
        options={{template: 'bootstrap', iconset: 'fa', noValidate: true , readOnly}}
      />
      {!isReady && <Loading/>}
    </div>
  );
}

SubmissionName.defaultProps ={
  onNameChange: () => {},
  onFormReady: () => {}
}

export default SubmissionName;
