import React from 'react';

import { useHistory, useParams } from 'react-router';
import { Confirm, Progressbar, AppErrors } from '../../../../common';
import { useForm, deleteForm } from '../formContext';

const FormDelete = ({ name }) => {
  const history = useHistory();
  const { formId } = useParams();
  
  const { state: formState, dispatch: dispatchFormAction } = useForm();
  const {error, deletingForm, form,  deleteFormStatus} = formState;
  const {progress, message} = deleteFormStatus;

  const confirmationMessage = `Are you sure you wish to delete the form '${form.title}'?`;

  const onYes = () => {
    deleteForm(dispatchFormAction, formId, name, (err) => {
      if (!err) {
        history.push('/form');
      }
    });
  };

  const onNo = () => {
    const prevState = history[history.length - 2];
    if (prevState) {
      history.push(prevState);
    }
  };

  return (
    <div>
      <AppErrors errors={[error]} />
      {deletingForm 
        ? (<Progressbar progress={progress} message={message}/>) 
        : (<Confirm message={confirmationMessage} onYes={onYes} onNo={onNo} />) 
      }
    </div>
  )
};

export default FormDelete;
