import { Formio } from '@formio/react';
import _ from 'lodash';
import editForm from './ToPageButton.form';


const FormioButton = Formio.AllComponents.button;

class ToPageButton extends FormioButton {
  static schema(...extend) {
    return FormioButton.schema({
      type: 'toPageButton',
      label: 'Navigate To Page',
      key: 'toPage',
      size: 'md',
      action: '',
      leftIcon: '',
      rightIcon: '',
      block: false,
      persistent: false,
      disableOnInvalid: false,
      theme: 'primary',
      dataGridLabel: true
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'To Page Button',
      group: 'custom',
      icon: 'arrows-h',
      weight: 40,
      schema: ToPageButton.schema()
    };
  }

  onClick(event) {
    if (this.disabled || this.options.attachMode === 'builder') {
      return;
    }
    this.dataValue = true;

    if (this.component.showValidations) {
      this.emit('checkValidity', this.data);
    }

    const wizard = this.root;
    const pageIndex = _.findIndex(wizard.pages, page => page.component.key === this.component.targetPage);

    wizard.setPage(pageIndex);
  }
  
}

ToPageButton.editForm = editForm;


export default ToPageButton;