import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useForm } from '../../form';
import { useAuth } from '../../../auth';
import { getReadOnlyUserRoleData, readOnlyRoles } from '../../FormsUtils';
import RoutingSubmissionsList from '../../partials/RoutingSubmissionsList';

const TrackedSubmissionsList = () => {
  const formId  = useParams().formId;
  
  const {state: authState} = useAuth();
  const {user} = authState;
  const {state: formState} = useForm();

  const getGridSubmissionsData = (routedSubmissions) => {
    return _.map(routedSubmissions, (subm) => {
      const trackingData = _.get(subm, 'data.tracking', []);
      const roles = readOnlyRoles;
      const {userRole, prevOwnerTrack, currentRoute} = getReadOnlyUserRoleData(trackingData, user._id)
         
      const inCopy = [roles.cc, roles.bcc].includes(userRole);

      return {
        submission: subm,
        routeTrackId: subm._id,
        routedSubmissionId: _.get(subm, 'data.routedSubmission.id', ''),
        submissionName: _.get(subm, 'data.routedSubmission.name', ''),
        formId: formId ||  _.get(subm, 'data.routedForm.id', ''),
        formTitle: formId ?  _.get(formState, 'form.title', '') : _.get(subm, 'data.routedForm.title', ''),
        from: _.get(currentRoute, 'senderInfo[0].from[0].data.email'),
        dateSent: _.get(subm, 'data.currentRoute.date', ''),
        dateRerouted: userRole === roles.prevOwner ? _.get(trackingData[prevOwnerTrack+1] || {}, 'senderInfo[0].dateSent', '') : '',
        inCopy, 
        dateOpened: _.chain(currentRoute).get('recipientsInfo', [])
          .filter(recipientInfo => {
            return inCopy
             ? recipientInfo.opened
             : recipientInfo.opened && _.get(recipientInfo, 'to[0]._id', '') === user._id
          })
          .map(recipientInfo => {
            const openedDate = recipientInfo.dateOpened;
            return {
              date: openedDate,
              formattedDate: new Date(openedDate),
            }
          })
          .minBy('formattedDate')
          .get('date', '')
          .value()
      }
    });
  }
  
  return <RoutingSubmissionsList
    submissionsRequestParams={{
      query: {
        ...(formId ? {'data.routedForm.id': formId} : {}),
        'data.currentRoute.readOnlyUsers._id': user._id,
      },
    }} 
    styleUnopenedRecords={false}
    transformGridData={getGridSubmissionsData}
    gridOptions={{viewTracked: true}} 
    getOpenRecordPath={(routedFormId,routedSubmissionId) => `/form/${routedFormId}/tracked/${routedSubmissionId}`}
    title={`Tracked Submissions - ${formState.form.title} Form `}
  />
}

export default TrackedSubmissionsList;
