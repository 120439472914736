import { Formio, Components } from "@formio/react";

const BasicEditForm = Formio.AllComponents.base.editForm;

export default function baseEditForm(...extend) {
  return BasicEditForm(
    [
      {
        key: "display",
        components: [
          {
            label: "Show In Route Track",
            tooltip: "The value of this component will be displayed in the stage route track.",
            key: "showInTrack",
            type: "checkbox",
            input: true,
            defaultValue: false,
            weight: 1900,
          },
        ],
      },
    ],
    ...extend
  );
}

Components.baseEditForm = baseEditForm;
