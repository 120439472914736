import React, { useEffect } from 'react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import _ from 'lodash';

import { useSubmission, getSubmission, SubmissionView, SubmissionDelete } from '../../../forms/submission';
import { Title } from '../../../../common';
import { useAuth } from '../../../auth';
import { hasAdminRights } from '../../../../utils';

const MyTagPage = (props) => {
  const {pathStart, formName, paramName } = props;

  const eventId = useParams()[`${paramName}Id`];
  const {state: authState} = useAuth();
  const {is: role} = authState;

  const isAdmin = hasAdminRights(role);

  const {dispatch: dispatchSubmissionEvent, state: submissionState} = useSubmission();
 

  useEffect(() => {
    getSubmission(dispatchSubmissionEvent, eventId, null, formName);
  }, [eventId, dispatchSubmissionEvent, formName]);

  const Edit = () => <SubmissionView pathStart={pathStart}  readOnly={false} formName={formName}/>;

  const navbarLinks = _.sortBy([ 
    {icon: "fa fa-chevron-left", getPath: (pathStart, eventId) => `${pathStart}`, title: '', priority: 0 },
    {icon: "fa fa-edit", getPath: (pathStart, eventId) => `${pathStart}/${eventId}`, title: 'Edit', priority: 20 },
    {icon: "fa fa-trash", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/delete`,  title: 'Delete', priority: 40 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-2">
      {navbarLinks.map(link => {
        if(link.adminOnly && !isAdmin && !link.showCondition) return null;
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.getPath(pathStart, eventId)}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

const deleteSubject = () => {
  const tag = _.get(submissionState, 'submission.data.tag', null);
  
  return tag ? `tag '${tag}'` : '';
} 

  return (
    <div>
      <Title text={`My Tag`}/>
      <Navbar />
      <Switch>
        <Route exact path={`${pathStart}/:${paramName}Id`} component={Edit}/>
        <Route
          path={`${pathStart}/:${paramName}Id/delete`}
          render={(props) => <SubmissionDelete {...props} pathStart={pathStart} formName={formName} paramName={paramName} deleteSubject={deleteSubject()}/>}
        />
      </Switch>
    </div>
  );
};

export default MyTagPage;
