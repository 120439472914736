import React, { useEffect , useState, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import {Form} from '@formio/react';
import _ from 'lodash';

import { FormsConfig} from '../../../../config' ;
import { useForm } from '../../form';
import { useSubmission, routeSubmission, resetRouteSettings } from '..';
import { setBeforeLogoutAction, useAuth } from '../../../auth';
import { useHistory } from 'react-router';
import { Loading } from '../../../../common';
import { everyoneGroup } from '../../../users/user/userContext';
import { useAlerts } from '../../../alerts';
import * as Utils from '../../../../utils';
import { getProcessSubmissionStage, hasAdminRights, setProcessSubmissionStage, unsetSoleOwnership } from '../../../../utils';

const SubmissionRoute = (props) => {
  const { state: submissionState, dispatch: dispatchSubmissionAction} = useSubmission();
  const { state: formState} = useForm();
  const {form, formFolder, isProcess} = formState;
  const {submission, routeTrack, routeSettings, routing} = submissionState;
  const { state: authState, dispatch: dispatchAuthAction} = useAuth();
  const { beforeLogoutAction , is: role } = authState;
  const isAdmin =  hasAdminRights(role);
  const {formId, submissionId} = useParams();
  const history = useHistory();
  const { addAlert } = useAlerts();

  const routedFormLink = `${window.location.origin}/form/${formId}/incoming/${submissionId}`
  const [routeOptions, setOptions] = useState({routedFormLink });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (routing) {
      setLoading(true);
    }
  }, [routing]);

  const getFormUserGroupsIds = useCallback(() => {
    const formUserGroups =  _.get(formFolder,'data.folder.data.userGroups', []);
      return _.some(formUserGroups, group => _.get(group, 'data.groupName', '') === everyoneGroup)
        ? ''
        : _.chain(formUserGroups).map(group => group._id).join(',').value();
  }, [formFolder]);

  const getRecipientsQuery = useCallback((recipientsSettings) => {
    const {group  = ''} = recipientsSettings;
    const queryParts = ['select=data.user'];

    queryParts.push(group ? `data.group._id=${group}` : `data.group.data.groupName=${everyoneGroup}`);
    return _.join(queryParts, '&');
  }, []);

  const getRecipientsQueries = useCallback(() => {
    const { routeRecipients = {to: {}, ccs:{}, bccs: {}}} = routeSettings;
    const queries = {};

    _.each(routeRecipients, (settingValue, settingKey) => {
      queries[settingKey] = getRecipientsQuery(settingValue)
    });

    return queries;
  }, [getRecipientsQuery, routeSettings]);

  useEffect(()=> {
    if (!isAdmin) {
      setOptions({
        readOnly: false,
        routedForm: form, 
        standAlone: !isProcess,
        userGroups: getFormUserGroupsIds(),
        routedSubmission: submission,
        user: authState.user, 
        routedFormLink,
        recipientsQueries: getRecipientsQueries(),
        routeSettings, 
        isProcess,
        currentStage: Utils.getCurrentStageSettings(submission, form).title,
        nextStage: Utils.getStageSettings(_.get(routeSettings, 'routeStage', ''), form).title
      });
    }
  }, [props, authState, isProcess, routedFormLink, form, submission, getRecipientsQueries, isAdmin, formFolder,routeSettings, getFormUserGroupsIds])


  const onRoute = (routeData)=> {
    unsetSoleOwnership(submission);
    
    let prevStageId = null;

    if (isProcess) {
      prevStageId = getProcessSubmissionStage(submission);
      const nextStage = _.get(routeSettings, 'routeStage', '');

      if (nextStage) {
        setProcessSubmissionStage(submission, nextStage);
      }
    }

    routeSubmission(dispatchSubmissionAction, submission, routeData, routeTrack, form, prevStageId, (err) => {
      if (!err) {
        if (beforeLogoutAction) {
          dispatchAuthAction(setBeforeLogoutAction(false));
        }
        addAlert({ type: 'success', content: 'Submission succesfully routed' });
        history.push(`/form/${formId}/routed`);
      }
    })
  }

  if (isAdmin) {
    return <div className="alert alert-warning " role="alert">
      The Routing functionality is only available to users.
    </div>
  }

  return (
    <div>
      {!routeOptions.user || loading
      ? <Loading/> 
      : (<Form
          src={FormsConfig.getSrc('route')}
          options={routeOptions}
          onSubmitDone={onRoute}
          formReady={(form) => {
            if (isProcess) {
              const { routeRecipients } = routeSettings;

              _.each(routeRecipients, (setting, key) => {
                if (setting.recipientType === 'allGroupUsers') {
                  form.emit(`${key}GroupUsers`)
                }
              });

              form.on('backToProcess', () => {
                resetRouteSettings(dispatchSubmissionAction);
              });
            }
          }}
        />)
      }
    </div>
  );
};



export default SubmissionRoute;


