import { Formio } from "@formio/react";
import _ from "lodash";
import editForm from "./ToFormButton.form";

const FormioButton = Formio.AllComponents.button;
class ToFormButton extends FormioButton {
  static schema(...extend) {
    return FormioButton.schema(
      {
        type: "toFormButton",
        label: "Navigate To Form",
        key: "toForm",
        size: "md",
        leftIcon: "",
        rightIcon: "",
        action: "",
        block: false,
        persistent: false,
        disableOnInvalid: false,
        theme: "primary",
        dataGridLabel: true,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: "To Form Button",
      group: "custom",
      icon: "step-forward",
      weight: 50,
      schema: ToFormButton.schema(),
    };
  }

  onClick(event) {
    if (this.disabled || this.options.attachMode === 'builder') {
      return;
    }
    this.dataValue = true;

    if (this.component.showValidations) {
      this.emit('checkValidity', this.data);
    }

    const wizard = this.root;
    const formPageIndex = _.findIndex(
      wizard.pages,
      (page) => page.component.key === this.component.targetFormPage
    );
    const targetFormPage = wizard.pages[formPageIndex];
    if (targetFormPage) {
      wizard.setPage(formPageIndex);
    }
    this.emit('navigateToForm', { backTarget: this.component.formBackPath, next: this.component.formNextButton, nextTarget: this.component.formNextPath })
   
  }
}

ToFormButton.editForm = editForm;

export default ToFormButton;
