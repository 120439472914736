import React, { useEffect } from 'react';
import ProcessEdit from './ProcessEdit';
import { useForm, saveForm, loadAccessControlComponent } from '../../form/formContext';
import { useAlerts } from '../../../alerts';
import _ from 'lodash';
import { Loading, Title, AppErrors } from '../../../../common';

const ProcessEditPage = (props) => {
  const { state: formState, dispatch } = useForm();
  const { addAlert } = useAlerts();
  const { loadingAccessControlComp, accessControlCompError, accessControlComp} = formState;

  useEffect(() => {
    loadAccessControlComponent(dispatch);
  }, [dispatch]);

  const onSaveForm = (form) => saveForm(dispatch, form, (err, form) => {
    if (!err) {
      addAlert({ type: 'success', content: 'Form succesfully updated' });
    }
  });

  if (loadingAccessControlComp || _.isEmpty(accessControlComp)) {
    return <Loading/>
  }

  return (
    <div>
     <Title text={`Edit ${formState.form?.title} Process`}/>
      <hr />
      <AppErrors errors={[formState.error, accessControlCompError]} />
      <ProcessEdit form={_.cloneDeep(formState.form)} saveText="Save Process" saveForm={onSaveForm} accessControlComp={accessControlComp} />
    </div>
  )
};

export default ProcessEditPage;