import React from 'react';
import { Formio } from '@formio/react';
import { FormsConfig } from '../../config';
import _ from 'lodash';

const IncomingCountContext = React.createContext();

const initialState = {
  incomingCount: 0
};

const IncomingCountReducer = (state, action) => {
  switch (action.type) {
    case 'COUNT_SUCCESS':
      return {
        ...state,
        error: '',
        incomingCount: action.incomingCount,
      };
    case 'COUNT_FAILURE':
      return {
        ...state,
        error: action.error,
        incomingCount: state.incomingCount,
      };
    default:
      return state;
  }
};

export function IncomingCountProvider(props) {
  const [state, dispatch] = React.useReducer(IncomingCountReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <IncomingCountContext.Provider value={value} {...props} />;
}

export function useIncomingCount() {
  const context = React.useContext(IncomingCountContext);
  if (!context) {
    throw new Error('useIncomingCount must be used within a IncomingCountProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

export const requestUnopenedIncomingRecordsSuccess = (unopenedRecords, userId) => {
  return ({
    type: 'COUNT_SUCCESS',
    incomingCount: _.reduce(unopenedRecords, (res, track)=> {
      const lastTrack = _.chain(track).get('data.tracking', []).last().value();

      if (lastTrack) {
        const openedByUser = _.chain(lastTrack).get('recipientsInfo', []).find(recipient =>  _.get(recipient, 'to[0]._id', '') === userId).get('opened').value();
        return openedByUser ? res : ++res
      }
      return res;
    }, 0) 
  });
}

export const requestUnopenedIncomingRecordsFail = (error) => {
  return ({
    type: 'COUNT_SUCCESS',
    error
  });
}


export const getIncomingUnopenedSubmissonsCount = (dispatch, userId, done = () => {}) => {
  const formio = new Formio(`${Formio.getProjectUrl()}/${FormsConfig.routeTrack}/submission`);

  return formio.loadSubmissions({params: { 
    'data.currentRoute.owners._id': userId, 
    'data.tracking.recipientsInfo.to._id': userId,
    'data.tracking.recipientsInfo.opened': false,
    select: 'data.tracking'
  }}, {ignoreCache: true})
    .then((result) => {
      dispatch(requestUnopenedIncomingRecordsSuccess(result, userId))
      done(null);
    })
    .catch((error) => {
      dispatch(requestUnopenedIncomingRecordsFail(error))
      done(error);
    });
};