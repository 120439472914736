import React from 'react';
import { Formio } from '@formio/react';
import { AppConfig, FormsConfig } from '../../../config';
import {Utils} from 'formiojs';
import * as AppUtils from '../../../utils';
import _ from 'lodash';
import { autoNameFieldKey, isProcess } from '../../../utils';

const FormContext = React.createContext();

const initialState = {
  id: '',
  isActive: false,
  lastUpdated: 0,
  clonedForm: {},
  form: {},
  url: '',
  error: '',
  gridForm: {},
  gridError: '',
  options: {},
  formFolder: {},
  formFolderError: '',
  hasFormAccess: false,
  hasFormWriteAccess: false,
  accessCheckError: '',
  checkingAccess: false,
  deletingForm: false,
  deleteFormStatus: {},
  autofilledFields: [],
  loadingFormFolder: false,
  isProcess: false,
  accessControlComp:{},
  loadingAccessControlComp: false,
  accessControlCompError: '',
  autoName: false,
  loadingGrid:false,
};

const deleteFormProgress={
  deletingFolder: {
    progress: 10,
    message: 'Deleting Form from Folder'
  },
  deletingForm:{
    progress: 50,
    message: 'Deleting Form'
  },
  finishing: {
    progress: 99,
    message: 'Finishing'
  }
}

const formReducer = function(state, action) {
  switch (action.type) {
    case 'FORM_CLEAR_ERROR':
      return {
        ...state,
        error: '',
      };
    case 'FORM_REQUEST':
      return {
        ...state,
        isActive: true,
        id: action.id,
        form: {},
        clonedForm: {},
        url: action.url,
        error: '',
        autofilledFields: []
      };
    case 'GRID_FORM_REQUEST':
      return {
        ...state,
        gridForm: {},
        gridError: '',
        loadingGrid: true,
      };
    case 'GRID_FORM_SUCCESS':
      return {
        ...state,
        gridForm: action.form,
        gridError: '',
        loadingGrid: false,
      };
    case 'GRID_FORM_FAILURE':
      return {
        ...state,
        gridError: action.error,
        loadingGrid: false,
      };
    case 'FOLDER_REQUEST':
      return {
        ...state,
        formFolder: {},
        loadingFormFolder: true,
        formFolderError: ''
      };
    case 'FOLDER_SUCCESS':
      return {
        ...state,
        formFolder: action.folder,
        loadingFormFolder: false,
        formFolderError: '',

      };
    case 'FOLDER_FAILURE':
      return {
        ...state,
        loadingFormFolder: false,
        formFolderError: action.error,
      };
    case 'ACCESS_WRITE_CHECK_SUCCESS':
        return {
          ...state,
          hasFormWriteAccess: action.authorized,
          accessCheckError: '',
          checkingAccess: false
        };
    case 'ACCESS_CHECK_SUCCESS':
      return {
        ...state,
        hasFormAccess: action.authorized,
        accessCheckError: '',
        checkingAccess: false
      };
    case 'ACCESS_CHECK_REQUEST':
      return {
        ...state,
        accessCheckError: '',
        checkingAccess: true
      };
    case 'ACCESS_CHECK_FAILURE':
      return {
        ...state,
        accessCheckError: action.error,
        checkingAccess: false
      };
    case 'FORM_SUCCESS':
      return {
        ...state,
        isActive: false,
        id: action.form._id,
        form: action.form,
        clonedForm: _.cloneDeep(action.form),
        url: action.url || state.url,
        error: '',
        isProcess:  isProcess(action.form),
        autofilledFields: action.autofilledFields || [],
        autoName: action.autoName
      };
    
    case 'FORM_FAILURE':
      return {
        ...state,
        isActive: false,
        isInvalid: true,
        deletingForm: false,
        error: action.error,
        deleteFormStatus: {},
        autofilledFields: []
      };
      case 'FORM_DELETE_START':
        return {
          ...state,
          deletingForm: true,
        };
    case 'FORM_SAVE':
      return {
        ...state,
        isActive: true,
      };
    case 'FORM_RESET':
      return initialState;
    case 'SET_FORM_OPTIONS':
      return {
        ...state,
        options: action.options,
      };
    case 'SET_DELETE_STATUS':
      return {
        ...state,
        deleteFormStatus: deleteFormProgress[`${action.status}`],
      };
    case 'ACCESS_COMP_REQUEST':
      return {
        ...state,
        accessControlComp: {},
        loadingAccessControlComp: true,
        accessControlCompError: ''
      };
    case 'ACCESS_COMP_SUCCESS':
      return {
        ...state,
        accessControlComp: action.accessControlComp,
        loadingAccessControlComp: false,
        accessControlCompError: ''
      };
    case 'ACCESS_COMP_FAILURE':
      return {
        ...state,
        loadingAccessControlComp: false,
        accessControlCompError: action.error
      }
    default:
      return state;
  }
}

export function FormProvider(props) {
  const [state, dispatch] = React.useReducer(formReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <FormContext.Provider value={value} {...props} />;
}

export function useForm() {
  const context = React.useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

export const clearFormError = () => ({
  type: 'FORM_CLEAR_ERROR',
});

const requestGridForm = (id, url) => ({
  type: 'GRID_FORM_REQUEST',
  id,
  url,
});

const receiveGridForm = (form, url) => ({
  type: 'GRID_FORM_SUCCESS',
  form,
  url,
});

const failGridForm = (err) => ({
  type: 'GRID_FORM_FAILURE',
  error: err,
});
const requestForm = (id, url) => ({
  type: 'FORM_REQUEST',
  id,
  url,
});

const receiveForm = (form, url, autofilledFields, autoName = false) => ({
  type: 'FORM_SUCCESS',
  form,
  url,
  autofilledFields,
  autoName
});

const failForm = (err) => ({
  type: 'FORM_FAILURE',
  error: err,
});

const accessControlCompRequest = () => ({
  type: 'ACCESS_COMP_REQUEST',
});

const accessControlCompSuccess = (accessControlComp) => ({
  type: 'ACCESS_COMP_SUCCESS',
  accessControlComp
});

const accessControlCompFail = (error) => ({
  type: 'ACCESS_COMP_FAILURE',
  error
});

export const resetForm = () => ({
  type: 'FORM_RESET',
});

const sendForm = (form) => ({
  type: 'FORM_SAVE',
  form,
});

const setOptions = (options) => ({
  type: 'SET_FORM_OPTIONS',
  options,
});

const requestFolder = () => ({
  type: 'FOLDER_REQUEST',
});

export const receiveFolder = (folder) => ({
  type: 'FOLDER_SUCCESS',
  folder
});

const failFolder= (err) => ({
  type: 'FOLDER_FAILURE',
  error: err,
});

const receiveAccess= (authorized) => ({
  type: 'ACCESS_CHECK_SUCCESS',
  authorized,
});

const receiveWriteAccess= (authorized) => ({
  type: 'ACCESS_WRITE_CHECK_SUCCESS',
  authorized,
});

const requestAccessCheck = () => ({
  type: 'ACCESS_CHECK_REQUEST',
});

const failAccessCheck = (err) => ({
  type: 'ACCESS_CHECK_FAILURE',
  error: err
});

const startFormDeleting = (err) => ({
  type: 'FORM_DELETE_START',
  error: err
});

const setDeleteFormStatus = (status) => ({
  type: 'SET_DELETE_STATUS',
  status
});

export const getForm = (dispatch, id, name,  transform,  done = () => {}) => {
  const formPath = `/${id ? `form/${id}` : `${name}`}`;
  const path = `${Formio.getProjectUrl()}${formPath}`;
  const formio = new Formio(path);

  dispatch(requestForm(id, path));

  return formio.loadForm()
    .then((result) => {
      let form = result;

      if (form && transform) {
        form = transform(form);
      }

      dispatch(receiveForm(form));
      done(null, form);
    })
    .catch((result) => {
      dispatch(failForm(result));
      done(result);
    });
};

export const getFormAndCheck = (dispatch, id, user, name,  done = () => {}) => {
  const formioAuthUserToken = localStorage.getItem('formioToken');
  const formPath = `/${id ? `form/${id}` : `${name}`}`;
  const path = `${Formio.getProjectUrl()}${formPath}`;
  const formio = new Formio(path);
  const existPath = `${AppConfig.projectUrl}/${FormsConfig.formWriteAccess}/exists?data.email=${user.data.email}&data.formId=${id}`
  fetch(existPath, {
    method: "GET",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'x-jwt-token': formioAuthUserToken
    }
  })
  .then((res) => {
    if (res.status === 404) {
      console.log("User DOES NOT has write privileges on this form")
      dispatch(receiveWriteAccess(false));
    }
    if (res.status === 200) {
      console.log("User has write privileges on this form")
      dispatch(receiveWriteAccess(true));
    }
  })
  .catch((err) => console.error(err))
  dispatch(requestForm(id, path));

  return formio.loadForm()
    .then((result) => {
      const form = result;
      if ((form.display === 'form' && !form.components.some(component => component.key === 'submissionAccessData')) ||
      (form.display === 'wizard' && !_.get(form, 'components[0].components', []).some(component => component.key === 'submissionAccessData'))) {
        throw Error('The form does not have Submission Access Data configuration. Please contact an administrator to fix this.');
      }
      
      const autofilledFields = [];
      let autoName = false;

      Utils.eachComponent(form.components, (component, path) => {
        if (component.key === autoNameFieldKey) {
          autoName = true;
        }
        if (component.autofilledValue) {
          autofilledFields.push({path: path, autofilledValue: component.autofilledValue});
        }
      }, true)

      dispatch(receiveForm(form, null, autofilledFields, autoName));
      done(null, form);
    })
    .catch((err) => {
      dispatch(failForm(err));
      done(err);
    });
};

export const setFormOptions = (dispatch, options) => {
  options = options || {};
  dispatch(setOptions(options));
};

export const getGridForm = (dispatch, id, name, done = () => {}) => {
  const formPath = `/${id ? `form/${id}` : `${name}`}`;
  const path = `${Formio.getProjectUrl()}${formPath}`;
  const formio = new Formio(path);

  dispatch(requestGridForm(id, path));

  return formio.loadForm()
    .then((result) => {
      dispatch(receiveGridForm(result));
      done(null, result);
    })
    .catch((result) => {
      dispatch(failGridForm(result));
      done(result);
    });
};

export const saveForm = (dispatch, form, done = () => {}) => {
  dispatch(sendForm(form));

  const id = form._id;
  const path = `${Formio.getProjectUrl()}/form${id ? `/${id}` : ''}`;
  const formio = new Formio(path);

  formio.saveForm(form)
    .then((result) => {
      const url = `${Formio.getProjectUrl()}/form/${result._id}`;
      dispatch(receiveForm(result, url));
      done(null, result);
    })
    .catch((result) => {
      dispatch(failForm(result));
      done(result);
    });
};

const deleteFormFolder = (dispatch, formId) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.folderForm}/submission`;
  const formio = new Formio(url);

  return formio.loadSubmissions({params: {'data.form._id': formId}})
    .then((result) => {
      if (!result.length) {
        return;
      }

      const formFolderSubmissionId = result[0]._id;
      const formio = new Formio(`${url}/${formFolderSubmissionId}`);

      return formio.deleteSubmission();
    })
};

export const deleteForm = (dispatch, id, name, done = () => {}) => {
  const formPath = `/${id ? `form/${id}` : `${name}`}`;
  const path = `${Formio.getProjectUrl()}${formPath}`;
  const formio = new Formio(path);

  dispatch(startFormDeleting());
  dispatch(setDeleteFormStatus('deletingFolder'));

  return deleteFormFolder(dispatch, id)
    .then(() => {
      dispatch(setDeleteFormStatus('deletingForm'));
      return formio.deleteForm();
    })
    .then(() => {
      dispatch(setDeleteFormStatus('finishing'));
      dispatch(resetForm());
      done();
    })
    .catch((error) => {
      dispatch(failForm(error));
      done(error);
    });
};

export const getFormFolder=(dispatch, formId, done =() => {}) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.folderForm}/submission`;
  const formio = new Formio(url);

  dispatch(requestFolder());

  formio.loadSubmissions({params: {'data.form._id': formId}})
    .then((result) => {
      dispatch(receiveFolder(result[0] || {}));
      done(null, result);
    })
    .catch((error) => {
      dispatch(failFolder(error));
      done(error);
    });
} 

export const checkAccess=(dispatch, formId, done =()=>{}) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.folderForm}/submission`;
  const formio = new Formio(url);

  dispatch(requestAccessCheck());

  formio.loadSubmissions({params: {'data.form._id': formId}})
    .then((result) => {
      dispatch(receiveAccess(!!result.length));
      done(null, result);
    })
    .catch((error) => {
      dispatch(failAccessCheck(error));
      done(error);
    });
} 

export const loadAccessControlComponent = (dispatch) => {
  dispatch(accessControlCompRequest());

  return AppUtils.getAccessControlComponent()
    .then(comp => {
      dispatch(accessControlCompSuccess(comp));
    })
    .catch((e) => {
      dispatch(accessControlCompFail(e));
    });
}