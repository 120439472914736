import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {FormsProvider} from '../../../forms/form/formsContext';
import MyForm from './MyForm';
import {MyFormProvider} from '../myFormContext';
import {FormProvider} from '../../../forms/form';
import {MyTagsPage} from '../../myTags';
import { FilterProvider } from '../../../filter/filterContext';
import MyFormsListPage from './MyFormsListPage';

const MyFormsPage = () => (
  <FormsProvider>
    <Switch>
      <Route exact 
        path="/myforms" 
        render={ (props) => <FilterProvider><MyFormsListPage {...props} /></FilterProvider>}
      />
      <Route
        exact
        path="/myforms/add"
        render={ (props) => <FormProvider><MyFormProvider><MyForm {...props}/></MyFormProvider></FormProvider> }
      />
      <Route
        path="/myforms/tags"
        render={ (props) => <FormProvider><MyTagsPage {...props}/></FormProvider> }
      />
    </Switch>
  </FormsProvider>
)

export default MyFormsPage;
