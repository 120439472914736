import React, { useEffect } from 'react';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import _ from 'lodash';

import { useSubmission, getSubmission, SubmissionView, SubmissionDelete } from '../../../forms/submission';
import { Loading, Title } from '../../../../common';
import { useAuth } from '../../../auth';
import { hasAdminRights } from '../../../../utils';

const EmployeePage = (props) => {
  const { pathStart, formName, paramName } = props;
  const eventId = useParams()[`${paramName}Id`];

  const { state: authState} = useAuth();
  const {is: role, userInfo} = authState;
  const isUserProfile = _.isEqual(eventId, userInfo._id);

  const isAdmin = hasAdminRights(role);

  const { dispatch: dispatchSubmissionEvent, state: submissionState } = useSubmission();
  
  const {isActive: isSubmissionActive} = submissionState;

  useEffect(() => {
    getSubmission(dispatchSubmissionEvent, eventId, null, formName);
  }, [eventId, dispatchSubmissionEvent, formName]);

  const View = () => <SubmissionView pathStart={pathStart}  options={{isUserProfile}} readOnly={true} formName={formName}/>; 
  const Edit = () => <SubmissionView pathStart={pathStart}  options={{isUserProfile}} readOnly={false} formName={formName}/>;

  const navbarLinks = _.sortBy([ 
    {icon: "fa fa-chevron-left", getPath: (pathStart, eventId) => `${pathStart}`, title: '', priority: 0 },
    {icon: "fa fa-eye", getPath: (pathStart, eventId) => `${pathStart}/${eventId}`, title: 'View', priority: 10 },
    {icon: "fa fa-edit", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/edit`, adminOnly: true, showCondition: isUserProfile,title: 'Edit', priority: 20 },
    {icon: "fa fa-trash", getPath: (pathStart, eventId) => `${pathStart}/${eventId}/delete`, adminOnly: true, title: 'Delete', priority: 40 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-2">
      {navbarLinks.map(link => {
        if (link.adminOnly && !isAdmin && !link.showCondition) return null;

        return (
          <li className="nav-item" key={link.title}>
            <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.getPath(pathStart, eventId)} >
              <i className={link.icon}></i> {link.title}
            </NavLink>
          </li>
        )
      })}
    </ul>
  );

  const deleteSubject = () => {
    const employee = _.get(submissionState, 'submission.data', null);

    if (employee) {
      const {email: employeeEmail, firstName, lastName} = employee;

      return `the profile for ${firstName} ${lastName} (${employeeEmail})`;
    }
    return;
  } 

  if (isSubmissionActive) {
    return <Loading/>;
  }

  return (
    <div>
      <Title text={`Employee Profile`}/>
      <Navbar />
      <Switch>
        <Route exact path={`${pathStart}/:${paramName}Id`} component={View}/>
        {isAdmin || isUserProfile
          ? (<Route path={`${pathStart}/:${paramName}Id/edit`} component={Edit}/>)
          : null
        }
        {isAdmin  
          ? (<Route
              path={`${pathStart}/:${paramName}Id/delete`}
              render={(props) => <SubmissionDelete {...props} pathStart={pathStart} formName={formName} paramName={paramName} deleteSubject={deleteSubject()}/>}
            />)
          : null
        }
      </Switch>
    </div>
  );
};

export default EmployeePage;
