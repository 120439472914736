import { Route, Switch } from 'react-router-dom';
import React from 'react';

import DataRoutesList from './DataRoutesList';
import { SubmissionsProvider } from '../../submission/submissionsContext';
import DataRoutePage from './DataRoutePage';
import { SubmissionProvider } from '../../submission/submissionContext';

const DataRoutesPage = () => (
  <SubmissionsProvider>
    <Switch>
      <Route
        exact
        path="/form/:formId/routes"
        component={DataRoutesList}
      />
      <Route
        path="/form/:formId/routes/:submissionId"
        render={(props) => <SubmissionProvider><DataRoutePage {...props} /></SubmissionProvider>}
      />
    </Switch>
  </SubmissionsProvider>
);

export default DataRoutesPage;
