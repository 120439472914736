import React from 'react';
import { useHistory } from 'react-router';
import { AppConfig, FormsConfig } from '../../config';

// Local storage data for tracking granted user from access
const formioAuthUser = JSON.parse(localStorage.getItem('formioUser'));
const formioAuthUserToken = localStorage.getItem('formioToken');

export default function AuthorizeUserModal(props) {
    const history = useHistory();
    const [open, setOpen] = React.useState('block');

    const overlayStyle = {
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: '1000',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    };

    const displayStyle = {
        display: `${open}`,
    }

    const addRecordToFormAuditLog = () => {
        const AUDIT_LOG_FORM_URL = `${AppConfig.projectUrl}/${FormsConfig.logUserAccess}/submission`
        fetch(AUDIT_LOG_FORM_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-jwt-token': formioAuthUserToken
          },
          body: JSON.stringify({
            data: { 
                email: formioAuthUser.data.email,
                formId: props.path[2]
            }
          })
        }).then(res => {
            if (res.ok) {
                console.log('[ForceUserAcknowledgeFormModal] ', {
                    status: res.status,
                    user: props.user,
                    path: props.path,
                })
            } else {
                console.log('[ForceUserAcknowledgeFormModal] ', {
                    status: res.status,
                    user: props.user,
                    path: props.path,
                    formUrl: AUDIT_LOG_FORM_URL,
                })
                throw new Error(`Status: ${res.status}`)
            }
        }).catch(err => {
            console.error(err);
        });
    }

    function handleAccept() {
        console.log("Granted, log this user to a resource")
        addRecordToFormAuditLog();
        setOpen('none');
    }

    function handleReject() {
        console.log("Rejected, push previous form")
        setOpen('none');
        history.push('/form');
    }

    return (
        <div 
            className="overlay" 
            style={{...overlayStyle, ...displayStyle }}
        >
            <div 
                className="modal fade show" 
                id="staticBackdrop" 
                data-backdrop="static" 
                data-keyboard="false" 
                tabIndex="-1" 
                aria-labelledby="staticBackdropLabel" 
                aria-hidden="true"
                style={displayStyle}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h3 className="modal-title w-100" id="staticBackdropLabel" style={{ fontSize: "50px", color: "red"}}>
                                <b>
                                     STOP <i className="fa fa-exclamation-triangle modal-title"></i>
                                </b>
                            </h3>
                        </div>
                        <div className="modal-body">
                            <h4 className='text-center'>
                                <b>
                                    Please read this before continuing
                                </b>
                            </h4>
                            <p>
                                Use the Legacy Forms Catalog located on the ODRC intranet page. Do not use the new automated forms.  You must use Internet Explorer with the Legacy Forms Catalog.
                            </p>
                            <p>
                                <b>If the Legacy Forms Catalog asks you for your credentials You are using the wrong internet browser. Please switch to Internet Explorer.</b>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button 
                                style={{ position: 'absolute', left: 12 }}
                                onClick={handleReject} 
                                type="button" 
                                className="btn btn-danger" 
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button 
                                onClick={handleAccept} 
                                type="button" 
                                className="btn btn-success"
                            >
                                Understood
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};