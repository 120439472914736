import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Formio } from '@formio/react';

import { useForms } from '../formsContext';
import { FormsConfig } from '../../../../config';
import { useAuth } from '../../../auth';
import { hasAdminRights } from '../../../../utils';
import { FilterableGrid } from '../../../../common';

const FormsList = (props) => {
  const {gridFormName, submissionsFormName, gridName } = props;

  const history = useHistory();
  const {state: formsState } = useForms();
  const {requestParams} = formsState;
  const {state: authState} = useAuth();
  const {is: role} = authState;
  const isAdmin = hasAdminRights(role);

  const getListDataUrl = useCallback(
    () => {
      const query = _.chain(isAdmin ? {...requestParams.query, 'tags__ne': 'default'} : requestParams.query).map((value, key) => `${key}=${value}`).join('&').value();
      return isAdmin ? `${Formio.getProjectUrl()}/form?type=form${query ? '&'+ query : ''}` :  `${Formio.getProjectUrl()}/${submissionsFormName || FormsConfig.folderForm}/submission${query ? '?'+ query : ''}`;
    },
    [isAdmin, requestParams, submissionsFormName]
  );

  const getGridName = useCallback(
    () => {
     return gridName || (isAdmin ? 'adminFormsTableFilter' : 'formsTableFilter')
    },
    [gridName, isAdmin]
  );

  const FormsGrid = () => {
    return (
      <FilterableGrid
        gridName={getGridName()}
        src={FormsConfig.getSrc(gridFormName || (isAdmin ? 'adminFormsTable' : 'formsTable'))}
        options={{
          noSubmit: true, 
          fetchUrl: getListDataUrl()
        }}
        formReady={(form)=> {
          form.on('rowClick', (row)=> {
            history.push(isAdmin ? `/form/${row._id}/submission` : `/form/${row.formId}/submission`);
          })
        }}
      />
    )
  }

  return (
    <div>
      <FormsGrid/> 
    </div>
  );
};

export default FormsList;
