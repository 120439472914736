import React, { useEffect, useCallback } from 'react'
import _ from 'lodash';

import { useForms, setFilterQueryParam} from '../../../forms/form/formsContext';
import { Title, AppErrors } from '../../../../common';
import { filterNames, useFilter } from '../../../filter/filterContext';
import Filter from '../../../filter/components/Filter';
import { Link } from 'react-router-dom';
import { FormsList } from '../../../forms/form';
import { FormsConfig } from '../../../../config';

const MyFormsListPage = () => {
  const {state: formsState, dispatch: dispatchFormsAction} = useForms();
  const {error: formsError, requestParamsSet} = formsState;
  const {state: filterState} = useFilter();
  const { filter, error: filterError, set: filterSet } = filterState;

  const getFilterQuery = useCallback(() => {
    if (_.isEmpty(filter)) return {};

    const {tags} = filter;
    const query = {}; 
    const tagsQuery = 'data.tags._id__in';

    if (tags && tags.length) {
      query[`${tagsQuery}`] = tags.map(tag => tag._id).join(',');
    };
    
    return query;
  }, [filter]);

  useEffect(()=> {
    if (filterSet) {
      setFilterQueryParam(dispatchFormsAction, getFilterQuery());
    }
  }, [dispatchFormsAction, filter, getFilterQuery, filterSet]);

  const ManageBtns = () => {
    return (
      <div className="d-flex justify-content-end mb-3">
        <Link className="btn btn-primary mr-3" to="/myforms/tags">Manage My Tags</Link>
        <Link className="btn btn-primary" to="/myforms/add"><i className="fa fa-plus"></i>&nbsp; Add My Form</Link>
      </div>
    );
  };

  return (
    <div>
      <Title text={'My Forms'}/>
      <p className="alert alert-primary" role="alert">
        This is a list of user forms. You can add the most frequently used forms from the general list of forms to my forms and apply tags to them. Tags make it easier for you to search and filter.
      </p>
      <AppErrors errors={[formsError, filterError]} />
      <Filter filterName={filterNames.myFormsFilter} filterFormName ={'myFormsFilter'} />
      <ManageBtns/>
      {filterSet && requestParamsSet && <FormsList gridFormName={'myFormsTable'} gridName={'myFormsTableFilter'} submissionsFormName={FormsConfig.myForms}/>}
    </div>
  );
};

export default MyFormsListPage;
