import React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router';

const CurrentSubmissionRedirect = ({submissionId, formId, submission}) => {
  const routed = _.get(submission, 'metadata.routed', '');

  return routed ?  null :  <Redirect to={`/form/${formId}/submission/${submissionId}`}/>;
}

export default CurrentSubmissionRedirect;
