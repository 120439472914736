import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import Login from './Login';
import { useAuth } from './../authContext';

const AuthPage = () => {
  const history = useHistory();

  const {state: authState} = useAuth();
  const {user, authenticated} = authState;

  useEffect(() => {
    if(user && authenticated) {
      history.push('/');
    }
  }, [user, authenticated, history])

  if (authenticated && user) {
    return null;
  }

  return (
    <div className="row mt-5">
      <div className="col-lg-6 col-md-6">
        <Login />
      </div>
      <div className="col-lg-6 col-md-6">
        <Login asAdmin/>
      </div>
    </div>
  );
};

export default AuthPage;
