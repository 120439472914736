import React from 'react';
import { Formio } from '@formio/react';
import _ from 'lodash';
import { FormsConfig } from '../../../config';
import NativePromise from 'native-promise-only';
import * as Utils from '../../../utils'

const SubmissionContext = React.createContext();

const initialState = {
  formId: '',
  id: '',
  isActive: false,
  lastUpdated: 0,
  submission: {},
  url: '',
  error: '',
  deletingSubmission: false,
  savingSubmission: false,
  route: false,
  routeSettings: {},
  routeTrack: {},
  routing: false,
  processInitPage: null,
};

const submissionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_INIT_PAGE':
      return {
        ...state,
        processInitPage: action.initPage
      };
    case 'SUBMISSION_CLEAR_ERROR':
      return {
        ...state,
        error: '',
      };
    case 'SUBMISSION_REQUEST':
      return {
        ...state,
        formId: action.formId,
        id: action.id,
        url: action.url,
        submission: {},
        isActive: true,
      };
    case 'SUBMISSION_SAVE':
      return {
        ...state,
        formId: action.formId,
        id: action.id,
        url: action.url || state.url,
        submission: {},
        isActive: false,
        savingSubmission: true
      };
    case 'SUBMISSION_SUCCESS':
      return {
        ...state,
        id: action.submission._id,
        submission: action.submission,
        isActive: false,
        error: '',
        savingSubmission: false,
      };
    case 'SUBMISSION_FAILURE':
      return {
        ...state,
        isActive: false,
        deletingSubmission:false,
        savingSubmission: false,
        error: action.error,
      };
    case 'START_DELETE_SUBMISSION':
      return {
        ...state,
        deletingSubmission: true,
      };
    case 'SUBMISSION_ROUTE':
      return {
        ...state,
        routing: true,
      };
    case 'SUBMISSION_AND_TRACK_REQUEST':
      return {
        ...state,
        formId: action.formId,
        id: action.id,
        url: action.url,
        submission: {},
        routeTrack: {},
        routing: false,
        isActive: true,
      };
    case 'SUBMISSION_AND_TRACK_SUCCESS':
      return {
        ...state,
        id: action.submission._id,
        submission: action.submission,
        isActive: false,
        routing: false,
        error: '',
        savingSubmission: false,
        routeTrack: action.routeTrack
      };
    case 'CANCEL_ROUTE_REQUEST':
      return {
        ...state,
        submission: {},
        routeTrack: {},
        isActive: true,
        error: ''
      };
    case 'SUBMISSION_AND_TRACK_FAILURE':
      return {
        ...state,
        isActive: false,
        deletingSubmission:false,
        savingSubmission: false,
        error: action.error,
        routing: false,
      };
    case 'SUBMISSION_TRACK_SAVE':
      return {
        ...state,
        isActive: true,
      };
    case 'SUBMISSION_TRACK_SUCCESS':
      return {
        ...state,
        routeTrack: action.submissionTrack,
        isActive: false,
        error: '',
      };
    case 'SUBMISSION_TRACK_FAILURE':
      return {
        ...state,
        error: action.error,
        isActive: false,
      };
    case 'ARCHIVE_INCOMING_START':
      return {
        ...state,
        isActive: true,
        error: '',
      };
    case 'ROUTE_SETTINGS_SET':
      return {
        ...state,
        route: true,
        routeSettings: action.routeSettings,
      };
      case 'ROUTE_SETTINGS_RESET':
        return {
          ...state,
          route: false,
          routeSettings: {},
        };
      // case 'ARCHIVE_INCOMING_SUCCESS':
      //   return {
      //     ...state,
      //     routeTrack: {},
      //     submission: {},
      //     isActive: false,
      //     error: '',
      //   };
      // case 'ARCHIVE_INCOMING_FAIL':
      //   return {
      //     ...state,
      //     isActive: false,
      //     error: '',
      //   };
    case 'SUBMISSION_RESET':
      return initialState;
    default:
      return state;
  }
};

export const setInitPage = (initPage) => ({
  type: 'SET_INIT_PAGE',
  initPage,
});

export function SubmissionProvider(props) {
  const [state, dispatch] = React.useReducer(submissionReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <SubmissionContext.Provider value={value} {...props} />;
}

export function useSubmission() {
  const context = React.useContext(SubmissionContext);
  if (!context) {
    throw new Error('useSubmission must be used within a SubmissionProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

export const clearSubmissionError = () => ({
  type: 'SUBMISSION_CLEAR_ERROR',
});

const requestSubmission = (id, formId,  url) => ({
  type: 'SUBMISSION_REQUEST',
  id,
  formId,
  url,
});

const sendSubmission = (data) => ({
  type: 'SUBMISSION_SAVE',
});

const receiveSubmission = (submission, url) => ({
  type: 'SUBMISSION_SUCCESS',
  submission,
  url,
});

const failSubmission = (error) => ({
  type: 'SUBMISSION_FAILURE',
  error,
});

const startDeletingSubmission = () => ({
  type: 'START_DELETE_SUBMISSION',
});

export const resetSubmission = () => ({
  type: 'SUBMISSION_RESET',
});

const startRouteSubmission = (data) => ({
  type: 'SUBMISSION_ROUTE',
});

const cancelRouteRequest = () => ({
  type: 'CANCEL_ROUTE_REQUEST',
});

const requestSubmissionAndRouteTrack = (id, formId,  url) => ({
  type: 'SUBMISSION_AND_TRACK_REQUEST',
  id,
  formId,
  url,
});

const receiveSubmissionAndRouteTrack = (submission, routeTrack, url) => ({
  type: 'SUBMISSION_AND_TRACK_SUCCESS',
  submission,
  routeTrack,
  url,
});

const failSubmissionAndRouteTrack = (error) => ({
  type: 'SUBMISSION_AND_TRACK_FAILURE',
  error,
});

const sendSubmissionTrack = (data) => ({
  type: 'SUBMISSION_TRACK_SAVE',
});

const receiveSubmissionTrack = (submissionTrack) => ({
  type: 'SUBMISSION_TRACK_SUCCESS',
  submissionTrack,
});

const failSubmissionTrack = (error) => ({
  type: 'SUBMISSION_TRACK_FAILURE',
  error,
});

const startArchiveIncomingSubmission = () =>( {
 type: 'ARCHIVE_INCOMING_START'
});

const addRouteSettings = (routeSettings) => ({
  type: 'ROUTE_SETTINGS_SET',
  routeSettings
});

const removeRouteSettings = () => ({
  type: 'ROUTE_SETTINGS_RESET',
});
// const archiveIncomingSubmissionSuccess = () =>( {
//   type:  'ARCHIVE_INCOMING_SUCCESS'
//  });

// const failArchiveIncomingSubmission = (error)  =>( {
//   type: 'ARCHIVE_INCOMING_FAIL',
//   error
//  });
export const resetRouteSettings = (dispatch, settings, done = () => {}) => {
  dispatch(removeRouteSettings(settings))
};

export const setRouteSettings = (dispatch, settings, done = () => {}) => {
  dispatch(addRouteSettings(settings))
};

export const getSubmission = (dispatch, id, formId, formName, done = () => {}) => {
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const url = `${Formio.getProjectUrl()}${formPath}/submission${id ? `/${id}` : ''}`;

  dispatch(requestSubmission(id, formId, url));

  Utils.getSubmission(id, formId, formName)
    .then((result) => {
      dispatch(receiveSubmission(result));
      done(null, result);
    })
    .catch((error) => {
      dispatch(failSubmission(error));
      done(error);
    });
};

export const saveSubmission = (dispatch, data, formId, formName, done = () => {}) => {
  dispatch(sendSubmission(data));

  return Utils.saveSubmission(data, formId, formName)
    .then((result) => {
      const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
      const url = `${Formio.getProjectUrl()}${formPath}/submission/${result._id}`;

      dispatch(receiveSubmission(result, url));
      done(null, result);
    })
    .catch((error) => {
      dispatch(failSubmission(error));
      done(error);
    });
};

export const deleteSubmission = (dispatch, id, formId, formName, done = () => {}) => {
  dispatch(startDeletingSubmission());

  return Utils.deleteSubmission(id, formId, formName)
    .then(() => {
      dispatch(resetSubmission());
      done(null, true);
    })
    .catch((error) => {
      dispatch(failSubmission(error));
      done(error);
    });
};

export const routeSubmission = (dispatch, submission, routeData, routeTrack, form, prevStageId, done=()=>{}) => {
  const trackInfo = Utils.getUpdatedRouteTrackData(submission, routeData, routeTrack, form, prevStageId);
  Utils.updateRoutedSubmissionAccess(submission, trackInfo, form);

  const routed = !_.isEmpty(routeTrack);
  const routedSubmissionTrack = routed ? {...routeTrack, data: {...routeTrack.data, ...trackInfo}} : { data: trackInfo, state: 'submitted'};

  dispatch(startRouteSubmission())

  NativePromise.all([
    Utils.saveSubmission(routedSubmissionTrack, null, FormsConfig.routeTrack),
    Utils.saveSubmission(submission, form._id)
  ])
    .then(([submissionRouteTrack, routedSubmission]) => {
      dispatch(receiveSubmissionAndRouteTrack(routedSubmission,submissionRouteTrack))
      done(null);
    })
    .catch((error) => {
      dispatch(failSubmissionAndRouteTrack(error))
      done(error);
    });
}

export const checkRouted = (dispatch, submission) => {
  if (_.get(submission, 'metadata.routed', false)) {
    dispatch(failSubmission('This submission has been routed and is not available in Current/Archived/Deleted Tabs.'));
  }
}

export const getSubmissionAndRouteTrack = (dispatch, id, formId, formName, done = () => {}) => {
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const submissionUrl = `${Formio.getProjectUrl()}${formPath}/submission${id ? `/${id}` : ''}`;

  dispatch(requestSubmissionAndRouteTrack(id, formId, submissionUrl));

  NativePromise.all([
    Utils.getSubmission(id, formId, formName),
    Utils.getSubmissions({query: {'data.routedSubmission.id': id}},  {}, null, FormsConfig.routeTrack)
  ])
    .then(([submission, routeTrackArr]) => {
      const routeTrack = routeTrackArr[0] || {};
      dispatch(receiveSubmissionAndRouteTrack(submission, routeTrack ));
      done(null, {submission, routeTrack});
    })
    .catch((error) => {
      dispatch(failSubmissionAndRouteTrack(error));
      done(error);
    });
};

export const getRouteTrack = (dispatch, id, done = () => {}) => {
  dispatch(sendSubmissionTrack());
    Utils.getSubmissions({query: {'data.routedSubmission.id': id}},  {}, null, FormsConfig.routeTrack)
    .then((routeTrackArr) => {
      const routeTrack = routeTrackArr[0] || {};
      dispatch(receiveSubmissionTrack(routeTrack));
      done(null,routeTrack);
    })
    .catch((error) => {
      dispatch(failSubmissionTrack(error));
      done(error);
    });
};

export const saveSubmissionTrack = (dispatch, submissionTrack, done = () => {}) => {
  Utils.saveSubmission(submissionTrack, null, FormsConfig.routeTrack)
    .then((result) => {
      dispatch(receiveSubmissionTrack(result));
      done(null, result);
    })
    .catch((error) => {
      dispatch(failSubmissionTrack(error));
      done(error);
    });
};

const addArchiveRecordToRouteTrack = (routeTrack, user) => {
  const routeTrackData = routeTrack.data || {}

  _.set(routeTrackData, 'routedSubmission.archived', true);
  const trackRecord = {
    archived: true,
    archiveAction: [{
      archiveActionUser: [user],
      archived: true,
      date: new Date().toISOString()
    }]
  }
  _.chain(routeTrackData).get('tracking').push(trackRecord).value()
}

export const archiveIncomingSubmission = (dispatch, submission, routeTrack, formId, user, done = () => {}) => {
  _.set(submission, 'metadata.archived', true);
  addArchiveRecordToRouteTrack(routeTrack, user);

  const submissionId = submission._id || '';
  const routeTrackId = routeTrack._id || '';

  dispatch(startArchiveIncomingSubmission());

  const routeTrackUrl = `${Formio.getProjectUrl()}/${FormsConfig.routeTrack}/submission${routeTrackId ? `/${routeTrackId}` : ''}`;
  const submissionUrl = `${Formio.getProjectUrl()}/form/${formId}/submission${submissionId ? `/${submissionId}` : ''}`;

  NativePromise.all([
    new Formio(routeTrackUrl).saveSubmission(routeTrack),
    new Formio(submissionUrl).saveSubmission(submission),
  ])
    .then(([track, archivedSubmission]) => {
      dispatch(receiveSubmissionAndRouteTrack(track, archiveIncomingSubmission));
      done(null);
    })
    .catch((error) => {
      dispatch(failSubmissionAndRouteTrack(error))
      done(error);
    });
}

export const cancelLastRoute = (dispatch, routeTrack, routedSubmission, form, done=()=>{}) => {
  const formId = form._id;
  const tracks = _.get(routeTrack, 'data.tracking', []);
  const lastTrack = _.last(tracks);
  const oneRoute = tracks.length === 1;
  const isFormProcess = Utils.isProcess(form);

  dispatch(cancelRouteRequest());

  let cancelRoutePromises = [];

  if (oneRoute) {
    Utils.unsetSubmissionRouted(routeSubmission);
    Utils.cleanSubmissionAccess(routedSubmission);
    Utils.setSubmissionOwner(routedSubmission, _.get(routeTrack, 'data.routeInitiators[0]'));

    if (isFormProcess) {
      Utils.setProcessFormsSubmissionOwner(form, routedSubmission, _.get(routeTrack, 'data.routeInitiators[0]'), true);
      Utils.setProcessSubmissionFirstStage(routedSubmission, form);
    }

    cancelRoutePromises = [
      Utils.saveSubmission(routedSubmission, formId),
      Utils.deleteSubmission(routeTrack._id, null, FormsConfig.routeTrack),
      Utils.deleteSubmission(lastTrack.routeId, null, FormsConfig.route),
    ];
  }
  else {
    Utils.cancelLastAndRestorePrevRoute(routeTrack);
    Utils.updateRoutedSubmissionAccess(routedSubmission, routeTrack.data, form);

    if (isFormProcess) {
      const updatedTracks = _.get(routeTrack, 'data.tracking', []);
      const currentLastTrack = _.last(updatedTracks);
      Utils.setProcessSubmissionStage(routedSubmission, currentLastTrack.stageId);
    }
    cancelRoutePromises = [
      Utils.saveSubmission(routedSubmission, formId),
      Utils.saveSubmission(routeTrack, null, FormsConfig.routeTrack),
      Utils.deleteSubmission(lastTrack.routeId, null, FormsConfig.route),
    ];
  }

  NativePromise.all(cancelRoutePromises)
    .then(([submission, routeTrack, route])=> {
      dispatch(receiveSubmissionAndRouteTrack(submission, oneRoute ? {} : routeTrack));
      done();
    })
    .catch((err) => {
      dispatch(failSubmissionAndRouteTrack(err));
      done(err);
    });
}
