import { NavLink, Route, Switch, useParams, Redirect } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { useSubmission, getSubmissionAndRouteTrack } from '../../submission/submissionContext';
import { useForm } from '../../form';
import { Loading, Title, AppErrors } from '../../../../common';
import { RoutedSubmissionAlert, CurrentSubmissionRedirect} from '../../partials';
import { useAuth } from '../../../auth';
import { getUserRole, readOnlyRoles, routedSubmissionRoles } from '../../FormsUtils';
import { SubmissionConvertButtons } from '../../convertButtons';
import { SubmissionView, SubmissionConversionProvider } from '../../submission';
import ProcessSubmissionView from '../../submission/components/ProcessSubmissionView';

const TrackedSubmissionPage = () => {
  const roles = readOnlyRoles;

  const { formId, submissionId } = useParams();
  const { dispatch, state: submissionState } = useSubmission();
  const { state: formState } = useForm();
  const {isProcess} = formState;
  const {isActive: isSubmissionActive, submission, routeTrack,  error: submissionError} = submissionState;
  const {state: userSate} = useAuth();
  const {user} = userSate;

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    getSubmissionAndRouteTrack(dispatch, submissionId, formId);
  }, [dispatch, submissionId, formId]);

  useEffect(() => {
    if(!_.isEmpty(user) && !_.isEmpty(submission)) {
      setUserRole(getUserRole(routeTrack, user._id));
    }
  }, [submissionId, formId, submission, user, routeTrack]);

  const View = () => isProcess
    ? <SubmissionConversionProvider><ProcessSubmissionView readOnly={true} /></SubmissionConversionProvider>
    : <SubmissionView readOnly={true} />;

  const navbarLinks = _.sortBy([
    {icon: "fa fa-chevron-left", path: `/form/${formId}/tracked`, title: '', priority: 0 },
    {icon: "fa fa-eye", path: `/form/${formId}/tracked/${submissionId}`, title: 'View', priority: 10 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs">
      {navbarLinks.map(link => {
        if(link.role && link.role !== userRole) return null;
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.path}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

  if (submissionError) {
    return <AppErrors errors={[submissionError]}/>
  }

  if (isSubmissionActive || _.isEmpty(submission) || !userRole) {
    return <Loading/>;
  }

  if (userRole && !_.some(readOnlyRoles, role => role === userRole)){
    if (userRole === routedSubmissionRoles.owner){
      return <Redirect to={`/form/${formId}/incoming/${submissionId}`} />
    }
    if (userRole === routedSubmissionRoles.routeInitiator){
      return <Redirect to={`/form/${formId}/routed/${submissionId}`} />
    }
    return <CurrentSubmissionRedirect submission={submission} formId={formId} submissionId={submissionId}/>
  }

  const getMessage = () => {
    if ( userRole === roles.prevOwner ) {
      return <span>This {isProcess ? 'process' : 'form submission'} has been rerouted to other users and is available in <strong>read-only</strong> mode.</span>
    }
    if (userRole === roles.cc) {
      return <span>You are in <strong>carbon copy</strong> to this {isProcess ? 'process' : 'form submission'}. The {isProcess ? 'process' : 'form submission'} is available in <strong>read-only</strong> mode.</span>
    }
    if (userRole === roles.bcc) {
      return <span>You are in <strong>blind carbon copy</strong> to this {isProcess ? 'process' : 'form submission'} routing. The {isProcess ? 'process' : 'form submission'} is available in <strong>read-only</strong> mode.</span>
    }
  }

  return (
    <div>
      <Title text={`${_.get(formState, 'form.title', '')} - Tracked Submission`}/>
      <RoutedSubmissionAlert message={getMessage()} routedSubmission={routeTrack}/>
      <SubmissionConversionProvider>{!isProcess && <SubmissionConvertButtons/>}</SubmissionConversionProvider>
      <Navbar />
      <Switch>
        <Route
          exact
          path="/form/:formId/tracked/:submissionId"
          component={View}
        />
      </Switch>
    </div>
  )
};

export default TrackedSubmissionPage;
