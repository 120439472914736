/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useReducer,  useState, useRef} from 'react';
import{ FormBuilder} from '@formio/react';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _camelCase from 'lodash/camelCase';
import { Formio, Form } from '@formio/react';
import { FormsConfig } from '../../../../config';
import _ from 'lodash';
import ProcessBuilder from '../../../../formioComponents/ProcessBuilder';

Formio.Builders.addBuilders({wizard:ProcessBuilder}); 

const reducer = (form, {type, value}) => {
  const formCopy = _cloneDeep(form);

  switch (type) {
    case 'formChange':
      for (let prop in value) {
        // eslint-disable-next-line no-prototype-builtins
        if (value.hasOwnProperty(prop)) {
          form[prop] = value[prop];
        }
      }
      return form;
    case 'replaceForm':
      return _cloneDeep(value);
    case 'title':
      if (!form._id) {
        formCopy.name = _camelCase(value);
        formCopy.path = _camelCase(value).toLowerCase();
      }
      break;
    default:
      break;
  }

  _set(formCopy, type, value);
  return formCopy;
};

const ProcessEdit = (props) => {
  const [form, dispatchFormAction] = useReducer(reducer, _cloneDeep(props.form));
  const [showSettings, toggleSettings] = useState(false);
  const schemaRef = useRef();
 
  useEffect(() => {
    const newForm = props.form;
    if (newForm && (form._id !== newForm._id || form.modified !== newForm.modified)) {
      dispatchFormAction({type: 'replaceForm', value: newForm});
    }
  }, [props.form, form]);

  const saveForm = () => {
    const {saveForm} = props;
    if (saveForm && typeof saveForm === 'function') {
      const updatedComponents = schemaRef.current?.components;
      
      saveForm({...form, components: updatedComponents || form.components});
    }
  };

  const handleChange = (path, event) => {
    const {target} = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    dispatchFormAction({type: path, value});
  };

  const formChange = (newForm, newSchema) => {
    schemaRef.current = newSchema;
    dispatchFormAction({type: 'formChange', value: newForm});
  };

  const {saveText, options, builder} = props;
  const builderOptions= {
    ...options,
    builder: {
      custom: {
        title: 'Navigation',
        weight: -5,
        default: true,
      },
      basic: {
        title: 'Basic',
        weight: -5,
        default: false,
      },
    },
    accessControlComp: props.accessControlComp
  }

  return (
    <div>
      <div className="row ">
        <div className="col-lg-2 col-md-4 col-sm-4">
          <div id="form-group-title" className="form-group">
            <label htmlFor="title" className="control-label field-required">Title</label>
            <input
              type="text"
              className="form-control" id="title"
              placeholder="Enter the form title"
              value={form.title || ''}
              onChange={event => handleChange('title', event)}
            />
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-4">
          <div id="form-group-name" className="form-group">
            <label htmlFor="name" className="control-label field-required">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter the form machine name"
              value={form.name || ''}
              onChange={event => handleChange('name', event)}
            />
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-4">
          <div id="form-group-path" className="form-group">
            <label htmlFor="path" className="control-label field-required">Path</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="path"
                placeholder="path"
                style={{'textTransform': 'lowercase', width:'120px'}}
                value={form.path || ''}
                onChange={event => handleChange('path', event)}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-4 row align-items-center">
          <a 
            className="nav-link mt-2 ml-2"
            title="Stages Settings"
            href="#"
            onClick={() => toggleSettings(!showSettings)}
          >
            <i className="fa fa-solid fa-gear fa-2x"></i>
          </a>
        </div>
        <div id="save-buttons" className="col-lg-3 col-md-5 col-sm-5 save-buttons pull-right">
          <span className="btn btn-primary pull-right px-5" onClick={() => saveForm()}>
            {saveText}
          </span>
        </div>
      </div>
      {showSettings && <Form
        submission={{data: {stages: _.chain(form).get('properties.stagesSettings', []).cloneDeep().value()}}}
        src={FormsConfig.getSrc('processSettings')}
        formReady={(form) => {
          form.on('saveStages', (data) => {
            dispatchFormAction({type: 'properties.stagesSettings', value: _.get(data, 'stages', [])})
            toggleSettings(false);
          })
        }}
      />}
      <FormBuilder
        key={form._id}
        form={form}
        options={builderOptions}
        builder={builder}
        onChange={formChange}
      />
    </div>
  );
};

ProcessEdit.defaultProps = {
  form: {
    title: '',
    name: '',
    path: '',
    display: 'wizard',
    type: 'form',
    properties: {process: true, stages: 2, stagesSettings: [{title: 'stage 1', id: '1'}, {title: 'stage 2', id: '2'}]}, 
    components: [],
    accessControlComp: {}
  }
};

export default ProcessEdit;