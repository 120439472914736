import React from 'react';
import { Form } from '@formio/react';
import { useHistory } from 'react-router';
import _ from 'lodash';

import { Progressbar, AppErrors } from '../../../../common';
import { useSubmission, saveSubmission } from '../../../forms/submission/submissionContext';
import {useFolder, updateFolderDependantResources} from '../folderContext';
import { useForm } from '../../../forms/form/formContext';


const FolderEdit = (props) => {
  const {formName, pathStart,  options: propsOptions } = props;
  const history = useHistory();

  const { state: formState } = useForm();

  const { state: submissionState, dispatch: dispatchSubmissionAction } = useSubmission();
  const { submission:folder, savingSubmission } = submissionState;
  const initialFolder = _.cloneDeep(folder.data);

  const {dispatch: dispatchFolderEvent, state: folderState} = useFolder();
  const {updatingDependencies, updateSubfoldersStatus, updateFolderFormsStatus, updateDependenciesError} = folderState;
  const {progress: subfodersUpdateProgress, message: subfodersUpdateMessage} = updateSubfoldersStatus;
  const {progress: folderFormsUpdateProgress, message: folderFormsUpdateMessage} = updateFolderFormsStatus;

  const getFolderChanges = (submission) => {
    const {data: updatedFolder} = submission;
    const result = {};

    result.updatedFolder = submission;
    result.newName = !_.isEqual(updatedFolder.folderName, initialFolder.folderName) ? updatedFolder.folderName : '';

    if (result.newName) {
      result.initialName = initialFolder.folderName;
    }
  
    const {userGroups: updatedGroups} = updatedFolder;
    const {userGroups: initialGroups} = initialFolder;

    result.deletedGroups = [];

    const areGroupsChanged = _.reduce(initialGroups, (changed, initialGroup) => {
      const groupInUpdatedGroups = _.find(updatedGroups, updatedGroup => _.isEqual(updatedGroup._id, initialGroup._id));

      if (groupInUpdatedGroups) {
        return changed;
      }
      else {
        result.deletedGroups.push(initialGroup._id);
        return true;
      }
    }, false);

    if (!_.isEqual(updatedGroups.length, initialGroups.length) || areGroupsChanged) {
      result.newGroups = updatedGroups;
    }
  
    return result;
  }

  const onSubmit = (submission) => {
    saveSubmission(dispatchSubmissionAction, submission, null, formName, (err, submission) => {
      if (!err) {
        updateFolderDependantResources(
          dispatchFolderEvent, 
          submission._id, 
          getFolderChanges(submission), 
          (err) => {
            if (!err) {
              history.push(`${pathStart}/${submission._id}`);   
            }
          }
        );
      }
    });
  }

  return (
    <div>
      <AppErrors errors={[formState.error, submissionState.error, updateDependenciesError]} />
      {updatingDependencies || savingSubmission
        ? (<>
            <Progressbar progress={savingSubmission ? 5 : subfodersUpdateProgress}  title={'Updating Subfolders'} message={ savingSubmission ? 'Saving Folder' : subfodersUpdateMessage}/>
            <Progressbar progress={folderFormsUpdateProgress} title={'Updating Folder Forms Access'} message={folderFormsUpdateMessage}/>
          </>) 
        : (<Form
            form={formState.form}
            submission={folder}
            url={submissionState.url}
            onSubmit={onSubmit}
            options={{ ...formState.options, ...propsOptions}}
          />)
      }
    </div>
  );
};

export default FolderEdit;
