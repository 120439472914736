import { Formio } from "@formio/react";

const getQuery = (query) => {
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      query[item.split("=")[0]] =
        item.split("=")[1] && decodeURIComponent(item.split("=")[1]);
    });

  return query;
};

// const stages = {
//   dev: 'https://formbuilding-izshuiourtmigzc.form.io',
//   test: 'https://testdeployment3-izshuiourtmigzc.form.io',
//   prod: 'https://izshuiourtmigzc.form.io',
// };

const stages = {
  dev: "https://forms-dev.drc.ohio.gov/drc-development",
  prod: "https://forms.drc.ohio.gov/drc",
};

const api = {
  portal: "https://api.form.io",
  dev: "https://forms-dev.drc.ohio.gov",
  prod: "https://forms.drc.ohio.gov",
};

const query = getQuery({});
const PROJECT_URL = query.projectUrl || stages.dev;
const API_URL = query.apiUrl || api.dev;

export const AppConfig = {
  projectUrl: PROJECT_URL,
  apiUrl: API_URL,
  version: "1.0.1-rc.17",
};

export const AuthConfig = {
  anonState: "/auth",
  authState: "/",
  login: {
    form: "user/login",
  },
  adminLogin: {
    form: "admin/login",
  },
  register: {
    form: "user/register",
  },
};

export const FormsConfig = {
  formsFilter: "formsfilter",
  myFormsTable: "myFormsTableApi",
  formsTable: "formsTableApi",
  adminFormsTable: "adminFormsTableApi",
  routedSubmissionsTable: "routedSubmissionsTableApi",
  submissionsTable: "submissionsTableApi",
  namedSubmissionsTable: "namedSubmissionsTableApi",
  user: "user",
  userProfile: "userprofile",
  folderForm: "folderform",
  userGroup: "usergroup",
  usersGroup: "usersGroup",
  folder: "folder",
  homePage: "homepage",
  myForms: "myforms",
  myTags: "mytags",
  myFormsFilter: "myformsfilter",
  submissionName: "submissionName",
  route: "route",
  routeTrack: "submissionRouteTrack",
  submissionAccessData: "submissionaccessdata",
  processSettings: "processsettings",
  processSupplement: "processsupplement",
  allSpecial: "allspecial",
  csvFilters: "csvfilters",
  logUserAccess: "loguseraccess",
  formWriteAccess: "formwriteaccess",
  getSrc: (formName) => PROJECT_URL + "/" + FormsConfig[`${formName}`],
};

Formio.setProjectUrl(AppConfig.projectUrl);
Formio.setBaseUrl(AppConfig.apiUrl);
