import { Formio } from "@formio/react";

const BasicEditForm = Formio.AllComponents.button.editForm;

export default function some(...extend) {
  return BasicEditForm(
    [
      {
        key: "display",
        components: [
          {
            key: "action",
            ignore: true,
          },
          {
            key: "custom",
            ignore: true,
          },
        ],
      },
    ],
    ...extend
  );
};

