

import React from 'react';
import { useHistory, useParams } from 'react-router';
import {useFolder, deleteSubfolders} from '../folderContext';
import { Progressbar} from '../../../../common';
import { Confirm, AppErrors } from '../../../../common';
import { useForm } from '../../../forms/form';
import { useSubmission, deleteSubmission } from '../../../forms/submission/submissionContext'


const FolderDelete = ({ formName, paramName, pathStart}) => {
  const history = useHistory();
  const {folderId} = useParams();

  const { state: formState } = useForm();

  const { state: submissionState, dispatch: dispatchSubmissionAction } = useSubmission();
  const { deletingSubmission: deletingFolder, submission: folder} = submissionState

  const {dispatch: dispatchFolderEvent, state: folderState} = useFolder();
  const {deletingSubfolders, deleteSubfoldersStatus, deleteSubfoldersError, deletePermissionError} = folderState;
  const {progress, message} = deleteSubfoldersStatus;

  const getDeleleMessage = () => {
    const folderData = folder?.data || {};
    const {folderName, folderPath} = folderData;
 
    return `Are you sure you wish to delete the folder '${folderName}' (path: ${folderPath}) with all its subfolders?`;
  }

  const onYes = () => {
    deleteSubfolders(dispatchFolderEvent, folder._id, (err) => {
      if(err) return;
      
      deleteSubmission(dispatchSubmissionAction, folderId, null, formName,  (err) => {
        if (!err && !deletePermissionError) {
          history.push(pathStart);
        }
      });
    })
  };

  const onNo = () => {
    history.push(pathStart);
  };

  const Progress = () => {
    return (<Progressbar progress={deletingFolder ? '98' : progress} message={deletingFolder ? 'Deleting folder' : message}/>)
  }

  const DeleteConfirmation = () => {
    return (<Confirm
      message={getDeleleMessage()}
      onYes={onYes}
      onNo={onNo}
    />)
  }

  return (
    <div>
      <AppErrors errors={[submissionState.error, formState.error, deleteSubfoldersError, deletePermissionError]} />
      {(deletingSubfolders || deletingFolder) 
        ? Progress()
        : !deletePermissionError 
          ? DeleteConfirmation() 
          : null
      }
    </div>
  )
};

export default FolderDelete;

