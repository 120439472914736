import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formio} from '@formio/react';
import _ from 'lodash';
import { Loading, Title, AppErrors } from '../../../common';
import { useForm } from '../form';
import { useSubmissions, saveSubmissions } from '../submission/submissionsContext';
import { FormsConfig } from '../../../config';
import { useAuth } from '../../auth';
import { ModalConfirmation, RouteTrackingModal } from '../partials';
import { addSelectedRowsClass, removeRouteTrackArchiveUser, setRouteTrackArchiveUser } from '../../../utils';
import FilterableGrid from '../../../common/FilterableGrid/FilterableGrid';


const RoutingSubmissionsList = (props) => {
  const {
    FormError,
    gridName = '',
    id,
    submissionsRequestParams = {},
    title,
    styleUnopenedRecords = false,
    gridOptions = {},
    transformGridData = null,
    getOpenRecordPath = (formId, submissionId) => { return ''}
  } = props;
  const selectedRowIdProperty = 'routeTrackId';

  const history = useHistory();
  const formId  = useParams().formId || id;
  const {state: authState} = useAuth();
  const {user} = authState;
  const defaultRequestParams = {
    query: {},
    select: '',
    sort: '',
    ...submissionsRequestParams
  };

  const defaultStatusAction = {
    active:false,
    selectedSubmissions: [],
    archive: false,
    restore: false,
  };

  const [requestParams, setRequestParams] = useState(defaultRequestParams);
  const [paramsSet, setParamsSet] = useState(false);
  const [trackingSubmission, setTrackingSubmission] = useState(null);
  const [statusAction, setStatusAction] = useState(defaultStatusAction);
  const [page, setPage] = useState(null);

  const { state: submissionsState, dispatch: dispatchSubmissionsAction } = useSubmissions();
  const { state: formState} = useForm();
  //const { addAlert } = useAlerts();

  useEffect(()=> {
    setRequestParams((requestParams) => {
      const updatedRequestParams = {...requestParams, ...submissionsRequestParams};
      setParamsSet(true);

      return !_.isEqual(requestParams, updatedRequestParams) 
        ? updatedRequestParams
        : requestParams;
    });
  }, [submissionsRequestParams, formId]);

  const getListDataUrl = useCallback(
    () => {
      const query = _.chain(requestParams.query).map((value, key) => `${key}=${value}`).join('&').value();
      return `${Formio.getProjectUrl()}/${FormsConfig.routeTrack}/submission?${query ? '&'+ query : ''}`;
    },
    [requestParams]
  );

  const isLoading = formState.isActive || !paramsSet || submissionsState.isActive;

  if (isLoading) {
    return <Loading />;
  }

  const addStyleToUnopenedRecords = (gridComp) => {
    if (!styleUnopenedRecords) return;

    gridComp.dataTableReady.promise.then(() => {
      const gridBody = _.get(gridComp, 'formioGrid.tbody', {});
      const rows = _.get(gridBody, 'refs.row', []);
      const rowsData = gridBody.data;

      _.each(rowsData,(data, index) => {
        if (!data.dateOpened && rows[index]) {
          rows[index].classList.add('font-weight-bold');
        }
      })
    })
  }

  const Grid = () => (
    <FilterableGrid
      gridName={gridName}
      submission={ {data: {selectedSubmissions: statusAction.selectedSubmissions}}}
        src={FormsConfig.getSrc('routedSubmissionsTable')}
        options={{
          fetchUrl: getListDataUrl(),
          noSubmit: true, 
          ...formState.options,
          ...gridOptions
      }}
      formReady={(form)=> {
        const gridComp = form.getComponent('submissions');

        if (transformGridData) {
          gridComp.dataTableReady.promise.then(() => {
            if (_.isNumber(page)) {
                gridComp.formioGrid.pagination().component.currentPage = page
            }

            _.set(gridComp, 'formioGrid.options.hooks.transformData', function (responseData) {
              return transformGridData(responseData);
            });
          })
        }

        form.on('change', ()=> {
          addStyleToUnopenedRecords(gridComp);
          addSelectedRowsClass(form, form.data.selectedSubmissions, selectedRowIdProperty);
        });

        form.on('track', (row)=> {
          setPage(gridComp.formioGrid.currentPage);
          setTrackingSubmission({...row.submission, page: gridComp.formioGrid.currentPage});
        });

        form.on('open', (rowData)=> {
          if (rowData) {
            const path = getOpenRecordPath(rowData.formId,rowData.routedSubmissionId )
            history.push(path);
          }
        });

        form.on('page', ()=> {
          addSelectedRowsClass(form, form.data.selectedSubmissions, selectedRowIdProperty);
          addStyleToUnopenedRecords(gridComp);
        });

        form.on('changeItemsPerPage', ()=> {
          addSelectedRowsClass(form, form.data.selectedSubmissions, selectedRowIdProperty);
          addStyleToUnopenedRecords(gridComp);
        });


        form.on('deselectAll', () => {
          addSelectedRowsClass(form, [], selectedRowIdProperty);
        });

        form.on('selectRow', (data)=> {
          const selectedSubmissions = form.data.selectedSubmissions;
          const routeTrackId = data.routeTrackId;

          form.data.selectedSubmissions = _.some(selectedSubmissions, selectedSubm => selectedSubm._id === routeTrackId)
            ? [..._.filter(selectedSubmissions, selectedSubm => selectedSubm._id !== routeTrackId)]
            : [...selectedSubmissions, data.submission];

          addSelectedRowsClass(form, form.data.selectedSubmissions, selectedRowIdProperty);
          form.triggerChange();
        });


        form.on('moveToArchived', () => {
          setPage(gridComp.formioGrid.currentPage);
          setStatusAction({
            ...defaultStatusAction,
            active: true,
            selectedSubmissions:  form.data.selectedSubmissions || [],
            archive: true,
          })
        });

        form.on('restoreArchived', () => {
          setPage(gridComp.formioGrid.currentPage);
          setStatusAction({
            ...defaultStatusAction,
            active: true,
            selectedSubmissions:  form.data.selectedSubmissions || [],
            restore: true
          })
        });
      }}
    />
  );

  const onNo = () => {
    setStatusAction({
      ...defaultStatusAction,
      selectedSubmissions: statusAction.selectedSubmissions,
    })
  };

  const onYes = () => {
    const {selectedSubmissions, restore, archive} = statusAction;
    const updatedSubmissions = _.chain(selectedSubmissions)
      .each(subm => {
        if (archive) {
          setRouteTrackArchiveUser(subm, user);
        }
        if (restore) {
          removeRouteTrackArchiveUser(subm, user);
        }
      })
      .value();

    saveSubmissions(dispatchSubmissionsAction, updatedSubmissions, null, FormsConfig.routeTrack, (err) => {
      if(!err) {
        //const {restore, archive} = statusAction;
        //addAlert({ type: 'success', content: `Records are successfully ${(restore && 'restored') || (archive && 'archived') || 'updated'} `}); 
        setStatusAction(defaultStatusAction);
        setPage(null);  
      }
    });
  } 

  const titleText = title || '';

  const getConfirmationMessage = () => {
    const {archive, restore} = statusAction;

    if (archive) {
      return <span>Are you sure you wish to <strong>archive all selected records</strong>?</span>;
    }

    if (restore) {
      return <span>Are you sure you wish to move <strong>all selected records</strong> to <strong>All Incoming</strong>?</span>;
    }
  };

  const MainContent = () =>  {
    return (
      <div className='form-index position-relative'>
        {titleText && <Title text={titleText} />}
        {statusAction.active && <ModalConfirmation
          onNo={onNo}
          onYes={onYes}
          message={getConfirmationMessage()}
          onModalCloseClick={onNo}
        />}
        <AppErrors errors={[formState.error, submissionsState.error]} />
        {trackingSubmission && <RouteTrackingModal routeTrack={trackingSubmission} onModalCloseClick={()=> setTrackingSubmission(null)} />}
        <Grid/>
      </div>
    )
  };

  if (formState.error && FormError) {
    return(
      <FormError error={formState.error}>
        <MainContent />
      </FormError>
    );
  }

  return <MainContent />;
}

export default RoutingSubmissionsList;