import React from 'react';

const Confirm = (props) => {
  const {
    onYes,
    onNo,
    message,
    yesText = 'Yes',
    noText = 'No',
    wrapperStyles={}
  } = props;

  return (
    <div className="mt-3" style={wrapperStyles}>
      <h4>{message}</h4>
      <div className="btn-group mt-3 mb-2" role="group">
        <button onClick={onYes} type="button" className="btn btn-danger">{yesText}</button>
        <button onClick={onNo} type="button" className="btn btn-outline-dark">{noText}</button>
      </div>
    </div>
  );
};

export default Confirm;
