import * as PremiumData from '@formio/premium';
import {Formio} from '@formio/react';

const DataTable = PremiumData.default.components.datatable || {};
class CustomDataTable extends DataTable {
  loadFormioGrid() {
    Formio.requireLibrary('formioGridCSS', 'FormioGrid', [
      { type: 'styles', src: `/static/css/formio.grid.min.css` }
    ], false);

    return Formio.requireLibrary('FormioCore', 'FormioCore', '/static/js/formio.core.min.js', true)
      .then(FormioCore => {
        this.FormioCore = FormioCore;

        return Formio.requireLibrary('FormioGrid', 'FormioGrid', `/static/js/formio.grid.min.js`, true)
        .then(FormioGrid => {
          if (!FormioGrid.initialRender) {
            FormioGrid.initialRender = FormioGrid.render;
          }
          
          const filters = this.hook('dataTableRetainedFilters') || {};

          FormioGrid.render = function() {
            arguments[1] = {...arguments[1], ...filters};
            return FormioGrid.initialRender(...arguments);
          }

          return FormioGrid;
        });
      });
  }
}


export default CustomDataTable;