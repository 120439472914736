import React, { useEffect, useCallback, useState } from 'react';
import { Route } from 'react-router-dom';
import { initAuth, Formio } from '@formio/react';
import _ from 'lodash';
import {Redirect, Switch, useHistory, useLocation} from 'react-router';
import './App.scss';
import {useAuth, AuthPage, getUserInfo, AutoLoginPage, logout} from './modules/auth';
import {FormProvider, FormsPage} from './modules/forms/form';
import {Footer, Header, Home, Loading, Modal, NotFoundPage} from './common';
import {GroupsPage}from './modules/groups/group';
import {UsersPage}from './modules/users/user';
import {EmployeesPage}from './modules/employees/employee';
import {FoldersPage} from './modules/folders/folder'
import {Alerts, AlertsProvider } from './modules/alerts';
import {MyFormsPage} from './modules/myForms/myForm';
import {AllIncomingSubmissionsPage} from './modules/forms/incomingSubmission';
import {AllRoutedSubmissionsPage} from './modules/forms/routedSubmission';
import { IncomingCountProvider } from './modules/incomingCount';
import { hasAdminRights } from './utils';
import EmployeeCreatePage from './modules/employees/employee/components/EmployeeCreatePage';
import { SubmissionProvider } from './modules/forms/submission';
import { UserProvider } from './modules/users/user/userContext';

function App() {
  const {dispatch, state: authState} = useAuth();
  const {isActive: isAuthActive, user, authenticated, userInfo,  init, is: role, isUserInfoFetched} = authState;
  const history = useHistory();
  const location = useLocation();
  const isAdmin = hasAdminRights(role);
  const {loggingOut} = authState;

  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    if (Formio){
      Formio.events.on('formio.sessionExpired', () => setTokenExpired(true));
    }
  }, []);

  const onLogOut = useCallback((err) => {
    setTokenExpired(false);
    if (!err) {
      history.push('/auth');
    }
  }, [history]);

  useEffect(() => {
    if (tokenExpired) {
        logout(dispatch, onLogOut);
    }
  }, [dispatch, onLogOut, tokenExpired ]);

  useEffect(() => {
    initAuth()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (user && user.data.id) {
      getUserInfo(dispatch, user.data.id);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (init && !isAuthActive && !user && location.pathname !== '/auth/done') {
      history.push('/auth');
    }

    if (user && authenticated && location.pathname === '/auth/done') {
      history.push('/');
    }
  }, [user, init, isAuthActive, history, location.pathname, authenticated]);

  const getAdminRoutes = () => (
    [
      <Route path="/users" key="/users"  render={(props) => <FormProvider><UsersPage {...props} /></FormProvider>} />,
      <Route path="/groups" key="/groups" render={(props) => <FormProvider><GroupsPage {...props} /></FormProvider>} />,
      <Route path="/folders" key="/folders" render={(props) => <FormProvider><FoldersPage {...props} /></FormProvider>} />,
    ]
  )

  const gettingUser = isAuthActive || !init;

  const getUnauthenticatedUserRoutes = () => {
    return <Switch>
      <Route  exact path="/auth/done" component={AutoLoginPage} />
      <Route exact path="/auth" component={AuthPage} />
    </Switch>
  };

  const getNewUserRoutes = () => {
    return <Switch>
      <Route path="/employees/create" exact render={(props) =>
        <FormProvider>
          <SubmissionProvider>
            <UserProvider>
              <EmployeeCreatePage {...props}/>
            </UserProvider>
          </SubmissionProvider>
        </FormProvider>} />
      <Route path="/">
        <Redirect to="/employees/create" />
      </Route>
    </Switch>
  };

  const getAuthenticatedUserRoutes = () => {
    return <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/employees/create">
        <Redirect to="/" />
      </Route>
      <Route exact path="/users/create">
        <Redirect to="/" />
      </Route>
      <Route path="/form" component={FormsPage} />
      <Route path="/incoming"  render={(props) => <FormProvider><AllIncomingSubmissionsPage {...props} /></FormProvider>}/>
      <Route path="/routed" render={(props) => <FormProvider><AllRoutedSubmissionsPage {...props} /></FormProvider>}/>
      {!isAdmin && <Route path="/myforms" component={MyFormsPage} />}
      <Route exact path="/auth" component={AuthPage} />
      <Route path="/employees" render={(props) => <FormProvider><EmployeesPage {...props} /></FormProvider>} />
      {isAdmin ? getAdminRoutes() : null}
      <Route component={NotFoundPage} />
    </Switch>
  };

  return (
    <IncomingCountProvider>
    <>
      {gettingUser || loggingOut
        ? (<Modal className="alert alert-info">
            <div className="d-flex flex-column align-items-center">
              <Loading style={{ marginBottom: ' 10px' }}/>
              {loggingOut ? 'Logging Out' : 'Logging In'}...
            </div>
          </Modal>)
        : null
      }
      <div className="App">
        <Header/>
        {!gettingUser && !loggingOut && (<AlertsProvider>
          <Alerts/>
          <div className="container mb-5" id="main">
            {!user
              ? getUnauthenticatedUserRoutes()
              : (user && isUserInfoFetched && _.isEmpty(userInfo)
                ? getNewUserRoutes()
                : ((isUserInfoFetched || isAdmin) && getAuthenticatedUserRoutes()))
            }-
          </div>
        </AlertsProvider>)}
        <Footer></Footer>
      </div>
    </>
    </IncomingCountProvider>
  );
}

export default App;
