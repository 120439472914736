import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { FormsConfig } from '../../../../config';
import { FormView, getForm, useForm } from '../../../forms/form';
import { FormsProvider } from '../../../forms/form/formsContext';
import { SubmissionProvider, SubmissionsList, SubmissionsProvider } from '../../../forms/submission';
import MyTagPage from './MyTagPage';
import { Loading } from '../../../../common';


const MyTagsPage = () => {
  const paramName = 'tag';
  const pathStart = '/myforms/tags';
  const formName = FormsConfig.myTags;

  const { dispatch: dispatchFormEvent, state: formState } = useForm();
  const {form, isActive: isFormActive} = formState;

  useEffect(() => {
    getForm(dispatchFormEvent, null, formName);
  }, [dispatchFormEvent, formName]);

  if (isFormActive || _.isEmpty(form)) {
    return <Loading/>
  }

  return (
    <div>
    <FormsProvider>
      <SubmissionsProvider>
        <Switch>
          <Route
            exact
            path={`${pathStart}`}
            render={(props) => {
              return  <div>
                  <SubmissionsList
                    {...props}
                    formName={formName}
                    getViewPath={(formId, submission) => `${pathStart}/${submission._id}`}
                    createSubmissionPath={`${pathStart}/create`}
                    addBtnTitle={'My Tag'}
                    title= {'My Tags'}
                    submissionsRequestParams={{ sort: '-modified'}}
                    gridName={'myTagsTableFilter'}
                  />
                </div>
            }}
          />
          <Route
            exact
            path={`${pathStart}/create`}
            render={(props) => (
              <SubmissionProvider>
                <FormView {...props} title={'New Tag'} name={formName} pathStart={pathStart}/>
              </SubmissionProvider>
            )}
          />
          <Route
            path={`${pathStart}/:${paramName}Id`}
            render={(props) => (
              <SubmissionProvider>
                <MyTagPage {...props} pathStart={pathStart} paramName={paramName} formName={formName} />
              </SubmissionProvider>
            )}
          />
        </Switch>
      </SubmissionsProvider>
    </FormsProvider>
    </div>
  );
};



export default MyTagsPage;
