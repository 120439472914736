import _ from 'lodash';
import {Utils} from 'formiojs';
import { cleanSubmissionAccess, setSubmissionOwner, unsetSubmissionRouted } from './Utils';

export const formsStageId = '-1';

export const getStageSettings = (stageId, form) => {
  const stages = _.get(form, 'properties.stagesSettings');

  return _.find(stages, stage => +stage.id === +stageId) || {};
};

export const getCurrentStageSettings = (submission, form) => {
  const currentStageId = _.get(submission, 'metadata.stageId', '');

  return getStageSettings(currentStageId, form);
};

export const setProcessSubmissionFirstStage = (submission, form) => {
  _.set(submission, 'metadata.stageId', _.get(form, 'properties.stagesSettings[0].id', ''));
}

export const setProcessSubmissionStage = (submission, stageId) => {
  _.set(submission, 'metadata.stageId', stageId)
}

export const getProcessSubmissionStage = (submission) => {
  return _.get(submission, 'metadata.stageId', '');
}

export const getProcessFormsPath = (form) => {
  const processFormsPath = [];
  Utils.eachComponent(_.filter(form.components, comp => comp.stageId === formsStageId), (comp, path) => {
    if (comp.type === 'form') {
      processFormsPath.push(path)
    }
  });
  return processFormsPath;
}

export const setProcessFormsSubmissionOwner = (form, submission, user, firstClean) => {
  _.each(getProcessFormsPath(form), (path) => {
    if (firstClean) {
      unsetSubmissionRouted(submission, path);
      cleanSubmissionAccess(submission, path);
    }

    setSubmissionOwner(submission, user, path);
    setFormSubmissionInProcessProperty(submission, path);
  });
}

export const setFormSubmissionInProcessProperty = (submission, path) => {
  _.set(submission, `${path ? `data.${path}.` : ''}metadata.inProcess`, true);
}

export const isProcess = (form) => {
  return _.get(form, 'properties.process', false);
}

export const setSoleOwnership = (submission, userId) => {
  const accessData = _.get(submission, 'data.submissionAccessData', {})
  const owners = _.filter(accessData.owners, owner => owner._id === userId);
  const blockedOwners = _.filter(accessData.owners, owner => owner._id !== userId);
  //do not change allOwnerIds!!!!
  _.set(accessData, 'owners', owners);
  _.set(accessData, 'blockedOwners', blockedOwners);
}

export const getSoleOwner = (submission) => {
  const accessData = _.get(submission, 'data.submissionAccessData', {})
  const blockedOwners = _.get(accessData, 'blockedOwners', []);

  return !!blockedOwners.length ?  _.get(accessData, 'owners[0]', {}) : null;
}

export const unsetSoleOwnership = (submission) => {
  const accessData = _.get(submission, 'data.submissionAccessData', {})
  const owners = _.get(accessData, 'owners', []);
  const blockedOwners = _.get(accessData, 'blockedOwners', []);

  if (!!blockedOwners.length) {
    _.set(accessData, 'owners', [...owners, ...blockedOwners]);
    _.set(accessData, 'blockedOwners', []);
  }
}