import React, {useCallback, useEffect} from 'react';
import { useAuth } from '../auth';
import { getIncomingUnopenedSubmissonsCount, useIncomingCount } from '.';
import _ from 'lodash';


export const IncomingCount = (props) => {
  const {state: userState} = useAuth();
  const {user} = userState;

  const {state: incomingCountState, dispatch: dispatchIncomingCountAction} = useIncomingCount();
  const {incomingCount: count} = incomingCountState;

  const updateIncomingCount = useCallback((userId)=> {
    getIncomingUnopenedSubmissonsCount(dispatchIncomingCountAction, userId);
   }, [dispatchIncomingCountAction]);

  useEffect(()=> {
    let incomingCountUpdateInterval = null;
    const userId = _.get(user, '_id', '');

    if (userId) {
      updateIncomingCount(userId)
      incomingCountUpdateInterval = setInterval(() => {
        updateIncomingCount(userId)
      }, 360000);
    } 
    else if(!user && incomingCountUpdateInterval) {
      clearInterval(incomingCountUpdateInterval);
    }

    return () => { 
      if (incomingCountUpdateInterval) {
        clearInterval(incomingCountUpdateInterval);
      }
    };
  }, [user, updateIncomingCount])

  return <span className="badge badge-pill badge-warning rounded">{count}</span>
};
