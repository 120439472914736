import { Formio } from '@formio/react';
import editForm from './NextButton.form';

const FormioButton = Formio.AllComponents.button;
class NextButton extends FormioButton {
  static schema(...extend) {
    return FormioButton.schema({
      type: 'nextButton',
      label: 'Next',
      key: 'next',
      size: 'md',
      action: '',
      leftIcon: '',
      rightIcon: '',
      block: false,
      persistent: false,
      disableOnInvalid: false,
      theme: 'primary',
      dataGridLabel: true
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Next Button',
      group: 'custom',
      icon: 'arrow-right',
      weight: 20,
      schema: NextButton.schema()
    };
  }

  onClick(event) {
    if (this.disabled || this.options.attachMode === 'builder') {
      return;
    }
    this.dataValue = true;

    if (this.component.showValidations) {
      this.emit('checkValidity', this.data);
    }

    const wizard = this.root;
    const pageStageId = wizard.pages[wizard.page]?.component?.stageId;
    const nextPageStageId= wizard.pages[wizard.page+1]?.component?.stageId;

    let allowNext = pageStageId === nextPageStageId;

    if (allowNext) {
      this.root.nextPage();
    }
  }

}

NextButton.editForm = editForm;


export default NextButton;