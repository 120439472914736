import React from 'react';

const Button = (props) => {
  const {
    btnClass,
    text,
    onClick
  } = props;

  return (<button type="button" onClick={onClick} className={`btn ${btnClass}`}>{text}</button>);
};

export default Button;
