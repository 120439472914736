import { Route, Switch } from 'react-router-dom';
import React from 'react';
import IncomingSubmissionsList from './IncomingSubmissionsList';
import IncomingSubmissionPage from './IncomingSubmissionPage';
import { SubmissionProvider } from '../../submission/submissionContext';
import { SubmissionsProvider } from '../../submission/submissionsContext';

const IncomingSubmissionsPage = () => (
  <SubmissionsProvider>
    <Switch>
      <Route
        exact
        path="/form/:formId/incoming"
        component={IncomingSubmissionsList}
      />
      <Route
        path="/form/:formId/incoming/:submissionId"
        render={(props) => <SubmissionProvider><IncomingSubmissionPage {...props} /></SubmissionProvider>}
      />
    </Switch>
  </SubmissionsProvider>
);

export default IncomingSubmissionsPage;
