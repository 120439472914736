import React, { useState } from 'react';
import { Form, setUser } from '@formio/react';
import { Loading } from '../../../common';
import { AppConfig, AuthConfig } from '../../../config';
import { useAuth } from '../authContext';

const Login = (props) => {
  const { dispatch } = useAuth();
  const [isReady, setIsReady] = useState(false);
  const {asAdmin} = props;

  const loginFormUrl = `${AppConfig.projectUrl}/${asAdmin ? AuthConfig.adminLogin.form : AuthConfig.login.form}?live=1`;

  const onSubmitDone = (submission) => {
    setUser(submission)(dispatch);
  };

  const onFormReady = (form) => {
    if (props.onFormReady) {
      props.onFormReady(form);
    }
    setIsReady(true);
  }

  return (
    <>
      <Form
        {...props}
        src={loginFormUrl}
        onSubmitDone={onSubmitDone}
        formReady={onFormReady}
      />
      { isReady ? null : <Loading /> }
    </>
  );
};

export default Login;
