import {  Formio } from '@formio/react';
import { _ } from 'formiojs/utils/utils';
import editForm from './RouteButton.form';
import {Utils} from 'formiojs';

const FormioButton = Formio.AllComponents.button;
class RouteButton extends FormioButton {
  static schema(...extend) {
    return FormioButton.schema({
      type: 'routeButton',
      label: 'Route',
      key: 'route',
      size: 'md',
      action: 'submit',
      leftIcon: '',
      rightIcon: '',
      block: false,
      persistent: false,
      disableOnInvalid: false,
      theme: 'primary',
      dataGridLabel: true
    }, ...extend);
  }

  static get builderInfo() {
    return {
      title: 'Route Button',
      group: 'custom',
      icon: 'arrow-circle-o-right',
      weight: 10,
      schema: RouteButton.schema()
    };
  }

  attachButton() {
    this.on('submit', () => {
      if (this.routeInitiated){
        let {routeStage, routeRecipients = {}} = this.component;
        routeRecipients = Utils.fastCloneDeep(routeRecipients);

        _.each(routeRecipients, (value, key) => {
          if (value.conditional) {
            const groupKey = this.evaluate(value.conditionalGroup, {}, 'group');

            routeRecipients[key] = {
              recipientType: value.recipientType,
              group: _.chain(value.groupOptions).find(option => option.key === groupKey).get('group', '').value()
            }
          }
        })
        
        this.emit('routeToStage', {routeStage, routeRecipients});
        this.routeInitiated = false;
      }
    }, true);
    super.attachButton();
  }
  
  onClick(event) {
    super.onClick(event);
    this.routeInitiated = true;
  }
  
}

RouteButton.editForm = editForm;

export default RouteButton;