import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import {Utils} from 'formiojs';

import { FormsConfig } from '../../../../config';
import { FormView, getForm, useForm, setFormOptions } from '../../../forms/form';
import { FormsProvider } from '../../../forms/form/formsContext';
import { SubmissionProvider, SubmissionsList, SubmissionsProvider, useSubmission } from '../../../forms/submission';
import UserGroupPage from './UserGroupPage';
import { Loading } from '../../../../common';


const UserGroupsPage = () => {
  const paramName = 'groups';
  const { userId } = useParams();
  const pathStart = `/users/${userId}/groups`;
  const formName = FormsConfig.userGroup;

  const { dispatch: dispatchFormEvent, state: formState} = useForm();
  const {form, isActive: isFormActive} = formState;

  const {state: submissionState} = useSubmission();
  const userEmail = _.get(submissionState, 'submission.data.email', '');

  const transformFormComponents = useCallback((form) => {
    if (form.components) {
      const groupComp = Utils.getComponent(form.components, 'user');

      if (groupComp) {
        groupComp.tableView = false;
      }
    }
    return form;
  }, []);

  useEffect(() => {
    getForm(dispatchFormEvent, null, formName, transformFormComponents);
  }, [dispatchFormEvent, formName, transformFormComponents]);

  useEffect(() => {
    setFormOptions(dispatchFormEvent, {disableMember: true});
  }, [dispatchFormEvent]);

  if(isFormActive || _.isEmpty(form)) {
    return <Loading/>
  }

  return (
    <div>
    <FormsProvider>
      <SubmissionsProvider>
        <Switch>
          <Route
            exact
            path={`${pathStart}`}
            render={(props) => {
              return  <SubmissionsList
                {...props}
                formName={formName}
                getViewPath={(formId, submission) => `${pathStart}/${submission._id}`}
                createSubmissionPath={`${pathStart}/create`}
                submissionsRequestParams ={{query: {'data.user.data.email': userEmail}}}
                addBtnTitle={'Group'}
                title={`${userEmail} Groups`}
              />
            }}
          />
          <Route
            exact
            path={`${pathStart}/create`}
            render={(props) => (
              <SubmissionProvider>
                <FormView {...props} name={formName} submission={{data: {user: submissionState.submission}}} pathStart={pathStart} title={'Add Group'}/>
              </SubmissionProvider>
            )}
          />
          <Route
            path={`${pathStart}/:${paramName}Id`}
            render={(props) => (
              <SubmissionProvider>
                <UserGroupPage {...props} pathStart={pathStart} paramName={paramName} formName={formName}/>
              </SubmissionProvider>
            )}
          />
        </Switch>
      </SubmissionsProvider>
    </FormsProvider>
    </div>
  );
};



export default UserGroupsPage;
