import { Formio } from "@formio/react";
import { autoNameFieldKey } from "../../utils";

const FormioTextField = Formio.AllComponents.textfield;
class AutoName extends FormioTextField {
  static schema(...extend) {
    return FormioTextField.schema(
      {
        label: "Auto Name ",
        hidden: true,
        tableView: true,
        persistent: false,
        clearOnHide: false,
        calculateValue: "var date = new Date();\nvalue = form.title +'_'+ date.toLocaleDateString() + '_'+  date.toLocaleTimeString()",
        key: autoNameFieldKey,
        type: "textfield",
        input: true,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: "Auto Name",
      group: "basic",
      icon: "magic",
      weight: 150,
      schema: AutoName.schema(),
    };
  }
}

export default AutoName;
