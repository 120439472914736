import { Formio, Templates } from "@formio/react";
import _ from "lodash";
import {Utils} from 'formiojs';
import { formsStageId, getSubmissionName } from "../utils";

const Wizard = Formio.Displays.getDisplay('wizard');

class Process extends Wizard {
  get formsStageId() {
    return formsStageId;
  }


  init() {
    this.on('navigateToForm', (navDetails) => {
      this.formPageNavigation = navDetails;
    });

    const parentInit = super.init();

    this.formPageNavigation = this.defaultFormPageNavigation();
    this.processForms = [];

    return parentInit;
  }

  get buttons() {
    if(this.isProcess) {
      return {};
    }
     return super.buttons;
  }

  get currentStage() {
    return _.get(this.submission, 'metadata.stageId', '')
  }

  get processFormInfoPath() {
    return 'dataValue.metadata.filledInProcess';
  }

  setSubmission(submission, flags = {}) {
    return super.setSubmission(submission, flags)
      .then(() => {
        const currentStage = _.get(submission, 'metadata.stageId', '');

        if (currentStage && !this.options.readOnly) {
          const page = this.options.initPage || _.findIndex(this.pages, page => +page.component.stageId === +currentStage)
          this.setPage(page);
        }

        if (this.options.readOnly) {
          this.setPage(0).then(() => this.redraw());
        }

        this.formPageNavigation = this.defaultFormPageNavigation();
        return submission;
      });
  }

  attachHeader() {
    if (this.isProcess) {
      const isAllowPrevious = this.isAllowPrevious();
      this.attachTooltips(this.refs[`${this.wizardKey}-tooltip`], this.currentPanel.tooltip);
      if (this.isBreadcrumbClickable() || isAllowPrevious) {
        this.refs[`${this.wizardKey}-link`]?.forEach((link, index) => {
          if (!isAllowPrevious || index <= this.enabledIndex) {
            this.addEventListener(link, 'click', (event) => {
              const index = +event.target.dataset.index;
              this.emit('wizardNavigationClicked', this.pages[index]);
              event.preventDefault();
              return this.setPage(index).then(() => {
                this.emitWizardPageSelected(index);
              });
            });
          }
        });
      }
    }
    else {
      super.attachHeader()
    }

  }

  getProcessFormPages() {
    const processFormPages = [];
    _.each(this.pages, (page, index) => {
      if (page.component.stageId === this.formsStageId ) {
        Utils.eachComponent(page.components, (comp, path) => {
          if (comp.component.type === 'form' && _.get(comp, this.processFormInfoPath , false)) {
            processFormPages.push({
              name: getSubmissionName(_.get(this.submission, `data.${path}`, {})),
              panel: page.component,
              index
            });
          }
        });
      }
    });
    return processFormPages;
  }

  setPage(num) {
    const currentPage = this.page;
    return super.setPage(num).then(() => {
      if (this.isProcess && currentPage !== num) {
        this.emit('wizardPageSet', this.pages[num], num);
      }
      const isFormPage = this.component.stageId === this.formsStageId;
      if (isFormPage) {
        const forms = [];
        const formPageComponents = _.get(_.find(this.pages, page => page.component.key === this.component.key), 'components', []);
        Utils.eachComponent(formPageComponents, (comp) => {
          if(comp.component.type === 'form') {
            forms.push(comp);
          }
        });

        _.each(forms, (form) => {
          _.set(form,  this.processFormInfoPath, true);
        });
      }
    });
  }

  defaultFormPageNavigation() {
    return {
      backTarget: _.chain(this.pages).find(page => {
        return page.component.stageId === this.currentStage
      }).get('component.key' ,'').value(),
      next: false
    }
  }

  get renderContext() {
    const isProcess = this.isProcess;
    const processForms = isProcess ? this.getProcessFormPages() : [];

    return {
      processForms,
      isProcess,
      ...super.renderContext,
    };
  }

  prepareHeaderSettings(ctx = {}, headerType) {
    if (this.isProcess) {

       return this.renderTemplate(headerType, ctx);
    }
    return super.prepareHeaderSettings(ctx, headerType)
  }

  executeFormPageNavigation(next) {
    const navDetails = this.formPageNavigation;

    if (navDetails) {
      const {backTarget, nextTarget} = navDetails;
      const target = next ? nextTarget : backTarget;

      const formPageIndex = _.findIndex(
        this.pages,
        (page) => page.component.key === target
      );
      if (formPageIndex >= 0) {
        this.setPage(formPageIndex);
      }

      this.formPageNavigation = this.defaultFormPageNavigation();
    }
  }

  formNavigationComponents() {
    return {
      label: "Columns",
      columns: [
        {
          components: [
            {
              label: "Back",
              action: "custom",
              showValidations: false,
              block: true,
              tableView: false,
              key: "back",
              type: "button",
              custom: ({form}) => {
                form.executeFormPageNavigation();
              },
              input: true,
            },
          ],
          width: 4,
          offset: 0,
          push: 0,
          pull: 0,
          size: "md",
          currentWidth: 4,
        },
        {
          components: [],
          width: 4,
          offset: 0,
          push: 0,
          pull: 0,
          size: "md",
          currentWidth: 4,
        },
        {
          components: [
            {
              label: "Next",
              action: "custom",
              showValidations: false,
              block: true,
              tableView: false,
              key: "next",
              type: "button",
              input: true,
              customConditional: ({instance}) => {
                const navDetails = instance.root.formPageNavigation;
                return _.get(navDetails, 'next', false)
              },
              custom: ({form}) => {
                form.executeFormPageNavigation(true);
              },
            },
          ],
          size: "md",
          width: 4,
          offset: 0,
          push: 0,
          pull: 0,
          currentWidth: 4,
        },
      ],
      key: "formNavigationColumns",
      type: "columns",
      input: false,
      tableView: false,
    };
  }

  get initiatePageKey() {
    return "defaultInitiateProcessPage"
  }

  initiateProcessPage() {
    return {
      title: "Initiate Process Page",
      key: this.initiatePageKey ,
      type: "panel",
      label: "Initiate New Process",
      components: [
        {
          label: "Initiate New Process",
          action: "saveState",
          showValidations: false,
          tableView: false,
          persistent: false,
          key: "defaultInitiateNewProcessBtn",
          type: "button",
          input: true,
          customConditional: ({instance}) => {
            return !instance.options.readOnly;
          },
          state: "draft",
        },
        {
          "html": "<p>{{instance.root.getProcessFormPages().length ? 'Click on the form title to see the form submission' : 'No Process Forms'}}</p>",
          "label": "Content",
          "refreshOnChange": true,
          "key": "readOnlyProcessComponent",
          "type": "content",
          customConditional: ({instance}) => {
            return instance.options.readOnly;
          },
          "input": false,
          "tableView": false
          },
      ],
    };
  }

  setForm(form, flags) {
    this.isProcess = _.get(form, 'properties.process', false);

    if (this.isProcess) {
      if(!this.options.readOnly){
      _.each(form.components || [], page => {
        if (page.stageId === this.formsStageId && !_.some(page.components, comp => comp.key === "formNavigationColumns") ) {
          page.components.push(this.formNavigationComponents());
        }
      });}
      const isInitiatePage = _.get(form.components, '[0].key', '') === this.initiatePageKey;

      if (!isInitiatePage){
        form.components.unshift(this.initiateProcessPage());
      }
     }
    return super.setForm(form, flags);
  }

}


const initialWizardHeaderTemplate = Templates.current.wizardHeader.form;
Templates.current.wizardHeader.form = (ctx) => {
  if(!ctx.isProcess) {
    return initialWizardHeaderTemplate(ctx);
  }

  return `
    <nav aria-label="Wizard navigation" class="" id="${ctx.wizardKey}-header" ref="${ctx.wizardKey}-header">
      <div class="d-flex justify-content-start px-2 flex-wrap" role="tablist">
        ${ctx.processForms.length ? '<div class="mr-2 py-1 font-weight-bold">Process Forms:</div>' : ''}
        ${ctx.processForms.map((formPanel, index) => {
        return `<div class="${ctx.currentPage === formPanel.index ? ' text-primary font-weight-bold' : 'text-muted '}" style="cursor: pointer;">
            <div tabindex="0" data-index="${formPanel.index}" role="tab" class="bg-light mx-1 px-1 py-1" ref="${ctx.wizardKey}-link">
              ${ctx.t(formPanel.name || formPanel.panel?.label, { _userInput: true })}
            </div>
          </div>`
        }).join('')}
      </div>
      <hr class="mt-1">
    </nav>
  `
}

export default Process;
