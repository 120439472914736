import React from 'react';

const Title = (props) => {
  return (<h2 className='pt-2 text-capitalize'>{props.text}</h2>);
};

Title.defaultProps = {
  text: '',
}

export default Title;
