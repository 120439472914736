import { Formio } from '@formio/react';
import _ from 'lodash';
import { autoNameFieldKey, isSubmissionRouted, setSubmissionName } from '../../utils';

const FormioFormComponent = Formio.AllComponents.form;
class NestedForm extends FormioFormComponent {
  getSubOptions(options = {}) {
    const superOptions = super.getSubOptions(options);
    const userProfileId = _.get(this.options, 'userProfileId', null);

    if (userProfileId) {
      superOptions.userProfileId = userProfileId;
    }

    const userId = _.get(this.options, 'userId', null);

    if (userId) {
      superOptions.userId = userId;
    }

    return superOptions;
  }

  attach(el) {
    return super.attach(el).then(()=> {
      if (this.options.isProcess ) {
        const subFormSubmission = this.subForm._submission
        const isRoutedForm = isSubmissionRouted(subFormSubmission);

        if (!isRoutedForm) {
          const autoNameComponent = this.subForm.getComponent(autoNameFieldKey);

          if (autoNameComponent) {
            autoNameComponent.on('componentChange', (data) => {
              if(_.get(data, 'component.key', '') === autoNameFieldKey) {
                setSubmissionName(subFormSubmission, data.value);
              }
            })
          }
          else {
            setSubmissionName(subFormSubmission, this.subForm.form.title);
          }
        }

        if (this.subForm &&  !this.options.readOnly) {
          this.subForm.calculateValue();
        }
      }
    });
  }
}

export default NestedForm;


