import { NavLink, Route, Switch, useParams, Redirect, useHistory, Link } from 'react-router-dom'
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import _ from 'lodash';

import { useSubmission, getSubmissionAndRouteTrack,saveSubmissionTrack, saveSubmission} from '../../submission/submissionContext';
import SubmissionRoute from '../../submission/components/SubmissionRoute';
import { useForm } from '../../form';
import { Loading, Title, AppErrors } from '../../../../common';
import { ArchivedSubmissionAlert, RoutedSubmissionAlert, CurrentSubmissionRedirect} from '../../partials';
import { setBeforeLogoutAction, useAuth } from '../../../auth';
import { getIncomingUnopenedSubmissonsCount, useIncomingCount } from '../../../incomingCount';
import { getUserRole, readOnlyRoles, routedSubmissionRoles } from '../../FormsUtils';
import { getCutLocationPath, isRoutedArchived, setSoleOwnership, unsetSoleOwnership } from '../../../../utils';
import ProcessSubmissionView from '../../submission/components/ProcessSubmissionView';
import { SubmissionConvertButtons } from '../../convertButtons';
import { SubmissionView, SubmissionConversionProvider } from '../../submission';

const IncomingSubmissionPage = () => {
  const history = useHistory();
  const location = history.location.pathname;
  const { formId, submissionId } = useParams();
  const { dispatch, state: submissionState } = useSubmission();
  const { state: formState } = useForm();
  const {isProcess, hasFormWriteAccess } = formState;
  const {isActive: isSubmissionActive, submission, routeTrack, error: submissionError} = submissionState;
  const {dispatch: dispatchAuthAction, state: userSate} = useAuth();
  const {user, beforeLogoutActionExecution} = userSate;
  const {dispatch: dispatchIncomingCountAction} = useIncomingCount();

  const [userRole, setUserRole] = useState(false);
  const [openedSet, setOpened] = useState(false); 
  const isOwner = useMemo(() => userRole === routedSubmissionRoles.owner, [userRole]);
  const isArchived = useMemo(() => isRoutedArchived(routeTrack, user), [user, routeTrack]);
  const currentTrack = useMemo(() => _.chain(routeTrack).get('data.tracking', []).last().value(), [routeTrack]);

  const groupRoutingData = useMemo(() => {
    const routeOwners = _.get(routeTrack, 'data.currentRoute.owners', []);
    const blockedOwners = _.get(submission, 'data.submissionAccessData.blockedOwners', []);
    const currentOwners = _.get(submission, 'data.submissionAccessData.owners', []);
    const currentOwner = !!blockedOwners.length ? currentOwners[0] : null;

    return {
      groupRouting: routeOwners.length > 1,
      groupSize: routeOwners.length,
      owners: _.map(routeOwners, owner => _.get(owner, 'data.email', '')),
      currentOwner,
      isUserCurrentOwner: !!blockedOwners.length ? user._id === currentOwner._id : false
    }
  }, [routeTrack, submission, user]);

  const onlyRead = useMemo(() => {
    return isOwner && groupRoutingData.groupRouting && !groupRoutingData.isUserCurrentOwner
  }, [isOwner, groupRoutingData]);

  const saveUpdatedSubmission = useCallback((done = ()=>{})=> {
    return saveSubmission(dispatch, submission, formId, null, done);
  }, [dispatch, formId, submission]);

  const dispatchBeforeLogoutAction = useCallback((action) => {
    dispatchAuthAction(setBeforeLogoutAction(action));
  }, [dispatchAuthAction])

  const executeSoleOwnershipRemoval = useCallback(() => {
    unsetSoleOwnership(submission);
    saveUpdatedSubmission((err, submission) => {
      if (!err) {
        dispatchBeforeLogoutAction(false);
      }
    });
  }, [submission, saveUpdatedSubmission, dispatchBeforeLogoutAction]);

  useEffect(() => {
    getSubmissionAndRouteTrack(dispatch, submissionId, formId, null, (err, data) => {
      if(!err) {
        const {submission} = data;
        const blockedOwners = _.get(submission, 'data.submissionAccessData.blockedOwners', []);

        if(!!blockedOwners.length && _.get(submission, 'data.submissionAccessData.owners[0]._id', []) === user._id) {
          dispatchBeforeLogoutAction(true);
        }
      }
    });
  }, [dispatch, submissionId,formId, dispatchBeforeLogoutAction, user]);

  useEffect(() => {
    if (groupRoutingData.isUserCurrentOwner && beforeLogoutActionExecution) {
      executeSoleOwnershipRemoval();
    }

    return () => {
      if (groupRoutingData.isUserCurrentOwner && (getCutLocationPath(location, 5) !== getCutLocationPath(history.location.pathname, 5))) {
        executeSoleOwnershipRemoval();
      }
    }
  }, [executeSoleOwnershipRemoval, beforeLogoutActionExecution,groupRoutingData, location, history]);

  useEffect(() => {
    if (isOwner && !_.isEmpty(user) && !_.isEmpty(submission)) {
      const recipientInfo = _.chain(currentTrack).get('recipientsInfo', []).find(recipient => _.get(recipient, 'to[0]._id', '') === user._id).value();

      if (recipientInfo && !_.get(recipientInfo, 'opened', false) && !openedSet) {
        _.set(recipientInfo, 'opened', true);
        _.set(recipientInfo, 'dateOpened', new Date().toISOString());

        saveSubmissionTrack(dispatch, routeTrack, (err)=> {
          if(!err) {
            getIncomingUnopenedSubmissonsCount(dispatchIncomingCountAction, user._id);
            setOpened(true)
          }
        });
      } else {
        setOpened(true);
      }
    }
  }, [dispatch, submissionId, formId, routeTrack, submission, currentTrack, isOwner, user, dispatchIncomingCountAction, openedSet]);

  useEffect(() => {
    if(!_.isEmpty(user) && !_.isEmpty(submission)) {
      setUserRole(getUserRole(routeTrack, user._id));
    }
  }, [submissionId, formId, submission, user, routeTrack]);

  const View = useCallback(() => isProcess
    ? <SubmissionConversionProvider><ProcessSubmissionView readOnly={onlyRead}/></SubmissionConversionProvider>
    : <SubmissionView noRedirectOnSubmit={true} readOnly={true} />, [isProcess, onlyRead]);
  const Edit = useCallback(() => isProcess
    ? <SubmissionConversionProvider><ProcessSubmissionView /></SubmissionConversionProvider>
    : <SubmissionView noRedirectOnSubmit={true} disableSubmissionName={true} readOnly={false} />, [isProcess]);

  const navbarLinks = _.sortBy([
    {icon: "fa fa-chevron-left", path: isArchived ? `/form/${formId}/archived/incoming`: `/form/${formId}/incoming`, title: '',  priority: 0 },
    {icon: "fa fa-eye", path: `/form/${formId}/incoming/${submissionId}`, condition:  !isProcess, title: 'View',  priority: 10 },
    {icon: "fa fa-edit", path: `/form/${formId}/incoming/${submissionId}/edit`, title: 'Edit', condition: !isArchived && !isProcess && !onlyRead && hasFormWriteAccess, priority: 20 },
    {icon: "fa fa-level-up", path: `/form/${formId}/incoming/${submissionId}/route`, title: 'Route', condition: !isArchived && !isProcess && !onlyRead && hasFormWriteAccess, priority: 30 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs">
      {navbarLinks.map(link => {
        if (_.isBoolean(link.condition) && !link.condition) return null;
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.path}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

  if (submissionError) {
    return <AppErrors errors={[submissionError]}/>
  }

  if (isSubmissionActive || _.isEmpty(submission) || !userRole) {
    return <Loading/>;
  }

  if (userRole && !isOwner){
    if (userRole === routedSubmissionRoles.routeInitiator){
      return <Redirect to={`/form/${formId}/routed/${submissionId}`} />;
    }

    if (_.some(readOnlyRoles, role => role === userRole) ){
      return <Redirect to={`/form/${formId}/tracked/${submissionId}`} />;
    }

    return <CurrentSubmissionRedirect submission={submission} formId={formId} submissionId={submissionId}/>;
  }

  const takeSoleOwnership = () => {
    const {_id} = user;

    setSoleOwnership(submission, _id);
    saveUpdatedSubmission((err, submission) => {
      if (!err) {
        dispatchBeforeLogoutAction(true);
      }
    });
  }

  const getMessage = () => {
    const isGroupRouting = groupRoutingData.groupRouting;
    const currentOwner = groupRoutingData.currentOwner;
    const isUserCurrentOwner = groupRoutingData.isUserCurrentOwner;

    if (isOwner && isGroupRouting) {
      return <div>
        <span>This {isProcess ? 'process' : 'form submission'} has been routed to a group of users. You are one of <strong>{groupRoutingData.groupSize} owners</strong> of this {isProcess ? 'process' : 'form submission'}. </span>
        {!currentOwner && <span>Only one owner at a time may access the actions or edit the attached form submission(s). By clicking on the <strong>View Actions</strong> button you will open the process but <strong>temporally lock out the other owners</strong>.</span>}
        {!currentOwner && <button className="d-block my-3 btn btn-primary btn-sm" onClick={takeSoleOwnership}>View Actions</button>}
        {currentOwner && isUserCurrentOwner && <p className="my-2">You have taken the <strong>full ownership</strong> for this {isProcess ? 'process' : 'form submission'} and locked out the other ownership participants.</p>}
        {currentOwner && !isUserCurrentOwner && <p className="my-2">This {isProcess ? 'process' : 'form submission'} is currently opened by <strong>{_.get(groupRoutingData, 'currentOwner.data.email', '')}</strong>. You cannot get full ownership of it. Please try later.</p>}
        <hr/>
        <div className="my-3">
          <span>Current ownership participants:</span>
          {_.map(groupRoutingData.owners, owner => <span className="mx-2 font-weight-bold" key={owner}><i className="fa fa-user-o" aria-hidden="true"></i><span className="ml-2">{owner}</span></span>)}
        </div>
      </div>
    }


    if ( isOwner && !isGroupRouting ) {
      return <span>This {isProcess ? 'process' : 'form submission'} has been routed to you and you are the <strong>sole owner</strong> of it.</span>;
    }
  }

  return (
    <div>
      <Title text={`${_.get(formState, 'form.title', '')} -  Incoming Submission`}/>
      {isArchived && <ArchivedSubmissionAlert/>}
      <RoutedSubmissionAlert message={getMessage()} routedSubmission={routeTrack}/>
      {!isProcess && hasFormWriteAccess && <div className="d-flex justify-content-end">
        <SubmissionConversionProvider><SubmissionConvertButtons/></SubmissionConversionProvider>
          <Link
            className="btn btn-primary ml-2"
            to={{
              pathname: `/form/${formId}`,
              state: {data: submission.data || {}}
            }}
          >Create Copy</Link>
      </div>}
      <Navbar />
      <Switch>
        <Route
          exact
          path="/form/:formId/incoming/:submissionId"
          component={View}
        />
        {isOwner && !isArchived && !isProcess && !onlyRead && [<Route
          path="/form/:formId/incoming/:submissionId/edit"
          component={Edit}
          key="edit"
        />,
        <Route
          path="/form/:formId/incoming/:submissionId/route"
          key="route"
          component={SubmissionRoute}
        />]}
      </Switch>
    </div>
  )
};

export default IncomingSubmissionPage;
