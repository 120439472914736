import React from 'react';
import _ from 'lodash';
import * as Utils from '../../../utils';
import NativePromise from 'native-promise-only';

const SubmissionsContext = React.createContext();

const initialState = {
  error: '',
  isActive: false,
};

const SubmissionsReducer = (state, action) => {
  switch (action.type) {
    case 'SUBMISSIONS_RESET':
      return initialState;
    case 'SUBMISSIONS_ACTION_SUCCESS':
      return {
        ...initialState,
        isActive: state.isActive
      };
    case 'SUBMISSIONS_FAILURE':
      return {
        ...state,
        error: action.error,
        isActive: false,
      };
    case 'SUBMISSIONS_ACTION_START':
      return {
        ...state,
        error: '',
        isActive: true,
      };
    case 'SET_ACTIVE_FALSE':
        return {
          ...state,
          isActive: false,
        };
    default:
      return state;
  }
};

export function SubmissionsProvider(props) {
  const [state, dispatch] = React.useReducer(SubmissionsReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <SubmissionsContext.Provider value={value} {...props} />;
}

export function useSubmissions() {
  const context = React.useContext(SubmissionsContext);
  if (!context) {
    throw new Error('useSubmissions must be used within a SubmissionsProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

export const resetSubmissions = () => ({
  type: 'SUBMISSIONS_RESET',
});

const failSubmissions = (error) => ({
  type: 'SUBMISSIONS_FAILURE',
  error,
});

const finishSubmissionsAction = () => ({
  type: 'SUBMISSIONS_ACTION_SUCCESS',
});

const submissionsActionStart = () => ({
  type: 'SUBMISSIONS_ACTION_START',
});

export const setActiveFalse = () => ({
  type: 'SET_ACTIVE_FALSE',
});

export const saveSubmissions = (dispatch, submissions = [], formId, formName, done=()=> {} ) => {
  dispatch(submissionsActionStart());

  NativePromise.all(_.map(submissions, subm => Utils.saveSubmission(subm, formId, formName)))
    .then(() => {
      dispatch(finishSubmissionsAction());
      done();
      dispatch(setActiveFalse());
    })
    .catch((err) => {
      dispatch(failSubmissions(err));
      done(err);
    });
}

export const deleteSubmissions = (dispatch, submissionIds = [], formId, done=()=> {} ) => {
  dispatch(submissionsActionStart());

  NativePromise.all(_.map(submissionIds, id => Utils.deleteSubmission(id, formId)))
    .then(() => {
      dispatch(finishSubmissionsAction());
      done();
      dispatch(setActiveFalse());
    })
    .catch((err) => {
      dispatch(failSubmissions(err));
      done(err);
    });
}
