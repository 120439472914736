import React, { useState } from 'react';
import { Form } from '@formio/react';
import { FormsConfig } from '../../../../config';
import { useAuth } from '../../../auth';
import NamedSubmissionsList from '../../submission/components/NamedSubmissionsList';

const AllSpecial = (props) => {
  const { readOnly} = props;
  const {state: authState } = useAuth();
  const { user} = authState;
  const options = { template: 'bootstrap', iconset: 'fa', readOnly: !!readOnly,  userId: user._id};
  const [specialParams, setSpecialParams] = useState(null);

  const onSettingsChange = function (data, changeInfo, changed) {
    if (changed) {
      setSpecialParams(null);
    }
  }

  return (
    <div>
      <Form
        src={FormsConfig.getSrc('allSpecial')}
        onChange={onSettingsChange}
        formReady={(form)=> {
          form.on('showSpecial', (params) => {
            setSpecialParams(params)
          });
        }}
        options={options}
      />
      {specialParams && <NamedSubmissionsList
        id={specialParams.formId} 
        hideAddBtn={true} 
        submissionsRequestParams={{
          query: {
            'metadata.routed__exists': true,
            'data.submissionAccessData.allOwnerIds': user._id,
            ...specialParams.query
          }
        }}
        gridOptions={{hideSelect: true}}
        getViewPath={(formId, submissionId) => `/form/${formId}/incoming/${submissionId}`}
        {...props} 
      />
      }
    </div>
  );
};

export default AllSpecial;
