import { Formio } from '@formio/react';
import _ from 'lodash';
import { FormsConfig } from '../config';
import { getCurrentStageSettings, getProcessFormsPath, getStageSettings, isProcess } from './ProcessUtils';
import {Utils as FormioUtils} from 'formiojs';
import FileSaver from 'file-saver';

const currentRouteArchiveUsersPath = 'data.currentRoute.archiveUsers';
export const accessControlCompKey = 'submissionAccessData';
export const autoNameFieldKey = 'autoNameField';

export const hasAdminRights = (role) => {
  return role.hasOwnProperty('administrator') && role.administrator;
}

export const setSubmissionName = (submission, name) => {
  _.set(submission, 'metadata.submissionName', name);
}

export const getSubmissionName = (submission) => {
  return _.get(submission, 'metadata.submissionName', '');
}

export const getRequestParams = (limit, query, sort, params, select, page) => {
  const requestParams = {...query, ...params};

  // Ten is the default so if set to 10, don't send.
  if (limit !== 10) {
    requestParams.limit = limit;
  }
  else {
    delete requestParams.limit;
  }

  if (_.isNumber(page) && page !== 1) {
    requestParams.skip = (page - 1) * limit;
  }
  else {
    delete requestParams.skip;
  }

  if (select) {
    requestParams.select = select;
  }
  else {
    delete requestParams.select;
  }

  if (sort) {
    requestParams.sort = sort;
  }
  else {
    delete requestParams.sort;
  }

  return requestParams;
};

export const getForms = ({ limit, query, select, sort }, page = 1, params = {}) => {
  const formio = new Formio(`${Formio.getProjectUrl()}/form`);
  const requestParams = getRequestParams(limit, {...query, type:'form'}, sort, params, select, page);

  return formio.loadForms({ params: requestParams })
}

export const getForm = (id, name) => {
  const formPath = `/${id ? `form/${id}` : `${name}`}`;
  const path = `${Formio.getProjectUrl()}${formPath}`;
  const formio = new Formio(path);

  return formio.loadForm();
}

export const saveForm = (form) => {
  const id = form._id;
  const path = `${Formio.getProjectUrl()}/form${id ? `/${id}` : ''}`;
  const formio = new Formio(path);

  return formio.saveForm(form)
}

export const deleteSubmission = (submissionId, formId, formName) => {
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const formio = new Formio(`${Formio.getProjectUrl()}${formPath}/submission/${submissionId}`);

  return formio.deleteSubmission();
};

export const getSubmission = (submissionId, formId, formName) => {
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const url = `${Formio.getProjectUrl()}${formPath}/submission${submissionId ? `/${submissionId}` : ''}`;
  const formio = new Formio(url);

  return formio.loadSubmission()
};

export const saveSubmission = (data, formId, formName) => {
  const id = data._id;
  const projectUrl = Formio.getProjectUrl();
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const submissionPath = `/submission${id ? `/${id}` : ''}`;
  const url = `${projectUrl}${formPath}${submissionPath}`;

  const formio = new Formio(url);

  return formio.saveSubmission(data)
};

export const getSubmissions = ({ limit, query, select, sort },  params = {}, formId, formName) => {
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const formio = new Formio(`${Formio.getProjectUrl()}${formPath}/submission`);
  const requestParams = getRequestParams(limit, query, sort, params, select);

  return formio.loadSubmissions({params: requestParams})
};

export const exportSubmissions = (query, formId, formName) => {
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const url = `${Formio.getProjectUrl()}${formPath}/export?format=csv&view=formatted`;
  const formio = new Formio(url);

  return formio.makeRequest('export', url, 'GET', null, {
    ignoreCache: true,
    headers: {
      'x-query': JSON.stringify(query),
    }
  }).then((data) => {
    var blob = new Blob([data], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, `${formName}.csv`);
  });
}

export const setSubmissionOwner = (submission, user, path) => {
  _.set(submission, `${path ? `data.${path}.` : ''}data.submissionAccessData.allOwnerIds`, [user._id]);
  _.set(submission, `${path ? `data.${path}.` : ''}data.submissionAccessData.owners`, [user]);
}

export const cleanSubmissionAccess = (submission, path) => {
  const access = _.get(submission, `${path ? `data.${path}.` : ''}data.submissionAccessData`, {});
  _.each(access, (users, permissionType) => {
    access[`${permissionType}`] = [];
  });
}

export const cancelLastAndRestorePrevRoute = (routeTrack) => {
  const tracks = _.get(routeTrack, 'data.tracking', []);
  const lastTrack = _.last(tracks);
  const canceledTracks = _.get(routeTrack, 'data.canceledTracks', []);

  const canceledTrack = {
    routeId: lastTrack.routeId,
    dateCanceled: new Date().toISOString(),
    recipientsInfo: lastTrack.recipientsInfo,
    senderInfo: lastTrack.senderInfo,
    stageId: lastTrack.stageId || '',
    stageName: lastTrack.stageName || ''
  };

  tracks.pop();
  canceledTracks.push(canceledTrack);

  const routeTrackData = routeTrack.data;
  const currentTrack = _.last(tracks);

  routeTrackData.routeInitiators = _.chain(tracks)
    .map(track => _.get(track, 'senderInfo[0].from[0]'))
    .value();

  const currentOwners = _.chain(currentTrack).get('recipientsInfo', []).map(recipient => _.get(recipient, 'to[0]')).value();
  const currentTrackDate = _.get(currentTrack, 'senderInfo[0].dateSent', '');
  const currentReadOnlyUsers = _.chain(tracks)
    .map(track => {
      const senderInfo = _.get(track, 'senderInfo[0]', []);
      const cc = _.get(senderInfo, 'cc', []);

      const bcc =  _.get(senderInfo, 'bcc', []);
      const owners = _.chain(track).get('recipientsInfo', []).map(recipient => _.get(recipient, 'to[0]')).value();
      return [ ...cc, ...bcc, ...owners];
    })
    .flattenDeep()
    .uniqBy('_id')
    .filter(user => !_.some(currentOwners, currentOwner => currentOwner._id === user._id) && !_.some(routeTrackData.routeInitiators, initiator => initiator._id === user._id))
    .value();

  const allUsersIds= _.chain([...currentReadOnlyUsers, ...currentOwners]).map(user => user._id).uniq().value();
  const currentArchiveUsers = _.chain(routeTrack).get(currentRouteArchiveUsersPath, []).filter(archiveUser => _.some(allUsersIds, id => id === archiveUser._id)).value();

  routeTrackData.currentRoute = {
    allUsersIds,
    owners: currentOwners,
    readOnlyUsers: currentReadOnlyUsers,
    date: currentTrackDate,
    from: _.chain(tracks).last().get('senderInfo[0].from', []).value(),
    archiveUsers: currentArchiveUsers
  };
}

export const defaultRouteTrackData = {
  routeInitiators: [],
  currentRoute: {
    date: '',
    readOnlyUsers: [],
    owners: [],
    archiveUsers: []
  },
  tracking: [],
  routedSubmission:{},
  routedForm: {}
};

const getPureUsers = (recipients) => {
  return _.map(recipients, recipient => _.get(recipient, 'data.user', {}));
};

export const getUpdatedRouteTrackData = (routedSubmission, routeData, routeTrack, form, prevStageId) => {
  const trackData = _.isEmpty(routeTrack) ? defaultRouteTrackData : routeTrack.data;
  const {data: routingInfo, created: routeDate} = routeData;
  const {readOnlyUsersFrom } = routingInfo;
  let { owners, readOnlyUsersCc, readOnlyUsersBcc} = routingInfo;

  owners = getPureUsers(owners);
  readOnlyUsersCc = getPureUsers(readOnlyUsersCc);
  readOnlyUsersBcc= getPureUsers(readOnlyUsersBcc);

  const isFormProcess = isProcess(form);

  const fullAccessUsers = _.uniqBy([...owners], '_id');
  const readOnlyUsers = _.chain([
      ...readOnlyUsersCc,
      ...readOnlyUsersBcc,
      ..._.get(trackData, 'currentRoute.readOnlyUsers', []),
      ..._.chain(trackData).get('currentRoute.owners', []).filter(prevOwner => prevOwner._id !== readOnlyUsersFrom._id).value()
    ])
    .uniqBy('_id')
    .filter(readOnlyUser => !_.some(fullAccessUsers, fullAccessUser => fullAccessUser._id === readOnlyUser._id))
    .value();

  const routeInitiators = _.chain([...trackData.routeInitiators, readOnlyUsersFrom ])
    .uniqBy('_id')
    .value();

  const getRouteStage = () => {
    const stageSettings = {
      stageName: '',
      stageId: ''
    };

    if (isFormProcess) {
      const currentStage = getCurrentStageSettings(routedSubmission, form);
      stageSettings.stageName = currentStage.title;
      stageSettings.stageId = currentStage.id;
    }
    return stageSettings;
  };

  const routeStage = getRouteStage();

  const getRouteMessage = () => {
    if(!isFormProcess) return '';

    let message = `route from ${getStageSettings(prevStageId, form).title} to ${routeStage.stageName};`;
    const stagePanels = _.filter(form.components, panel => panel.stageId === prevStageId)

    FormioUtils.eachComponent(stagePanels, (comp, path) => {
      if (comp.showInTrack) {
        const compValue = _.get(routedSubmission.data, path, '');
        message = `
          ${message}
          ${compValue};
        `;
      }
    }, true);

    return message;
  };

  return {
    routeInitiators,
    currentRoute: {
      date: routeDate,
      from: [readOnlyUsersFrom || {}],
      readOnlyUsers,
      owners: fullAccessUsers,
      allUsersIds: _.chain([...readOnlyUsers, ...fullAccessUsers]).map((user)=> user._id).filter(id=> !!id).uniq().value(),
      archiveUsers: _.chain(trackData).get('currentRoute.archiveUsers', []).filter(archiveUser => !_.some(owners, owner => owner._id === archiveUser._id)).value()
    },
    tracking: [
      ...trackData.tracking,
      {
      routeId: routeData._id || '',
      ...routeStage,
      senderInfo: [{
        from: [readOnlyUsersFrom || {}],
        dateSent: routeDate,
        comments: getRouteMessage(),
        cc: [...readOnlyUsersCc],
        bcc: [...readOnlyUsersBcc]
      }],
      recipientsInfo: _.map(owners, (owner) => ( {
          dateOpened: '',
          opened: false,
          to: [owner],
        }))
    }],
     routedSubmission: { id: routedSubmission._id, name: _.get(routedSubmission, 'metadata.submissionName', '')},
     routedForm: {
      id: form._id,
      title: form.title,
      name: form.name
    }
  }
}

export const setUpdatedAccess = (routedSubmission, routeTrackData, path) => {
  const submissionAccess = _.get(routedSubmission, `${path ? `data.${path}.` : ''}data.submissionAccessData`, {});

  _.set(submissionAccess, 'allOwnerIds', _.chain(routeTrackData).get('currentRoute.owners', []).map(owner => owner._id).value());
  _.set(submissionAccess, 'owners', _.get(routeTrackData, 'currentRoute.owners', []));
  _.set(submissionAccess, 'readOnlyUsers', _.get(routeTrackData, 'currentRoute.readOnlyUsers', []));
  _.set(submissionAccess, 'routeInitiators', _.get(routeTrackData, 'routeInitiators', []));
}

export const setSubmissionRouted = (routedSubmission, path) => {
  _.set(routedSubmission, `${path ? `data.${path}.`: ''}metadata.routed`, true);
}

export const isSubmissionRouted = (submission) => {
  return  _.get(submission, 'metadata.routed');
}

export const unsetSubmissionRouted = (routedSubmission, path) => {
  _.set(routedSubmission, `${path ? `data.${path}.`: ''}metadata.routed`, true);
}

export const updateRoutedSubmissionAccess = (routedSubmission, routeTrackData, form) => {
  setUpdatedAccess(routedSubmission, routeTrackData);
  setSubmissionRouted(routedSubmission);

  const isFormProcess = isProcess(form);
  if (isFormProcess) {
    const processFormsPath = getProcessFormsPath(form);
    _.each(processFormsPath, path => {
      setUpdatedAccess(routedSubmission, routeTrackData, path);
      setSubmissionRouted(routedSubmission, path);
    });
  }
}

export const submissionStatuses ={
  archived: 'archived',
  deleted: 'deleted'
};

export const setSubmissionStatus = (submission, status) => {
  _.set(submission, 'metadata.status', status);
}

export const unsetSubmissionStatus = (submission) => {
  _.unset(submission, 'metadata.status');
}

export const addSelectedRowsClass = (form, selectedSubmissions, idProperty) => {
  const gridComp = form.getComponent('submissions');
  const rowId = idProperty || '_id';

  if (gridComp) {
    gridComp.dataTableReady.promise.then(() => {
      const gridBody = _.get(gridComp, 'formioGrid.tbody', {});
      const rows = _.get(gridBody, 'refs.row', []);
      const rowsData = gridBody.data;
      _.each(rows, row => {
        row.classList.remove('bg-selected');
      });

      _.each(selectedSubmissions, (selectedSubm) => {
        _.find(rowsData, (data, index) => {
          if (data[rowId] === selectedSubm._id) {
            rows[index].classList.add('bg-selected');
            return data;
          }
        });
      });
    });
  }
}

export const getProgress = (limit, skipCoef, total) => {
  const progress = _.round(((limit*skipCoef)/total)*100);
  return progress > 100 ? 100 : progress;
}

export const setRouteTrackArchiveUser = (routeTrack, user) => {
  const archiveUsers = _.get(routeTrack, currentRouteArchiveUsersPath, []);
  archiveUsers.push(user);
}

export const removeRouteTrackArchiveUser = (routeTrack, user) => {
  const archiveUsers = _.get(routeTrack, currentRouteArchiveUsersPath, []);
  _.set(routeTrack, currentRouteArchiveUsersPath, _.filter(archiveUsers, archiveUser => archiveUser._id !== user._id));
}

export const isRoutedArchived = (routeTrack, user) => {
  const archiveUsers = _.get(routeTrack, currentRouteArchiveUsersPath, []);
  return _.some(archiveUsers, archiveUser => archiveUser._id === user._id);
}

export const getAccessControlComponent = () => {
  return getForm(null, FormsConfig.submissionAccessData)
    .then((form) => {
      return _.chain(form).get('components', []).find(comp => comp.key === accessControlCompKey ).value();
    });
}

export const getCutLocationPath = (locationPath = '', cutNumber) => {
  return _.chain(locationPath).split('/').slice(0, cutNumber).join('/').value();
}

/**--------------------------------------------------------------------------------
 *  From White List Project
 * --------------------------------------------------------------------------------
 */
export const unauthorizedMsg = (formId) => {
  const msg = `🚧Unauthorized🚧 - This action requires Write Access to the form. If this is a mistake please contact your administrator with your email and the ID of this form. FORM ID: ${formId}`
  return msg;
}