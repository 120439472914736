import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';


import { FormsConfig } from '../../../../config';
import { FormView, getForm, useForm } from '../../../forms/form';
import { FormsProvider } from '../../../forms/form/formsContext';
import { SubmissionProvider, SubmissionsList, SubmissionsProvider } from '../../../forms/submission';
import EmployeePage from './EmployeePage';
import { Loading, AppErrors } from '../../../../common';
import _ from 'lodash';

const EmployeesPage = () => {
  const paramName = 'employee';
  const pathStart = '/employees';
  const formName = FormsConfig.userProfile;

  const { dispatch: dispatchFormEvent, state: formState} = useForm();
  const {form, isActive: isFormActive, error } = formState;

  useEffect(() => {
    getForm(dispatchFormEvent, null, formName);
  }, [dispatchFormEvent, formName]);

  if (isFormActive || _.isEmpty(form)) {
    return <Loading/>
  }

  if (error) {
    return <AppErrors errors={[error]}/>
  }

  return (
    <div>
      <FormsProvider>
        <SubmissionsProvider>
          <Switch>
            <Route
              exact
              path={`${pathStart}`}
              render={(props) => {
                return  (<div>
                    <SubmissionsList
                      {...props}
                      formName={formName}
                      getViewPath={(formId, submission) => `${pathStart}/${submission._id}`}
                      createSubmissionPath={`${pathStart}/create`}
                      addBtnTitle={'Employee'}
                      title= {'Employees'}
                      hideAddBtn={true}
                      gridName={'employeesTableFilter'}
                    />
                  </div>)
              }}
            />
            <Route
              exact
              path={`${pathStart}/create`}
              render={(props) => (
                <SubmissionProvider>
                  <FormView {...props} name={formName} pathStart={pathStart}/>
                </SubmissionProvider>
              )}
            />
            <Route
              path={`${pathStart}/:${paramName}Id`}
              render={(props) => (
                <SubmissionProvider>
                  <EmployeePage {...props} pathStart={pathStart} paramName={paramName} formName={formName} />
                </SubmissionProvider>
              )}
            />
          </Switch>
        </SubmissionsProvider>
      </FormsProvider>
    </div>
  );
};



export default EmployeesPage;
