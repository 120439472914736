import React, { useEffect, useCallback } from 'react'
import _ from 'lodash';

import { Progressbar, AppErrors } from '../../../../common';
import { useForms, addFormAccessControlFields, setFilterQueryParam} from '../formsContext';
import { Title } from '../../../../common';
import { useAuth } from '../../../auth';
import { useAlerts } from '../../../alerts';
import FormsList from './FormsList';
import { filterNames, useFilter } from '../../../filter/filterContext';
import Filter from '../../../filter/components/Filter';
import { Link } from 'react-router-dom';
import { hasAdminRights } from '../../../../utils';
// import { StopBanner } from '../../../alerts/StopBanner';

const FormsListPage = () => {
  const {state: formsState, dispatch: dispatchFormsAction} = useForms();
  const {formsUpdateProgress, formsUpdating, error: formsError, requestParamsSet} = formsState;
  const {state: authState} = useAuth();
  const {is: role} = authState;
  const {state: filterState} = useFilter();
  const { filter, error: filterError, set: filterSet } = filterState;
  const { addAlert } = useAlerts();

  const isAdmin = hasAdminRights(role);

  const setQuery = useCallback((query, filter, folderQuery, propertySearchQuery, noFolderQuery) => {
    const {folder, noFolder, searchQuery, searchProperty} = filter;

    if (folder && folder._id && !noFolder) {
      query[`${folderQuery}`] = folder._id;
    };

    if (noFolder) {
      query[`${noFolderQuery}`] = false;
    };
    
    if (searchQuery && searchProperty) {
      let regex = `/${searchQuery.trim()}/ig`;

      if (searchProperty === 'name') {
        regex = `/^${_.chain(searchQuery).split(/(\d+)/).filter(part => !!part).map(part => `(?=.*${part.trim()})`).join('').value()}.*$/im`;
      }
      
      query[`${propertySearchQuery}`] = encodeURIComponent(regex);
    }
  }, [])

  const getFilterQuery = useCallback(() => {
    if(_.isEmpty(filter)) return {};

    const { searchProperty} = filter;
    const query = {}; 

    if (isAdmin){
      const folderQuery = 'properties.folder';
      const noFolderQuery = 'properties.folder__exists';
      const propertySearchQuery = `${searchProperty}__regex`;
      setQuery(query, filter, folderQuery, propertySearchQuery, noFolderQuery);
    }
    else {
      const folderQuery = 'data.folder._id';
      const propertySearchQuery = `data.form.${searchProperty}__regex`;
      setQuery(query, filter, folderQuery, propertySearchQuery);
    }
    return query;
  }, [filter, isAdmin, setQuery]);

  useEffect(()=> {
    if (filterSet) {
      setFilterQueryParam(dispatchFormsAction, getFilterQuery());
    }
  }, [dispatchFormsAction, filter, getFilterQuery, filterSet]);
 
  const updateAccessControlFields = () => {
    addFormAccessControlFields(dispatchFormsAction, (err) => {
      addAlert({ type: 'success', content: 'Form are succesfully updated' });
    });
  }

  const AdminBtns = () => {
    return isAdmin
      ? (
        <div className="d-flex justify-content-between">
          <button onClick={updateAccessControlFields} className="btn btn-dark btn-sm mb-3">Update Form Access Control Fields</button>
          <Link className="btn btn-primary btn-sm mb-3 px-4" to="/form/create"><i className="fa fa-plus"></i>&nbsp; Create Process</Link>
        </div>
      )
      : null;
  };

  return (
    <div>
      <Title text={'Forms'}/>
      {/* <StopBanner /> */}
      <p className="alert alert-primary" role="alert">This is a list of all the forms and resources for the project. You can <b>filter and sort the rows</b> in the table using the menu on the right side of each column header. It is also possible to <b>hide / show columns</b>.</p>
      <AppErrors errors={[formsError, filterError]} />
      {formsUpdating
        ? <Progressbar title={'Forms Access Control Component Update'} progress={formsUpdateProgress} message={`${formsUpdateProgress}% . . . Please wait. It can take some minutes`}/>
        : <>
          <AdminBtns/>
          <Filter filterName={filterNames.formsFilter} filterOptions = {{isAdmin}} filterFormName ={'formsFilter'} />
          {filterSet && requestParamsSet && <FormsList/>}
        </>
      }
    </div>
  );
};

export default FormsListPage;
