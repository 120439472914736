import React from 'react';
import {Link, useParams } from 'react-router-dom'

const ArchivedSubmissionAlert = () => {
  const {formId} = useParams();

  return (
    <div className="alert alert-warning " role="alert">
      <span className="text-uppercase font-weight-bold">Archived record. </span>If you want to continue any operations with this record, you need to restore it in the <Link to={`/form/${formId}/archived`}>Archived tab</Link> .
    </div>
  )
};

export default ArchivedSubmissionAlert;
