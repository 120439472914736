import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useForm } from '../../form';
import { useAuth } from '../../../auth';
import RoutingSubmissionsList from '../../partials/RoutingSubmissionsList';


const IncomingSubmissionsList = (props) => {
  const {
    archived,
    hideTitle,
    title,
    gridName
  } = props;

  const formId  = useParams().formId;
  const {state: authState} = useAuth();
  const {user} = authState;
  
  const { state: formState} = useForm();
 
  const getGridSubmissionsData = (routedSubmissions) => {
    return _.map(routedSubmissions, (subm) => {
      const trackingData = _.get(subm, 'data.tracking', []);
      const currentRoute = _.last(trackingData);

      return {
        submission: subm,
        routeTrackId: subm._id,
        routedSubmissionId: _.get(subm, 'data.routedSubmission.id', ''),
        submissionName: _.get(subm, 'data.routedSubmission.name', ''),
        formId: formId ||  _.get(subm, 'data.routedForm.id', ''),
        formTitle: formId ?  _.get(formState, 'form.title', '') : _.get(subm, 'data.routedForm.title', ''),
        from: _.get(currentRoute, 'senderInfo[0].from[0].data.email'),
        dateSent: _.get(subm, 'data.currentRoute.date', ''),
        dateOpened: _.chain(currentRoute).get('recipientsInfo', [])
          .filter(recipientInfo => {
            return recipientInfo.opened && _.get(recipientInfo, 'to[0]._id', '') === user._id;
          })
          .map(recipientInfo => {
            const openedDate = recipientInfo.dateOpened;
            return {
              date: openedDate,
              formattedDate: new Date(openedDate),
            }
          })
          .minBy('formattedDate')
          .get('date', '')
          .value()
       }})
  }

  return <RoutingSubmissionsList
    gridName={gridName}
    submissionsRequestParams={{
      query: {
        ...(formId ? {'data.routedForm.id': formId} : {}),
        'data.currentRoute.owners._id': user._id,
        [`data.currentRoute.archiveUsers._id${archived ? '':'__ne'}`]: user._id,
      }
    }}
    transformGridData={getGridSubmissionsData}
    styleUnopenedRecords={true} 
    gridOptions={{
      viewIncoming: true, 
      hide: {
        restore: !archived || !formState.hasFormWriteAccess ? true : false, 
        moveToArchived: archived || !formState.hasFormWriteAccess ? true : false
      }
    }} 
    getOpenRecordPath={(routedFormId,routedSubmissionId) => `/form/${routedFormId}/incoming/${routedSubmissionId}`}
    title={hideTitle ? '' : (title || (formId && `Incoming Submissions - ${formState.form.title} Form `))}
  />
}


export default IncomingSubmissionsList;
