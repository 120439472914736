import React, { useEffect } from 'react';

import ProcessEdit from './ProcessEdit';
import { useHistory } from 'react-router';
import { useForm, saveForm, loadAccessControlComponent, resetForm} from '../../form/formContext';
import { Loading, Title, AppErrors } from '../../../../common';

const ProcessCreatePage = () => {
  const history = useHistory();
  const { state: formState, dispatch: dispatchFormAction } = useForm();
  const { loadingAccessControlComp, accessControlCompError, accessControlComp } = formState;

  useEffect(() => {
    loadAccessControlComponent(dispatchFormAction);
  }, [dispatchFormAction]);

  const onSaveForm = (form) => {
    saveForm(dispatchFormAction, form, (err, form) => {
      if (!err) {
        dispatchFormAction(resetForm());
        history.push(`/form/${form._id}/edit`);
      }
    })};

  if (loadingAccessControlComp) {
    return <Loading/>
  }

  return (
    <div>
      <Title text={'Create Process'}/>
      <hr />
      <AppErrors errors={[formState.error, accessControlCompError]} />
      <ProcessEdit saveForm={onSaveForm} saveText='Create Process' accessControlComp={accessControlComp}/>
    </div>
  );
};

export default ProcessCreatePage;
