import React, { useEffect } from 'react';
import { Form } from '@formio/react';

import { getFilter, setFilter, useFilter } from '../filterContext';
import { FormsConfig } from '../../../config';

const Filter = ({filterName, filterOptions = {}, filterFormName}) => {
  const {state: filterState, dispatch} = useFilter();
  const {filter} = filterState;

  useEffect(() => {
    getFilter(dispatch, filterName);
  }, [dispatch, filterName]);

  const addFilter = (filter) => {
    setFilter(dispatch, filter, filterName);
  };

  return (
    <Form
      submission={{data: filter}}
      src={FormsConfig.getSrc(filterFormName)}
      options={filterOptions}
      formReady={(form)=> {
        form.on('filterForms', (filter) => {
          addFilter(filter);
        });
        form.on('search', (filter) => {
          addFilter(filter);
        });
        form.on('resetFilter', () => {
          addFilter({});
        });
      }}
    />
  );
};

export default Filter;
