import React from 'react';
import { Form, Utils } from '@formio/react';
import { Title } from '../../../../common';
import { useForm, saveForm, receiveFolder } from '../formContext';
import { useAlerts } from '../../../alerts';
import { FormsConfig } from '../../../../config';
import _ from 'lodash';

const FormFolder= () => {
  const { addAlert } = useAlerts();
  const { state: formState, dispatch } = useForm();

  const {
    form,
    formFolder
  } = formState;

  const isFormFolderSubmission = !!formFolder._id;

  const onSaveFormFolder = (folder) => { 
    const id = _.get(folder, 'data.folder._id')
    const formToSave = Utils.fastCloneDeep(form);

    _.set(formToSave,'properties.folder', id);

    saveForm(dispatch, formToSave, (err) => {
      if (!err) {
        dispatch(receiveFolder(folder));
        addAlert({ type: 'success', content: 'Form succesfully updated' });
      }
    });
  }

  const formData = () => {
    const {title, _id, name} = form;
    return  {title, _id, name};
  }

  const formFolderData = () => {
    if (isFormFolderSubmission) {
      formFolder.data = {...formFolder.data, form: formData()};

      if (!_.get(formFolder, 'data.folder.data.folderName')) {
        _.set(formFolder.data, 'folder', {});
      }
      return formFolder;
    }

    return {data: {form: formData()}};
  };

  return (
    <div>
      <Title text={`Form '${ form.title }' Folder`}/>
      <Form
        options={{editForm: form}}
        src={FormsConfig.getSrc('folderForm')}
        submission={formFolderData()}
        formReady={(form)=> {
          form.on('submitDone', (folderSubmission)=> {
            onSaveFormFolder(folderSubmission);
          });
        }}
      />

    </div>
  );
}

export default FormFolder;