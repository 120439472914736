import React from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useForm } from '../../form';
import { useAuth } from '../../../auth';
import RoutingSubmissionsList from '../../partials/RoutingSubmissionsList';


const RoutedSubmissionsList = (props) => {
  const {
    archived,
    hideTitle,
    title,
    gridName
  } = props;
  
  const formId  = useParams().formId;
  const {state: authState} = useAuth();
  const {user} = authState;
  const {state: formState} = useForm();

  const getGridSubmissionsData = (submissionsResponse) => {
    const routedSubmissions = _.chain(submissionsResponse)
      .map(subm => {
        return {
          routeSubmission: subm,  
          routeTrackId: subm._id,
          submission: _.get(subm, 'data.routedSubmission'),
          form: _.get(subm, 'data.routedForm'),
          data: _.chain(subm)
            .get('data.tracking', [])
            .filter(track => _.get(track, 'senderInfo[0].from[0]._id', '') === user._id)
            .last()
            .value()
        };
      })
      .value()

    return _.map(routedSubmissions, (subm) => {
      return {
        submission: subm.routeSubmission,
        routeTrackId: subm.routeTrackId,
        routedSubmissionId: _.get(subm, 'submission.id', ''),
        submissionName: _.get(subm, 'submission.name', ''),
        formTitle: _.get(formId ? formState : subm, 'form.title', ''),
        formId: formId || _.get(subm, 'form.id', ''),
        to: _.chain(subm).get('data.recipientsInfo', []).map(owner => _.get(owner, 'to[0].data.email', '')).value(),
        dateSent: _.get(subm, 'data.senderInfo[0].dateSent', ''),
        dateOpened: _.chain(subm).get('data.recipientsInfo', []).filter(recipientInfo => recipientInfo.opened)
          .map(recipientInfo => {
            const openedDate = recipientInfo.dateOpened;
            return {
              date: openedDate,
              formattedDate: new Date(openedDate),
            }
          })
          .minBy('formattedDate')
          .get('date', '')
          .value()
      }
    });
  }

  return <RoutingSubmissionsList
    gridName={gridName}
    submissionsRequestParams={{
      query: {
        ...(formId ? {'data.routedForm.id': formId} : {}),
        'data.routeInitiators._id': user._id,
        [`data.currentRoute.archiveUsers._id${archived ? '':'__ne'}`]: user._id,
      },
    }} 
    styleUnopenedRecords={false}
    transformGridData={getGridSubmissionsData}
    gridOptions={{
      viewRouted: true,
      hide: {
        restore: !archived || !formState.hasFormWriteAccess,  
        moveToArchived: archived || !formState.hasFormWriteAccess
      }, 
    }} 
    getOpenRecordPath={(routedFormId,routedSubmissionId) => `/form/${routedFormId}/routed/${routedSubmissionId}`}
    title={hideTitle ? '' : (title || (formId && `Routed Submissions - ${formState.form.title} Form `))}
  />
}

export default RoutedSubmissionsList;
