import { Route, Switch } from 'react-router-dom';
import React from 'react';

import RoutedSubmissionsList from './RoutedSubmissionsList';
import { SubmissionsProvider } from '../../submission/submissionsContext';
import RoutedSubmissionPage from './RoutedSubmissionPage';
import { SubmissionProvider } from '../../submission/submissionContext';

const RoutedSubmissionsPage = () => (
  <SubmissionsProvider>
    <Switch>
      <Route
        exact
        path="/form/:formId/routed"
        component={RoutedSubmissionsList}
      />
      <Route
        path="/form/:formId/routed/:submissionId"
        render={(props) => <SubmissionProvider><RoutedSubmissionPage {...props} /></SubmissionProvider>}
      />
    </Switch>
  </SubmissionsProvider>
);

export default RoutedSubmissionsPage;
