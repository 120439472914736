import React from 'react';
import { Formio } from '@formio/react';
import { FormsConfig } from '../../../config';
import * as Utils from '../../../utils';

const UserContext = React.createContext();

const initialState = {
  addingUserToEveryoneStatus: {},
  addingUserToEveryoneError: '',
  isCsvUserGroupsLoading: false,
  isCsvUserGroupsFetched: false,
  allowCsv: false,
  error: ''
};

export const everyoneGroup = 'Everyone';
const everyoneGroupDescription = 'All Users Group';

const addUserToEveryoneProgress ={
  'gettingGroup':{
    progress: 30,
    message: 'Checking/Creating Everyone Group'
  },
  'savingUserInGroup':{
    progress: 70,
    message: 'Saving User In Everyone Group'
  },
  'finishing':{
    progress: 99,
    message: 'Finishing'
  },
};

const userReducer = (state, action) => {
  switch (action.type) {
    case 'USER_RESET':
      return initialState;
    case 'SET_ADD_USER_STATUS':
      return {
        ...state,
        addingUserToEveryoneStatus: addUserToEveryoneProgress[`${action.status}`]
      };
    case 'ADD_USER_FAILURE':
      return {
        ...state,
        addingUserToEveryoneStatus: {},
        addingUserToEveryoneError:  action.error
      };
    case 'CSV_USER_GROUPS_REQUEST':
      return {
        ...state,
        isCsvUserGroupsLoading: true,
        error: ''
      }
    case 'CSV_USER_GROUPS_SUCCESS':
      return {
        ...state,
        isCsvUserGroupsLoading: false,
        isCsvUserGroupsFetched: true,
        allowCsv: !!action.userGroups.length,
        error: ''
      }
      case 'CSV_USER_GROUPS_FAIL':
      return {
        ...state,
        isCsvUserGroupsLoading: false,
        isCsvUserGroupsFetched: true,
        error: action.error
      }
    default:
      return state;
  }
};

export function UserProvider(props) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <UserContext.Provider value={value} {...props}/>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  }
}

export const resetUser = () => ({
  type: 'USER_RESET'
});

const failAddingUser = (error) => ({
  type: 'ADD_USER_FAILURE',
  error,
});

const setAddingUserStatus = (status) => ({
  type: 'SET_ADD_USER_STATUS',
  status
});

const startCsvUserGroupsLoading = () => ({
  type: 'CSV_USER_GROUPS_REQUEST',
});

const receiveCsvUserGroups = (userGroups) => ({
  type: 'CSV_USER_GROUPS_SUCCESS',
  userGroups,
});

const failGettingCsvUserGroups = (error) => ({
  type: 'CSV_USER_GROUPS_FAIL',
  error,
});

const createEveryoneGroup = (formio, dispatch) => {
  return formio.saveSubmission({
    data: {
      groupName: everyoneGroup,
      description: everyoneGroupDescription
    },
    state: 'submitted'
  })
    .then((result) => {
      return result;
    });
};

const getEveryoneGroup = (dispatch, done = () => {}) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.usersGroup}/submission`;
  const formio = new Formio(url);

  return formio.loadSubmissions({params: {'data.groupName': everyoneGroup}})
    .then((result) => {
      const everyoneGroup = result[0];
      return everyoneGroup
        ? everyoneGroup
        : createEveryoneGroup(formio, dispatch);
    })
};

const saveUserInGroup = (dispatch, user, group) => {
  const url = `${Formio.getProjectUrl()}/${FormsConfig.userGroup}/submission`;
  const formio = new Formio(url);
  return formio.saveSubmission({
    data: {
      group,
      user
    },
    state: 'submitted'
  })
    .then((result) => {
      return result;
    });
};

export const addUserToEveryoneGroup = (dispatch, user, done = () => {}) => {
  dispatch(setAddingUserStatus('gettingGroup'));

  getEveryoneGroup(dispatch)
    .then(everyoneGroup => {
      dispatch(setAddingUserStatus('savingUserInGroup'));
      return saveUserInGroup(dispatch, user, everyoneGroup);
    })
    .then(userGroup => {
      dispatch(setAddingUserStatus('finishing'));
      dispatch(resetUser());
      done(null, userGroup);
    })
    .catch(error => {
      dispatch(failAddingUser(error))
      done(error);
    });
};

export const checkCsvAccess = (dispatch, { limit, query, select, sort }, params = {}, formId, formName, done = () => {}) => {
  dispatch(startCsvUserGroupsLoading());

  return Utils.getSubmissions({ limit, query, select, sort }, params, formId, formName)
    .then((result) => {
      dispatch(receiveCsvUserGroups(result));
      done(null, result);
    })
    .catch((error) => {
      dispatch(failGettingCsvUserGroups(error));
      done(error);
    });
}
