import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { FormsConfig } from '../../../../config';
import {  getForm, useForm } from '../../../forms/form/formContext';
import { FormsProvider } from '../../../forms/form/formsContext';
import { SubmissionProvider, SubmissionsList, SubmissionsProvider } from '../../../forms/submission';
import UserPage from './UserPage';
import { Loading, AppErrors } from '../../../../common';

const UsersPage = (props) => {
  const paramName = 'user';
  const pathStart = '/users';
  const formName = FormsConfig.user;

  const { dispatch: dispatchFormEvent, state: formState} = useForm();
  const {form, isActive: isFormActive, error} = formState;

  useEffect(() => {
    getForm(dispatchFormEvent, null, formName);
  }, [dispatchFormEvent, formName]);

  if (isFormActive || _.isEmpty(form)) {
    return <Loading/>
  }

  if (error) {
    return <AppErrors errors={[error]}/>
  }

  return (
    <div>
    <FormsProvider>
      <SubmissionsProvider>
        <Switch>
          <Route
            exact
            path={`${pathStart}`}
            render={(props) => {
             return  <div>
               <SubmissionsList
                {...props}
                formName={formName}
                getViewPath={(formId, submission) => `${pathStart}/${submission._id}`}
                createSubmissionPath={`${pathStart}/create`}
                addBtnTitle={'User'}
                title= {'Users'}
                hideAddBtn={true}
                gridName={'usersTableFilter'}
              />
              </div>
            }}
          />
          <Route
            path={`${pathStart}/:${paramName}Id`}
            render={(props) => (
              <SubmissionProvider>
                <UserPage {...props} pathStart={pathStart} paramName={paramName} formName={formName} />
              </SubmissionProvider>
            )}
          />
        </Switch>
      </SubmissionsProvider>
    </FormsProvider>
    </div>
  );
};



export default UsersPage;
