import { NavLink, Route, Switch, useParams, Link } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';


import { useSubmission, getSubmission, checkRouted } from '../submissionContext';
import SubmissionView from './SubmissionView';
import SubmissionRoute from './SubmissionRoute';
import { useForm } from '../../form';
import { Loading, Title, AppErrors } from '../../../../common';
import { useAuth } from '../../../auth';
import { ArchivedSubmissionAlert } from '../../partials';
import { hasAdminRights, submissionStatuses } from '../../../../utils';
import ProcessSubmissionView from './ProcessSubmissionView';
import { SubmissionConvertButtons } from '../../convertButtons';
import { SubmissionConversionProvider } from '../submissionConversionContext';


const SubmissionPage = () => {
  const { formId, submissionId } = useParams();
  const { dispatch, state: submissionState } = useSubmission();
  const { state: formState } = useForm();
  const {isProcess, hasFormWriteAccess} = formState;
  const {isActive: isSubmissionActive, submission, error } = submissionState;
  const {state: authState} = useAuth();
  const { is: role} = authState;
  const isAdmin = hasAdminRights(role);

  useEffect(() => {
    getSubmission(dispatch, submissionId, formId, null);
  }, [dispatch, submissionId, formId]);

  useEffect(() => {
    if(!_.isEmpty(submission) && !isAdmin) {
      checkRouted(dispatch, submission);
    }
  }, [dispatch, submission, isAdmin]);

  const submissionStatus = _.get(submission, 'metadata.status', '');
  const isArchived = useMemo(() => submissionStatus === submissionStatuses.archived, [submissionStatus]);
  const isDeleted = useMemo(() => submissionStatus === submissionStatuses.deleted, [submissionStatus]);
  const isDisabled = !isAdmin && (isArchived || isDeleted);

  const View = useCallback(() => isProcess
    ? <SubmissionConversionProvider><ProcessSubmissionView /></SubmissionConversionProvider>
    : <SubmissionView readOnly={true} />, [isProcess]);
  const Edit = useCallback(() => isProcess
    ? <SubmissionConversionProvider><ProcessSubmissionView/></SubmissionConversionProvider>
    : <SubmissionView readOnly={false} />, [isProcess]);

  const backBtnPath = useCallback(() => {
    return isArchived
      ? `/form/${formId}/archived`
      : isDeleted
        ? `/form/${formId}/deleted`
        : `/form/${formId}/submission`;
  }, [isArchived, isDeleted, formId])

  const navbarLinks = _.sortBy([
    {icon: "fa fa-chevron-left", path: backBtnPath(), title: '', priority: 0 },
    {icon: "fa fa-eye", path: `/form/${formId}/submission/${submissionId}`, condition:  !isProcess, title: 'View', priority: 10 },
    {icon: "fa fa-edit", path: `/form/${formId}/submission/${submissionId}/edit`, condition: !isDisabled && !isProcess && hasFormWriteAccess, title: 'Edit', priority: 20 },
    {icon: "fa fa-level-up", path: `/form/${formId}/submission/${submissionId}/route`, condition: !isDisabled && !isProcess && hasFormWriteAccess, notAdmin: true, title: 'Route', priority: 30 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs">
      {navbarLinks.map(link => {
        if ((link.notAdmin && isAdmin )|| (_.isBoolean(link.condition) && !link.condition)) return null;
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.path}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

  if((isSubmissionActive || _.isEmpty(submission)) && !error) {
    return <Loading/>;
  }

  const DeletedSubmissionAlert = () => {
    return (
      <div className="alert alert-warning " role="alert">
        <span className="text-uppercase font-weight-bold">Deleted record. </span>Go to the <Link to={`/form/${formId}/deleted`}>Deleted tab</Link> if you want to change the status of this record or delete it parmanently.
      </div>
    )
  };


  return (
    <div>
      <Title text={`${_.get(formState, 'form.title', '')} Submission`}/>
      {isArchived && <ArchivedSubmissionAlert/>}
      {isDeleted && <DeletedSubmissionAlert/>}
      {!isProcess && hasFormWriteAccess && <div className="d-flex justify-content-end">
        <SubmissionConversionProvider><SubmissionConvertButtons/></SubmissionConversionProvider>
          <Link
            className="btn btn-primary ml-2"
            to={{
              pathname: `/form/${formId}`,
              state: {data: submission.data || {}}
            }}
          >Create Copy</Link>
      </div>}
      <Navbar />
      <AppErrors errors={[error]}/>
      {!error && <Switch>
        <Route
          exact
          path="/form/:formId/submission/:submissionId"
          component={View}
        />
        {!isDisabled && !isProcess && [<Route
          path="/form/:formId/submission/:submissionId/edit"
          component={Edit}
          key="edit"
        />,
        <Route
          path="/form/:formId/submission/:submissionId/route"
          component={SubmissionRoute}
          key="route"
        />]}
      </Switch>}
    </div>
  )
};

export default SubmissionPage;
