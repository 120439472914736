import _ from 'lodash';

export  const readOnlyRoles = {
  cc: 'cc',
  bcc: 'bcc',
  prevOwner: 'prevOwner',

};

export const routedSubmissionRoles = {
  routeInitiator: 'routeInitiator',
  owner: 'owner',
  noAccess: 'noAccess',
  ...readOnlyRoles
}

export const getReadOnlyUserRoleData = (tracks, userId) => {
  let userRole = null;
  let prevOwnerTrack = 0;
  const currentRoute =_.chain(tracks)
    .findLast((track, trackIndex) => {
      const ccs = _.get(track, 'senderInfo[0].cc', []);
      const bccs = _.get(track, 'senderInfo[0].bcc', []);
      const prevOwners = _.get(track, 'recipientsInfo', []);

      if (_.some(prevOwners, prevOwner => _.get(prevOwner, 'to[0]._id', '') === userId)) {
        prevOwnerTrack = trackIndex;
        userRole = readOnlyRoles.prevOwner;
        return true;
      } 

      if (_.some(ccs, cc => cc._id === userId)) {
        userRole = readOnlyRoles.cc;
        return true;
      }

      if ( _.some(bccs, bcc => bcc._id === userId)) {
        userRole = readOnlyRoles.bcc;
        return true;
      }

      return false;
    })
    .value();

    return {
      currentRoute,
      userRole,
      prevOwnerTrack
    };
};

export const getUserRole = (routeTrack, userId) => {
  const routeInitiators = _.get(routeTrack, 'data.routeInitiators', []);
  const currentOwners = _.get(routeTrack, 'data.currentRoute.owners', []);
  const currentReadOnlyUsers = _.get(routeTrack, 'data.currentRoute.readOnlyUsers', []);

  if (_.some(currentOwners, owner => owner._id === userId)) {
    return routedSubmissionRoles.owner;
  }
  
  const isReadOnly = _.some(currentReadOnlyUsers, readOnlyUser => readOnlyUser._id === userId);

  if (_.some(routeInitiators, routeInitiator => routeInitiator._id === userId) && !isReadOnly) {
    return routedSubmissionRoles.routeInitiator;
  }
  
  if (isReadOnly) {
    return getReadOnlyUserRoleData(_.get(routeTrack, 'data.tracking', []), userId).userRole;
  }

  return routedSubmissionRoles.noAccess;
}