import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import React from 'react';
import _ from 'lodash';

import { SubmissionsProvider } from '../../submission/submissionsContext';
import { submissionStatuses } from '../../../../utils';
import { IncomingSubmissionsList } from '../../incomingSubmission';
import { RoutedSubmissionsList } from '../../routedSubmission';
import { Title } from '../../../../common';
import { useForm } from '../../form';
import NamedSubmissionsList from '../../submission/components/NamedSubmissionsList';

const ArchivedSubmissionsPage = () => {
  const { formId } = useParams();
  const {state: formState} = useForm();

  const navbarLinks = _.sortBy([
    {icon: "", path: `/form/${formId}/archived`,  title: 'Current', priority: 10 },
    {icon: "", path: `/form/${formId}/archived/incoming`, exact: false,  title: 'Incoming', priority: 10 },
    {icon: "", path: `/form/${formId}/archived/routed`, exact: false,  title: 'Routed', priority: 20 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-3">
      {navbarLinks.map(link => {
        return (
          <li className="nav-item" key={link.title}>
          <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true } to={link.path}  >
            <i className={link.icon}></i> {link.title}
          </NavLink>
          </li>
        )
      })}
    </ul>
  );

  return (
    <>
    <Title text={`Archived Submissions - ${formState.form.title} Form `}/>
    <Navbar/>
    <SubmissionsProvider>
      <Switch>
      <Route
          exact
          path="/form/:formId/archived/incoming"
          render={(props) => <IncomingSubmissionsList {...props} hideTitle={true} archived={true}/>}
        />
        <Route
          exact
          path="/form/:formId/archived/routed"
          render={(props) => <RoutedSubmissionsList {...props} hideTitle={true} archived={true}/>}
        />
        <Route
          exact
          path="/form/:formId/archived"
          render={(props) => <NamedSubmissionsList
            hideAddBtn={true} 
            submissionsRequestParams={{query: {
              'metadata.routed__exists': false,
              'metadata.status': submissionStatuses.archived
            }}}
            gridOptions={{
              hideTrack: true,
              hide: {
                deletePermanently: true, 
                moveToArchived: true, 
                moveToDeleted: !formState.hasFormWriteAccess,
                moveToCurrent: !formState.hasFormWriteAccess,
              }, 
            }}
            {...props} 
          />}
        />
      </Switch>
    </SubmissionsProvider>
    </>
  )
};

export default ArchivedSubmissionsPage;
