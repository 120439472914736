import React from 'react';

const Loading = (props) => {
  const {
    style = {
      width: '60px',
      height: '60px',
    },
    textClass,
    loaderMessage
  } = props;

  const wrapperStyles = {
    marginLeft: 'calc(50% - 60px)',
  };

  const textStyles = {
    fontSize: '1.1rem',
    marginLeft: '-2rem',
    marginTop: '1rem'
  };

  return (
    <div style={wrapperStyles}>
    <div className={`spinner-border ${textClass || 'text-primary'}`} style={style} role="status">
      <span className="sr-only" >Loading...</span>
    </div>
    {loaderMessage && <p style={textStyles}>{`${loaderMessage} ...`}</p>}
    </div>
  );
};

export default Loading;
