import React, {useEffect} from 'react';
import {NavLink, Route, Switch, useParams} from 'react-router-dom';
import _ from 'lodash';

import {useSubmission, getSubmission, SubmissionView} from '../../../forms/submission';
import {useFolder, getParentFolder, resetFolder} from '../folderContext';
import {SubfoldersPage} from '../../subfolders';
import {FormProvider} from '../../../forms/form';
import {getSubfolderGroupsQuery} from '../../FoldersUtils';
import FolderDelete from './FolderDelete';
import FolderEdit from './FolderEdit';
import { Loading } from '../../../../common';

const FolderPage = (props) => {
  const {pathStart, formName, paramName} = props;
  const folderId = useParams()[`${paramName}Id`];

  const {dispatch: dispatchFolderEvent, state: folderState} = useFolder();
  const {parentFolder, loadingParentFolder} = folderState;

  const {dispatch: dispatchSubmissionEvent, state: submissionState} = useSubmission();
  const folder = _.get(submissionState, 'submission', null);
  const isSubfolder = _.get(folder, 'data.subfolder');
  const {isActive: isFolderLoading} = submissionState;

  useEffect(() => {
    getSubmission(dispatchSubmissionEvent, folderId, null, formName);
  }, [folderId, dispatchSubmissionEvent, formName, dispatchFolderEvent]);

  useEffect(() => {
    dispatchFolderEvent(resetFolder());

    if (isSubfolder){
      const parentFolderId = _.get(folder, 'data.parentFolder._id');
      getParentFolder(dispatchFolderEvent, parentFolderId);
    }
  }, [dispatchFolderEvent, folder, folderId, isSubfolder]);
 
  const View = () => <SubmissionView pathStart={pathStart} readOnly={true} formName={formName}/>; 
  const Edit = () => <FolderEdit pathStart={pathStart}  options={{subfolderGroupsQuery: isSubfolder ? getSubfolderGroupsQuery(parentFolder) : ''}}  formName={formName}/>;

  const navbarLinks = _.sortBy([ 
    {icon: "fa fa-chevron-left", getPath: (pathStart, folderId) => `${pathStart}`, title: '', priority: 0 },
    {icon: "fa fa-eye", getPath: (pathStart, folderId) => `${pathStart}/${folderId}`, title: 'View', priority: 10 },
    {icon: "fa fa-edit", getPath: (pathStart, folderId) => `${pathStart}/${folderId}/edit`, title: 'Edit', priority: 20 },
    {icon: "fa fa-folder", getPath: (pathStart, folderId) => `${pathStart}/${folderId}/subfolders`, exact: false, title: 'Subfolders', priority: 40 },
    {icon: "fa fa-trash", getPath: (pathStart, folderId) => `${pathStart}/${folderId}/delete`, title: 'Delete', priority: 50 },
  ], (link) => link.priority );

  const Navbar = () => (
    <ul className="nav nav-tabs mb-2">
      {navbarLinks.map(link => (
        <li className="nav-item" key={link.title}>
        <NavLink className="nav-link" exact={_.isBoolean(link.exact) ? link.exact : true} to={link.getPath(pathStart, folderId)}  >
          <i className={link.icon}></i> {link.title}
        </NavLink>
        </li>
      ))}
    </ul>
  );

  const isLoading = loadingParentFolder || (isSubfolder && _.isEmpty(parentFolder)) || isFolderLoading || _.isEmpty(folder);

  if(isLoading) {
    return <Loading/>;
  }

  return (
    <div>
      <Navbar />
      <Switch>
        <Route exact path={`${pathStart}/:${paramName}Id`} component={View}/>
        <Route path={`${pathStart}/:${paramName}Id/edit`} component={Edit}/>
        <Route
          path={`${pathStart}/:${paramName}Id/delete`}
          render={(props) => <FolderDelete {...props} formName={formName} pathStart={pathStart} paramName={paramName}/>}
        />
        <Route path={`${pathStart}/:${paramName}Id/subfolders`} render={(props) => <FormProvider><SubfoldersPage {...props} /></FormProvider>}/>
      </Switch>
    </div>
  );
};

FolderPage.defaultProps = {
  eventNavbarLinks: [],
};

export default FolderPage;
