import { useParams, useHistory } from 'react-router-dom'
import React, { useEffect, useState , useMemo, useCallback} from 'react';
import _ from 'lodash';

import { useSubmission, getSubmissionAndRouteTrack, cancelLastRoute, saveSubmission } from '../../submission/submissionContext';
import { useForm } from '../../form';
import { Confirm, Loading, Title, AppErrors} from '../../../../common';
import { TrackSubmissionForm } from '../../partials';
import { useAlerts } from '../../../alerts';
import { getSoleOwner, unsetSoleOwnership } from '../../../../utils';


const DataRoutePage = () => {
  const history = useHistory();
  const { formId, submissionId } = useParams();
  const { dispatch, state: submissionState } = useSubmission();
  const { state: formState } = useForm();
  const { isActive: isSubmissionActive, submission, routeTrack, error: submissionError} = submissionState;
  const [cancelRoute, setCancelRoute] = useState(false);
  const [removeOwner, setRemoveOwner] = useState(false);
  const {addAlert} = useAlerts();

  const currentOwner = useMemo(() => {
    const currentOwnerData = getSoleOwner(submission);

    return {
      exists: !!currentOwnerData,
      data: currentOwnerData,
    }
  }, [submission])

  useEffect(() => {
    getSubmissionAndRouteTrack(dispatch, submissionId, formId);
  }, [dispatch, submissionId, formId]);

  const executeTemporalOwnershipRemoval = useCallback(() => {
      unsetSoleOwnership(submission);
      saveSubmission(dispatch, submission, formId, null, () => {
        setRemoveOwner(false);
        addAlert({type: 'success', content: 'The current ownership successfully cancelled'});
      });
  }, [submission, dispatch, formId, addAlert]);


  const lastRouteId = useMemo(()=> _.chain(routeTrack).get('data.tracking', []).last().get('routeId').value(),[routeTrack])
  if (submissionError) {
    return <AppErrors errors={[submissionError]}/>
  }

  if(isSubmissionActive || _.isEmpty(submission) || _.isEmpty(routeTrack)) {
    return <Loading/>;
  }

  const onCancelRoute = () => {
    unsetSoleOwnership(submission);

    cancelLastRoute(dispatch, routeTrack, submission, formState.form, (err) => {
      if(!err) {
        addAlert({type: 'success', content: 'Route succesfully canceled'});
        history.push(`/form/${formId}/routes`);
      }
    })
  }
 
  return (
    <div>
      <Title text={`${_.get(formState, 'form.title', '')}  - Submission Route`}/>
      { currentOwner.exists && removeOwner
        ? <Confirm
            message={`The removal of full ownership from ${_.get(currentOwner, 'data.data.email', '')} will allow other process ownership participants take the full ownership of the process and edit the process forms submissions. Are you sure, you wish to continue?`}
            onYes={executeTemporalOwnershipRemoval}
            onNo={() => setRemoveOwner(false)}
          />
        : currentOwner.exists && <button className="btn btn-primary my-2"  onClick={() => setRemoveOwner(true)}>Remove Current Owner - {_.get(currentOwner, 'data.data.email', '')}</button>}
      <div className="alert alert-primary" role="alert">
        <TrackSubmissionForm routeTrack={routeTrack} showRoutesIds={true}/>
      </div>
      {formState.hasFormWriteAccess ? (
        <>
          {cancelRoute 
            ? <Confirm
                message={`After cancellation, the route recipients will lose their current permissions to the form submission. Are you sure you wish to cancel the route ${lastRouteId}?`}
                onYes={onCancelRoute}
                onNo={() => setCancelRoute(false)}
              />
            : <button className="btn btn-primary" onClick={() => setCancelRoute(true)}>Cancel Last Route <br/>(ID:  {lastRouteId})</button>
          }
        </>
      ) : null}
    </div>
  )
};

export default DataRoutePage;
