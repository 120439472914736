import React from 'react';
import { Formio } from '@formio/react';

const SubmissionConversionContext = React.createContext();

const initialState = {
  url: '',
  pdfUrl: '',
  error: '',
  isPdfUrlLoading: false,
};

const submissionConversionReducer = (state, action) => {
  switch (action.type) {
    case 'PDF_URL_REQUEST':
      return {
        ...state,
        isPdfUrlLoading: true,
        url: action.url,
        error: '',
      };
    case 'PDF_URL_SUCCESS':
      return {
        ...state,
        isPdfUrlLoading: false,
        pdfUrl: action.pdfUrl,
        error: '',
      };
    case 'PDF_URL_FAIL':
      return {
        ...state,
        isPdfUrlLoading: false,
        error: action.error,
      };
    case 'STATE_RESET':
      return initialState;
    default:
      return state;
  }
};

export function SubmissionConversionProvider(props) {
  const [state, dispatch] = React.useReducer(submissionConversionReducer, initialState);
  const value = React.useMemo(() => [state, dispatch], [state, dispatch]);

  return <SubmissionConversionContext.Provider value={value} {...props} />
}

export function useSubmissionConversion() {
  const context = React.useContext(SubmissionConversionContext);
  if (!context) {
    throw new Error(`useSubmissionConversion must be used within a SubmissionConversionProvider`);
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch
  };
}

const pdfUrlRequest = (url) => ({
  type: 'PDF_URL_REQUEST',
  url
});

const pdfUrlSuccess = (pdfUrl) => {
  return {
    type: 'PDF_URL_SUCCESS',
    pdfUrl: `${pdfUrl}&margin=50,50,50,50`
  }
};

const pdfUrlFail = (error) => ({
  type: 'PDF_URL_FAIL',
  error
});

const reset = () => ({
  type: 'STATE_RESET',
});

export const getPdfUrl = (dispatch, url, done =() => {}) => {
  const formio = new Formio(url);

  dispatch(pdfUrlRequest());

  formio.getDownloadUrl()
    .then(pdfUrl => {
      dispatch(pdfUrlSuccess(pdfUrl));
      done(pdfUrl);
    })
    .catch(err => {
      dispatch(pdfUrlFail(err));
      done(err);
    });
};

export const processUrl = (dispatch, submissionId, formId, formName = '') => {
  const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
  const url = `${Formio.getProjectUrl()}${formPath}/submission${submissionId ? `/${submissionId}` : ''}`;

  getPdfUrl(dispatch, url);
};

export const resetState = (dispatch) => {
  dispatch(reset());
}
