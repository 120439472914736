import React, {useEffect, useState} from 'react';
import { Form } from '@formio/react';
import { useParams, useHistory} from 'react-router-dom';
import _ from 'lodash';

import { Button, Loading } from '../../../../common';
import { useForm } from '../../../forms/form/formContext';
import { useAlerts } from '../../../alerts';
import { FormsConfig } from '../../../../config';
import { Title, AppErrors} from '../../../../common';
import { useMyForm, validateAndTransformMyFormData, getMyForm, deleteMyForm, getMyFormForm, setMyForm } from '../myFormContext';

const MyForm = () => {
  const [showMyForm, setShow] = useState(false);

  const history = useHistory();
  const {formId} = useParams();
  const {addAlert} = useAlerts();

  const {state: formState} = useForm();
  const {form, error: formError, isActive} = formState;

  const {state: myFormState, dispatch: dispatchMyFormAction} = useMyForm();
  const {myForm, isActive: isMyFormActive, error: myFormError, myFormForm} = myFormState;
   
  useEffect(() => {
    if (formId && !showMyForm) {
      getMyForm(dispatchMyFormAction, formId);
    }
  }, [dispatchMyFormAction, formId, showMyForm]);

  useEffect(() => {
    if (formId && showMyForm) {
      getMyFormForm(dispatchMyFormAction,formId);
    }
  }, [dispatchMyFormAction, formId, showMyForm])

  const onAddToMyFormsClick = () => {
    setShow(true);
  }

  const onDeleteFromMyFormsClick = () => {
    setShow(false);

    deleteMyForm(dispatchMyFormAction, myForm._id, (err) => {
      if (!err) {
        addAlert({type: 'success', content: 'Form succesfully deleted from My Forms'});
      }
    });
  };

  const ManageMyFormBtn = () => {
    if (formId && !isMyFormActive && !showMyForm) {
      const noMyForm = _.isEmpty(myForm);

      return (
        <div className="d-flex flex-row-reverse">
          <Button 
            btnClass={noMyForm ? 'btn-primary' : 'btn-danger btn-sm'} 
            text={noMyForm ? `Add Form "${form.title}" To My Forms` : 'Delete Form From My Forms'}
            onClick={noMyForm ? onAddToMyFormsClick : onDeleteFromMyFormsClick}
          />
        </div>
      )
    } 
    return null;
  };

  const Loader = () => {
    return (isActive || isMyFormActive) ? <Loading /> : null
  }

  const MyFormEdit = () => {
    if (!isActive && !isMyFormActive && ((formId && !_.isEmpty(myForm)) || !formId ||showMyForm )) {
      return (
        <Form
          src={FormsConfig.getSrc('myForms')}
          options={{
            disableForm: !!formId,
            hooks: {
              customValidation: (submission, next) => {
                validateAndTransformMyFormData(dispatchMyFormAction, submission, !formId, next);
              }
            }
          }}
          submission={!_.isEmpty(myFormForm) ? {data: {form: myFormForm}} : myForm}
          formReady={(form)=> {
            form.on('submitDone', (submission) => {
              setShow(false);
              setMyForm(dispatchMyFormAction,submission, () => {
                addAlert({ type: 'success', content: 'My Form is succesfully saved' });
              });

              history.push(!formId ? '/myforms' : history.location.pathnam);
            })
          }}
        />)
    }
    return null;
  }

  return (
    <div>
      <Title text={(_.isEmpty(myForm) && showMyForm) || !formId ? `New My Form` : 'My Form' }/>
      <AppErrors errors={[formError, myFormError]} />
      <ManageMyFormBtn/>
      <Loader/>
      <MyFormEdit/>
    </div>
  );
}

export default MyForm;