import React, {useRef} from 'react';
import { useHistory, useParams } from 'react-router';
import { Form } from '@formio/react';
import _ from 'lodash';

import { Loading, AppErrors } from '../../../../common';
import { useSubmission, saveSubmission } from '../submissionContext';
import { useForm } from '../../form/formContext';
import { useAlerts } from '../../../alerts';
import { SubmissionName } from '../../partials';
import { useAuth } from '../../../auth';

const SubmissionView = (props) => {
  const { hideComponents, readOnly, formName, pathStart, options: propsOptions, noRedirectOnSubmit, disableSubmissionName} = props;
  const history = useHistory();
  const { formId } = useParams();
  const {addAlert} = useAlerts();
  const {state: authState } = useAuth();
  const { userInfo, user } = authState;

  const requiredName = !!formId;

  const {state: formState} = useForm();
  const {isActive: isFormActive, error: formError, form, autoName} = formState;
 
  const {state: submissionState, dispatch: dispatchSubmissionAction} = useSubmission();
  const {url, submission, error: submissionError, isActive: isSubmissionActive} = submissionState;

  const options = { template: 'bootstrap', iconset: 'fa', readOnly: !!readOnly, ...formState.options, ...propsOptions, userId: user._id, userProfileId: userInfo._id};

  const nameForm = useRef();
  const submitBtn = useRef();

  const onSubmit = (submission) => {
    saveSubmission(dispatchSubmissionAction, submission, formId, formName, (err, submission) => {
      if (!err) {
        addAlert({ type: 'success', content: 'Submission is successfully updated' });
        if (!noRedirectOnSubmit) {
          history.push(`${formId ? `/form/${formId}/submission` : `${pathStart || '/' + formName}`}/${submission._id}`);
        }
      }
    });
  }

  const isLoading = (isFormActive || isSubmissionActive || _.isEmpty(submission) || _.isEmpty(form)) && !submissionError;

  if (isLoading ) {
    return <Loading />;
  }

  return (
    <div>
      <AppErrors errors={[formError, submissionError]} />
      {requiredName && <SubmissionName
        name={_.get(submission, 'metadata.submissionName', '')}
        onFormReady={(form) => {nameForm.current = form;}}
        readOnly={readOnly || disableSubmissionName || autoName}
        onNameChange={(name) => {
          if (name && requiredName && submitBtn.current && submitBtn.current.hasError && !submitBtn.current?.root?.errors.length) {
            submitBtn.current.triggerChange();
          }
        }}
      />}
      <Form
        form={form}
        submission={submission}
        url={url}
        hideComponents={hideComponents}
        onSubmit={onSubmit}
        formReady={(form)=> {
          submitBtn.current = form.getComponent('submit');
        }}
        options={{...options, hooks: {
          customValidation: function (submission, next) {
            if(!requiredName) {
              next();
            }

            const submNameForm= nameForm.current;
            const submissionName = _.get(submNameForm, 'submission.data.submissionName', '');

            if (submissionName) {
              _.set(submission, 'metadata.submissionName', submissionName);
              next();
            } 
            else {
              submNameForm.getComponent('submissionName').pristine = false;
              submNameForm.getComponent('submissionName').checkValidity();
              submNameForm.getComponent('submissionName').focus();
              
              next('Please add name for this submission.');
            }
          }
        }}}
      />
    </div>
  );
};

export default SubmissionView;
