
import NextButton from "./NextButton/NextButton";
import RouteButton from "./RouteButton/RouteButton";
import PrevButton from "./PrevButton/PrevButton";
import ToPageButton from "./ToPageButton/ToPageButton";
import ToFormButton from "./ToFormButton/ToFormButton";
import Process from "./Process";
import NestedForm from "./NestedForm/NestedForm";
import Component from "./Component/Component";
import AutoName from "./AutoName/AutoName";
import DataTable from "./DataTable/DataTable"

export const components = {
  component: Component,
  base: Component,
  nextButton: NextButton,
  routeButton: RouteButton,
  prevButton: PrevButton,
  toPageButton: ToPageButton,
  toFormButton: ToFormButton,
  form: NestedForm,
  autoName: AutoName,
  datatable: DataTable
};

export const displays = {wizard: Process};

