import React from 'react';
import { TrackSubmissionForm } from '.';
import RouteTrackingModalWrapper from './RouteTrackingModalWrapper';

const RouteTrackingModal = (props) => {
  const {
    routeTrack = {},
    onModalCloseClick = () => {}
  } = props;

  return (
    <RouteTrackingModalWrapper  onModalCloseClick={ onModalCloseClick}>
      <TrackSubmissionForm routeTrack={routeTrack}/>
    </RouteTrackingModalWrapper>
  )
}

export default RouteTrackingModal;
