import _ from 'lodash';
import React, { useCallback, useEffect} from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { FormsConfig } from '../../../../config';
import { FormView, getForm, useForm, setFormOptions } from '../../../forms/form';
import { FormsProvider } from '../../../forms/form/formsContext';
import { SubmissionProvider, SubmissionsList, SubmissionsProvider, useSubmission } from '../../../forms/submission';
import MemberPage from './MemberPage';
import {Utils} from 'formiojs';
import { Loading } from '../../../../common';

const MembersPage = () => {
  const paramName = 'member';
  const { groupId } = useParams();
  const pathStart = `/groups/${groupId}/members`;
  const formName = FormsConfig.userGroup;

  const {dispatch: dispatchFormEvent, state: formState} = useForm();
  const {form, isActive: isFormActive} = formState;

  const {state: submissionState} = useSubmission();
  const groupName = _.get(submissionState, 'submission.data.groupName', '');

  const transformFormComponents = useCallback((form) => {
    if (form.components) {
      const groupComp = Utils.getComponent(form.components, 'group');

      if (groupComp) {
        groupComp.tableView = false;
      }
    }
    return form;
  }, []);

  useEffect(() => {
    getForm(dispatchFormEvent, null, formName, transformFormComponents);
  }, [dispatchFormEvent, formName, transformFormComponents]);

  useEffect(() => {
    setFormOptions(dispatchFormEvent, {disableGroup: true});
  }, [dispatchFormEvent]);

  if (isFormActive || _.isEmpty(form)) {
    return <Loading/>
  }
  return (
    <div>
      <FormsProvider>
        <SubmissionsProvider>
          <Switch>
            <Route
              exact
              path={`${pathStart}`}
              render={(props) => {
                return  <SubmissionsList
                  {...props}
                  formName={formName}
                  getViewPath={(formId, submission) => `${pathStart}/${submission._id}`}
                  createSubmissionPath={`${pathStart}/create`}
                  submissionsRequestParams ={{query: {'data.group.data.groupName': groupName}}}
                  addBtnTitle={'Member'}
                  title={`${groupName} Group Members`}
                />
              }}
            />
            <Route
              exact
              path={`${pathStart}/create`}
              render={(props) => (
                <SubmissionProvider>
                  <FormView {...props} name={formName} submission={{data: {group: submissionState.submission}}} pathStart={pathStart}/>
                </SubmissionProvider>
              )}
            />
            <Route
              path={`${pathStart}/:${paramName}Id`}
              render={(props) => (
                <SubmissionProvider>
                  <MemberPage {...props} pathStart={pathStart} paramName={paramName} formName={formName}/>
                </SubmissionProvider>
              )}
            />
          </Switch>
        </SubmissionsProvider>
      </FormsProvider>
    </div>
  );
};



export default MembersPage;
