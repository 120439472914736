import React from 'react';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';

import { Confirm, AppErrors } from '../../../../common';
import { useForm } from '../../form';
import { useSubmission, deleteSubmission } from '../submissionContext'


const SubmissionDelete = ({formName, paramName, deleteSubject, pathStart}) => {
  const history = useHistory();
  const { formId, submissionId} = useParams();
  const eventId = useParams()[`${paramName}Id`];

  const { state: submissionState, dispatch: dispatchSubmissionAction } = useSubmission();
  const { state: formState } = useForm();

  const performDelete = function() {
    deleteSubmission(dispatchSubmissionAction, submissionId || eventId, formId, formName,  (err) => {
      if (!err) {
        history.push(`${formId ? `/form/${formId}/submission` : `${pathStart || '/' + formName}`}`);
      }
    });
  }

  const onYes = () => {
    performDelete();  
  };

  const onNo = () => {
    history.push(`${formId ? `/form/${formId}/submission` : `${pathStart || '/' + formName}`}`);
  };

  const subject = deleteSubject || `the submission '${_.get(submissionState, 'submission.metadata.submissionName', '') || submissionId || eventId}'`;
  const message = `Are you sure you wish to delete ${subject}?`;

  return (
    <div>
      <AppErrors errors={[submissionState.error, formState.error]} />
      <Confirm
        message={message}
        onYes={onYes}
        onNo={onNo}
      />
    </div>
  )
};

export default SubmissionDelete;
